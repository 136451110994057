import { useState } from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import { Box } from "./box";
const style = {
  height: "12rem",
  width: "12rem",
  marginRight: "1.5rem",
  marginBottom: "1.5rem",
  color: "white",
  padding: "1rem",
  textAlign: "center",
  fontSize: "1rem",
  lineHeight: "normal",
  float: "left",
};
const arr = [];
export const Dustbin = (props) => {

  const { boards, setBoard } = props;
  let services = [];
  const [isActive, setIsActive] = useState(boards.length ? true : false);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.BOX,
    drop: (item) => addServiceToDustbin(item.name, item.id),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  const addServiceToDustbin = (name, id) => {
    // setIsActive(true);
    // if(props.boards.filter(x => x.id === id).length === 0)
    props.setBoard((p) => [...p, { key: id, value: name }])
    props.onChange()
  }

  const removeServiceToDustbin = (i) => {
    let temp = [...boards];
    temp.splice(i, 1);
    setBoard((p) => [...temp]);
    // console.log("splice>>", temp, i, boards);
  }

  return (
    <>

      <div
        className='drag-area-sec m-3 p-3 text-center'

        ref={drop}
      >
        {boards.length
          ? ""
          : "Select or drag Amss Servies to use Engagement Windows"}
        {boards?.map((b, i) => {
          return (
            <div key={i}
              className="boxitem"

            >
              <div
                style={{
                  position: "absolute",
                  borderRadius: "50%",
                  top: "2px",
                  background: "red",
                  width: "18px",
                  height: "18px",
                  right: "2px",
                  fontSize: "10px",
                  display: "grid",
                  placeItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  removeServiceToDustbin(i);
                }}
              >
                X
              </div>
              {b?.value}
            </div>
          );
        })}
      </div>
    </>
  );
};
