import React from "react";
import { Modal, Spinner } from "react-bootstrap";

export default function Loader(props) {
	return (
		<>
			{props.isLoading && (
				<Modal show={props.isLoading} centered size="sm">
					<Modal.Body>
						<div class="justify-content-center align-item-center">
							<Spinner animation="border" class="m-5" />
							Loading....
						</div>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
}
