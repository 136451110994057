import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

// import { ItemTypes } from "./ItemTypes.js";
const style = {
  border: "1px dashed gray",
  padding: "0.5rem 1rem",
  marginBottom: ".5rem",
  backgroundColor: "white",
  cursor: "move",
};

export default function AssetVerificationGroupItem({
  g,
  editAVSAttribute,
  delAVSAttribute,
  index,
  reorderAssetVerificationAttributes,
  setAttributeOrderFlag,
  isAttributeOrderFlag,
  groups,
  setGroups,
  onAttributesFlagOpen,
}) {
  const [orderAttributes, setOrderAttributes] = useState([
    ...g.assetVerificationAttributes,
  ]);
  const reset = () => {
    setOrderAttributes([...g.assetVerificationAttributes]);
    const tempAllGroups = [...groups];
    tempAllGroups[index].assetVerificationAttributes = orderAttributes;
    setGroups([...tempAllGroups]);
    setAttributeOrderFlag(false);
  };
  const onDragEnd = (e) => {
    const sourceIndex = e.source.index;
    const destIndex = e.destination.index;
    const tempGroups = [...g.assetVerificationAttributes];
    tempGroups.splice(destIndex, 0, tempGroups.splice(sourceIndex, 1)[0]);
    // setGroups(tempGroups);
    console.log("tempGroups", { tempGroups, g, groups });
    const tempAllGroups = [...groups];
    // const selectedIndex = tempAllGroups.findIndex((t) => t.id == g.id);
    tempAllGroups[index].assetVerificationAttributes = tempGroups;
    setGroups([...tempAllGroups]);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="avs-table" id="avsTable" style={{ display: "block" }}>
        <div className="table-responsive">
          <table className="table table-bordered text-light mb-0">
            <thead className="table-dark">
              <tr>
                <th></th>
                <th>Attribute Test Name</th>
                <th>Test Description </th>
                <th>Source Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <Droppable droppableId={"droppable-attr-" + index}>
              {(provided, snapshot) => (
                <tbody
                  id="avsTableApp"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {g.assetVerificationAttributes &&
                    g.assetVerificationAttributes.map((attribute, index1) => {
                      return (
                        <Draggable
                          draggableId={"draggable-attr-" + index1}
                          index={index1}
                          key={index1}
                        >
                          {(provided, snapshot) => (
                            <tr
                              key={index}
                              index={index}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <td class="p-0 text-center">
                                {isAttributeOrderFlag && (
                                  <span class="" {...provided.dragHandleProps}>
                                    <i class="fas fas fa-sort text-info"></i>
                                  </span>
                                )}
                              </td>
                              <td className="text-bold-500">
                                {attribute.name}
                              </td>

                              <td>{attribute.description}</td>
                              <td>{attribute.verificationSource}</td>

                              <td>
                                <div className="d-flex table-action">
                                  <a
                                    className="table-acton-icon"
                                    title="Edit Template"
                                    onClick={() => editAVSAttribute(attribute)}
                                  >
                                    <i
                                      className="fa fa-edit text-info"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                  <a
                                    title="Delete "
                                    class="table-acton-icon ml-2"
                                    onClick={() =>
                                      delAVSAttribute(attribute.id)
                                    }
                                  >
                                    <i className="fas fa-trash text-info"></i>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      );
                    })}
                </tbody>
              )}
            </Droppable>
          </table>
        </div>
        {isAttributeOrderFlag ? (
          <>
            {/* <button
              class="btn drag_submit border border-info bg-transparent p-2"
              onClick={reset}
            >
              <i class="fa fa-back p-1"></i> Reset
            </button> */}
            {g.assetVerificationAttributes.length > 1 && (
              <button
                class="btn drag_submit m-0 mt-2 mb-0 p-1 "
                onClick={() =>
                  reorderAssetVerificationAttributes([
                    ...g.assetVerificationAttributes,
                  ])
                }
                title="Save order"
              >
                <i class="fa fa-save p-1"></i>
              </button>
            )}
          </>
        ) : (
          <>
            {g.assetVerificationAttributes.length > 1 && (
              <button
                class="btn drag_submit m-0 mt-2 mb-0 p-1 "
                onClick={() => onAttributesFlagOpen()}
                title="Edit order"
              >
                <img src="assets/images/reorder.png" class="icon" />
              </button>
            )}
          </>
        )}
      </div>
    </DragDropContext>
  );
}
