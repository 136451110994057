import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/Button";

function handleLogin(instance) {
  // instance.loginRedirect(loginRequest).catch((e) => {
  instance.loginPopup(loginRequest).catch((e) => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  return (
    <Button
      variant="secondary"
      className="ml-auto"
      onClick={() => handleLogin(instance)}
    >
      Sign In
    </Button>
  );
};

export const handleAzureLogout = async (instance) => {
  //   instance.logoutRedirect({ postLogoutRedirectUri: "/" }).catch((e) => {
  await instance.logoutPopup({ postLogoutRedirectUri: "/" }).catch((e) => {
    console.error(e);
  });
};

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  return (
    <Button
      variant="secondary"
      className="ml-auto"
      onClick={() => handleAzureLogout(instance)}
    >
      Sign Out
    </Button>
  );
};
