import { STORE_CLIENT_ACTION } from "actions/homeAction";
import { UPDATE_CLIENT_ACTION } from "actions/homeAction";

const initialState = {
	client_id: null,
	clients: [],
};

const homeReducer = (state = initialState, action) => {
	// const newState = { ...state };
	console.log("action>>", action, state);
	switch (action.type) {
		case UPDATE_CLIENT_ACTION:
			return { ...state, client_id: action.payload.clientId };
		case STORE_CLIENT_ACTION:
			return { ...state, clients: action.payload.clients };
		default:
			return state;
	}
};

export default homeReducer;
