import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export default function ConvenantItem({
  covenants,
  setCovenants,
  onEdit,
  submit,
}) {
  const [isOrderEdit, setOrderEdit] = useState(false);
  const onDragEnd = (e) => {
    const sourceIndex = e.source.index;
    const destIndex = e.destination.index;
    const tempGroups = [...covenants];
    tempGroups.splice(destIndex, 0, tempGroups.splice(sourceIndex, 1)[0]);
    // setGroups(tempGroups);
    //  const tempAllGroups = [...groups];
    // const selectedIndex = tempAllGroups.findIndex((t) => t.id == g.id);
    //  tempAllGroups[index].assetVerificationAttributes = tempGroups;
    setCovenants([...tempGroups]);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="table-responsive">
        <table className="table table-bordered text-light mb-0">
          <thead className="table-dark">
            <tr>
              <th> </th>
              <th>Covenant Catagory</th>
              <th>Covenant Name</th>
              <th>Source Name</th>
              {/* <th>Value Type</th> */}
              {/* <th>Value To Compare</th> */}
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <Droppable droppableId={"droppable-conv-1"}>
            {(provided, snapshot) => (
              <tbody
                id="covenantTableBody"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {covenants &&
                  covenants.map((cov, c) => {
                    return (
                      <Draggable
                        draggableId={"draggable-conv-" + c}
                        index={c}
                        key={c}
                      >
                        {(provided, snapshot) => (
                          <tr
                            key={c}
                            index={c}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <td class="p-0 text-center">
                              {isOrderEdit && (
                                <span class="" {...provided.dragHandleProps}>
                                  <i class="fas fas fa-sort text-info"></i>
                                </span>
                              )}
                            </td>
                            {/* <td className='text-bold-500'>{cov.covenantTypeName}</td> */}
                            <td>{cov.covenantCategoryName}</td>
                            <td>{cov.name}</td>
                            <td>{cov.verificationSource}</td>
                            {/* <td>{cov.dataValueTypeName}</td> */}
                            {/* <td>{cov.value}</td> */}
                            <td>{cov.description}</td>

                            <td>
                              <div className="d-flex table-action">
                                <div
                                  className="table-acton-icon"
                                  title="Edit Template"
                                  onClick={() => onEdit(cov)}
                                >
                                  <i
                                    className="fa fa-edit text-info"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
              </tbody>
            )}
          </Droppable>
        </table>
        <div>
          {isOrderEdit ? (
            <button
              class="btn btn-primary px-2 py-2"
              onClick={() => {
                setOrderEdit(false);
                submit();
              }}
              title="Save order"
            >
              <i class="fa fa-save" />
            </button>
          ) : (
            <button
              class="btn btn-primary p-1"
              type="button"
              onClick={() => setOrderEdit(true)}
              title="Edit order"
            >
              <img src="assets/images/reorder.png" class="icon" />
            </button>
          )}
        </div>
      </div>
    </DragDropContext>
  );
}
