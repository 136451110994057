import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { config } from "../constant";
import moment from "moment";

// reactstrap components
import // Button,
// Input,
// InputGroupAddon,
// InputGroupText,
// InputGroup,
// Container,
// Row,
// Col,
"reactstrap";

// core components
import SideBar from "components/SideBar.js";
import AamsHeader from "components/AamsHeader.js";
import AamsFooter from "components/AamsFooter.js";
import Plot from "react-plotly.js";
import NumberFormat from "react-number-format";
import { axiosAuthHeaderSet } from "helpers/utils";
import { useSelector } from "react-redux";
import Toaster from "components/Toaster";
import { AppContext } from "App";

const getEngagementUrl = config.url.API_URL + "asset-tape-summary";

function Blankpage() {
	const [originatorData, setOriginatorData] = useState([]);
	const [dealDataByDealName, setDealDataByDealName] = useState([]);
	const [dealData, setDealData] = useState([]);
	const [dealSum, setDealSum] = useState(0);
	const [defaultDeal, setDeafultDeal] = useState(0);
	const [openEngagementPeriod, setOpenEngagementPeriod] = useState(0);
	const [lateReport, setLateReport] = useState(0);
	const [convenantReviewSummery, setConvenantReviewSummery] = useState([]);
	const [convenantReviewSummeryTrand, setConvenantReviewSummeryTrand] =
		useState({ key: [], value: [] });
	const [assetReviewSummery, setAssetReviewSummery] = useState([]);
	const [assetReviewSummeryTrand, setAssetReviewSummeryTrand] = useState({
		key: [],
		value: [],
	});
	const [dealSummeryByMonth, setDealSummeryByMonth] = useState({});
	const [selectedDealId, setSelectedDeal] = useState(null);
	const [selectedOriginatorId, setSelectedOriginator] = useState(null);
	const [selectedAssetTypeId, setSelectedAssetType] = useState(null);
	const [assetTypes, setAssetTypes] = useState([]);
	const homeData = useSelector((state) => state.home);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState(null);
	const [alertType, setAlertType] = useState("info");
	const appContext = useContext(AppContext);
	useEffect(() => {
		axiosAuthHeaderSet();
		getAssetTapeSummary();
		getCovenantReviewSummary();
		getAssetVerificationReviewSummary();
		getDealDetails();
		getConvenantReviewTrand();
		getAssetTapeSummaryByMonth();
		getAssetVerificationReviewSummaryTrand();
		getPeriodData();
		getAssetTypeData();
	}, [homeData.client_id]);

	const camelize = (s) => {
		return s[0].toUpperCase() + s.slice(1);
	};
	const getAssetTypeData = async () => {
		try {
			const resp = await axios.get(config.url.API_URL + "asset-type");
			setAssetTypes(resp?.data?.responseData);
		} catch (error) {
			console.log("error>>", error);
			setAlertType("danger");
			console.log("error>>", error);
			setShowAlert(true);
			setAlertMessage(error.response?.data?.Message);
		}
	};
	const getPeriodData = async () => {
		try {
			const resp = await axios.get(
				config.url.API_URL + "period?PeriodStatusCode=OPEN"
			);
			setOpenEngagementPeriod(resp?.data?.responseData.length);
			const tempLateData = resp?.data?.responseData?.filter((r) => {
				if (moment(r.deliveryDate) < moment()) {
					return r;
				}
			});
			setLateReport(tempLateData.length);
			console.log("getPeriodData>>>", resp, tempLateData);
		} catch (error) {
			console.log("error>>", error);
			setAlertType("danger");
			console.log("error>>", error);
			setShowAlert(true);
			setAlertMessage(error.response?.data?.Message);
		}
	};
	const groupbyOriginator = (data) => {
		const temp = data.reduce((r, a) => {
			r[a.originator?.trim()] = r[a.originator?.trim()] || [];
			r[a.originator?.trim()].push(a);
			return r;
		}, {});
		let oriData = {
			key: Object.keys(temp),
			// value: Object.values(temp).reduce((v, p) => {
			//   v += p.totalBalance;
			//   return v;
			// }, 0),
		};
		const val = Object.keys(temp).map((key) => {
			return temp[key].reduce((p, a) => {
				p += a.totalBalance;
				return p;
			}, 0);
		});

		oriData["value"] = val;
		setOriginatorData(oriData);
	};
	const groupbyEndMonth = (data) => {
		const temp = data.reduce((r, a) => {
			r[moment(a.periodEndDate?.trim()).format("MMM")] =
				r[moment(a.periodEndDate?.trim()).format("MMM")] || [];
			r[moment(a.periodEndDate?.trim()).format("MMM")].push(a);
			return r;
		}, {});
		let oriData = {
			key: Object.keys(temp),
		};
		let data1 = { failed: [], passed: [], "not Applicable": [], tested: [] };
		const val = Object.keys(temp)
			.map((key) => {
				return temp[key].reduce(
					(p, a) => {
						// p += a.totalBalance;
						p.covenantsFailed += a.covenantsFailed;
						p.covenantsNotApplicable += a.covenantsNotApplicable;
						p.covenantsPassed += a.covenantsPassed;
						p.covenantsTested += a.covenantsTested;
						return p;
					},
					{
						covenantsFailed: 0,
						covenantsNotApplicable: 0,
						covenantsPassed: 0,
						covenantsTested: 0,
					}
				);
			})
			.map((v) => {
				data1.failed.push(v.covenantsFailed);
				data1.passed.push(v.covenantsPassed);
				data1["not Applicable"].push(v.covenantsNotApplicable);
				data1.tested.push(v.covenantsTested);
			});

		oriData["value"] = data1;
		console.log("temp>>", oriData, data1);
		setConvenantReviewSummeryTrand(oriData);
	};
	const groupbyEndMonthForAssets = (data) => {
		const temp = data.reduce((r, a) => {
			r[moment(a.periodEndDate?.trim()).format("MMM")] =
				r[moment(a.periodEndDate?.trim()).format("MMM")] || [];
			r[moment(a.periodEndDate?.trim()).format("MMM")].push(a);
			return r;
		}, {});
		let oriData = {
			key: Object.keys(temp),
		};
		let data1 = { failed: [], passed: [], "not Applicable": [], tested: [] };
		const val = Object.keys(temp)
			.map((key) => {
				return temp[key].reduce(
					(p, a) => {
						// p += a.totalBalance;
						p.covenantsFailed += a.assetVerificationsFailed;
						p.covenantsNotApplicable += a.assetVerificationsNotApplicable;
						p.covenantsPassed += a.assetVerificationsPassed;
						p.covenantsTested += a.assetsTested;
						return p;
					},
					{
						covenantsFailed: 0,
						covenantsNotApplicable: 0,
						covenantsPassed: 0,
						covenantsTested: 0,
					}
				);
			})
			.map((v) => {
				data1.failed.push(v.covenantsFailed);
				data1.passed.push(v.covenantsPassed);
				data1["not Applicable"].push(v.covenantsNotApplicable);
				data1.tested.push(v.covenantsTested);
			});

		oriData["value"] = data1;
		console.log("temp>>", oriData, data1);
		setAssetReviewSummeryTrand(oriData);
	};
	const getDealDetails = async () => {
		setShowAlert(false);
		try {
			const resp = await axios.get(config.url.API_URL + "deal");
			console.log("deal>>>", resp);
			const result = resp.data.responseData;
			setDealData(result);

			const total = result.reduce((t, a) => {
				return t + a.initialSize;
			}, 0);
			setDealSum(total);
			const dfDeal = result.filter(
				(r) => r.IsDefaultedFinancial || r.IsDefaultedOther
			);
			setDeafultDeal(dfDeal.length);

			console.log("sum:", total, dfDeal.length);
		} catch (error) {
			console.log("getDealDetails error>>", error);
			setAlertType("danger");
			console.log("error>>", error);
			setShowAlert(true);
			setAlertMessage(error.response?.data?.Message);
		}
	};

	const groupbyDealName = (data) => {
		const temp = data.reduce((r, a) => {
			r[a.dealTypeName?.trim()] = r[a.dealTypeName?.trim()] || [];
			r[a.dealTypeName?.trim()].push(a);
			return r;
		}, {});

		let oriData = {
			key: Object.keys(temp),
		};
		const val = Object.keys(temp).map((key) => {
			return temp[key].reduce((p, a) => {
				p += a.totalBalance;
				return p;
			}, 0);
		});
		oriData["value"] = val;
		setDealDataByDealName((p) => oriData);
		console.log("dealTypeName>>", oriData, dealData);
	};
	const getAssetTapeSummary = async () => {
		let url = getEngagementUrl + "?IncludeOnlyMostRecentUpload=true";
		if (selectedDealId) {
			url += "&dealId=" + selectedDealId;
		}
		if (selectedAssetTypeId) {
			url += "&assetTypeId=" + selectedAssetTypeId;
		}
		try {
			const resp = await axios.get(url);
			console.log(resp.data.responseData);
			groupbyOriginator(resp.data.responseData);
			groupbyDealName(resp.data.responseData);
		} catch (error) {
			setAlertType("danger");
			console.log("error>>", error);
			// setShowAlert(true);
			// setAlertMessage(error.response?.data?.Message);
		}
	};
	const getAssetTapeSummaryByMonth = async () => {
		const startDate = moment().subtract(6, "months").format("YYYY-MM-DD");
		let url = getEngagementUrl + "?startDate=" + startDate;
		if (selectedDealId != null) {
			url += "&dealId=" + selectedDealId;
		}
		if (selectedAssetTypeId != null) {
			url += "&assetTypeId=" + selectedAssetTypeId;
		}
		try {
			const resp = await axios.get(url);
			// const resp = await axios.get(
			// 	getEngagementUrl +
			// 		"?startDate=" +
			// 		startDate +
			// 		"&dealId=" +
			// 		selectedDealId +
			// 		"&assetTypeId=" +
			// 		selectedAssetTypeId
			// );
			console.log("getAssetTapeSummaryByMonth", resp);
			let temp = {
				x: [],
				y: [],
			};
			resp.data.responseData.map((r) => {
				temp.x.push(r.tapeMonth);
				temp.y.push(r.totalAmountPaid);
			});
			setDealSummeryByMonth(temp);
		} catch (error) {
			setAlertType("danger");

			setShowAlert(true);
			setAlertMessage(error.response?.data?.Message);
		}
	};
	const getConvenantReviewTrand = async () => {
		let startDate = moment()
			.subtract(6, "months")
			// .endOf("month")
			.format("YYYY-MM-DD");
		let url =
			config.url.API_URL + "covenant-review-summary?startDate=" + startDate;
		if (selectedDealId != null) {
			url += "&dealId=" + selectedDealId;
		}
		if (selectedAssetTypeId != null) {
			url += "&assetTypeId=" + selectedAssetTypeId;
		}
		try {
			const resp = await axios.get(url);
			// const resp = await axios.get(
			// 	config.url.API_URL +
			// 		"covenant-review-summary?startDate=" +
			// 		startDate +
			// 		"&dealId=" +
			// 		selectedDealId
			// );
			groupbyEndMonth(resp.data.responseData);
		} catch (error) {
			setAlertType("danger");
			console.log("error 1>>", error.errorResponse);
			setShowAlert(true);
			setAlertMessage(error.response?.data?.Message);
		}
	};
	const getCovenantReviewSummary = async () => {
		let url =
			config.url.API_URL +
			"covenant-review-summary?IncludeOnlyMostRecentPeriod=true";
		if (selectedDealId != null) {
			url += "&dealId=" + selectedDealId;
		}
		if (selectedAssetTypeId != null) {
			url += "&assetTypeId=" + selectedAssetTypeId;
		}
		try {
			const resp = await axios.get(url);
			// const resp = await axios.get(
			// 	config.url.API_URL +
			// 		"covenant-review-summary?IncludeOnlyMostRecentPeriod=true&dealId=" +
			// 		selectedDealId
			// );
			console.log("getCovenantReviewSummary", resp.data.responseData);
			let temp = [
				{
					x: [],
					y: [],
					name: "Tested",
					orientation: "h",
					marker: {
						color: "rgba(150,175,5,0.6)",
						width: 1,
					},
					type: "bar",
				},
				{
					x: [],
					y: [],
					name: "Passed",
					orientation: "h",
					marker: {
						color: "rgba(45,128,11,0.6)",
						width: 1,
					},
					type: "bar",
				},
				{
					x: [],
					y: [],
					name: "Not Applicable",
					orientation: "h",
					marker: {
						color: "rgba(55,128,191,0.6)",
						width: 1,
					},
					type: "bar",
				},
				{
					x: [],
					y: [],
					name: "Failed",
					orientation: "h",
					marker: {
						color: "rgba(150,12,12,0.6)",
						width: 1,
					},
					type: "bar",
				},
			];

			resp.data.responseData.map((r) => {
				temp[0]["x"].push(r.covenantsTested);
				temp[1]["x"].push(r.covenantsPassed);
				temp[2]["x"].push(r.covenantsNotApplicable);
				temp[3]["x"].push(r.covenantsFailed);
				temp[0]["y"].push(r.dealName);
				temp[1]["y"].push(r.dealName);
				temp[2]["y"].push(r.dealName);
				temp[3]["y"].push(r.dealName);
			});
			setConvenantReviewSummery(temp);
		} catch (error) {
			setAlertType("danger");
			setShowAlert(true);
			setAlertMessage(error.response?.data?.Message);
			if (error.response.status == 401) {
				appContext.handleLogout();
			}
		}
	};
	const getAssetVerificationReviewSummary = async () => {
		let url = config.url.API_URL + "asset-verification-review-summary";
		if (selectedDealId != null) {
			url += "?dealId=" + selectedDealId;
		}
		// if (selectedAssetTypeId) {
		// 	url += "&assetTypeId=" + selectedAssetTypeId;
		// }
		try {
			const resp = await axios.get(url);
			// const resp = await axios.get(
			// 	config.url.API_URL +
			// 		"asset-verification-review-summary?dealId=" +
			// 		selectedDealId
			// );
			console.log("getAssetVerificationReviewSummary", resp.data.responseData);
			let temp = [
				{
					x: [],
					y: [],
					name: "Asset Tested",
					orientation: "h",
					marker: {
						color: "rgba(150,175,5,0.6)",
						width: 1,
					},
					type: "bar",
				},
				{
					x: [],
					y: [],
					name: "Attribute Tested",
					orientation: "h",
					marker: {
						color: "rgba(1,175,5,0.6)",
						width: 1,
					},
					type: "bar",
				},
				{
					x: [],
					y: [],
					name: "Passed",
					orientation: "h",
					marker: {
						color: "rgba(45,128,11,0.6)",
						width: 1,
					},
					type: "bar",
				},
				{
					x: [],
					y: [],
					name: "Not Applicable",
					orientation: "h",
					marker: {
						color: "rgba(55,128,191,0.6)",
						width: 1,
					},
					type: "bar",
				},
				{
					x: [],
					y: [],
					name: "Failed",
					orientation: "h",
					marker: {
						color: "rgba(150,12,12,0.6)",
						width: 1,
					},
					type: "bar",
				},
			];

			resp.data.responseData.map((r) => {
				temp[0]["x"].push(r.assetsTested);
				temp[1]["x"].push(r.attributesTested);
				temp[2]["x"].push(r.assetVerificationsPassed);
				temp[3]["x"].push(r.assetVerificationsNotApplicable);
				temp[4]["x"].push(r.assetVerificationsFailed);
				temp[0]["y"].push(r.dealName);
				temp[1]["y"].push(r.dealName);
				temp[2]["y"].push(r.dealName);
				temp[3]["y"].push(r.dealName);
				temp[4]["y"].push(r.dealName);
			});
			setAssetReviewSummery(temp);
		} catch (error) {
			setAlertType("danger");
			console.log("error>>", error, error.error, error.errorResponse);

			setShowAlert(true);
			setAlertMessage(error.response?.data?.Message);
		}
	};
	const getAssetVerificationReviewSummaryTrand = async () => {
		let startDate = moment()
			.subtract(6, "months")
			// .endOf("month")
			.format("YYYY-MM-DD");
		try {
			let url =
				config.url.API_URL +
				"asset-verification-review-summary?startDate=" +
				startDate;
			console.log("KKKKKKKKKKKKKKKKKKKK>>>", selectedDealId);
			if (selectedDealId) {
				url += "&dealId=" + selectedDealId;
			}
			if (selectedAssetTypeId) {
				url += "&assetTypeId=" + selectedAssetTypeId;
			}

			const resp = await axios.get(url);
			// const resp = await axios.get(
			// 	config.url.API_URL +
			// 		"asset-verification-review-summary?startDate=" +
			// 		startDate +
			// 		"&dealId=" +
			// 		selectedDealId
			// );
			console.log(
				"getAssetVerificationReviewSummaryTrand",
				resp.data.responseData
			);
			groupbyEndMonthForAssets(resp.data.responseData);
		} catch (error) {
			setAlertType("danger");
			console.log("error>>", error);
			setShowAlert(true);
			setAlertMessage(error.response?.data?.Message);
		}
	};
	const handleDealChange = (e) => {
		// setTimeout(() => {
		// 	getAssetVerificationReviewSummaryTrand();
		// 	getAssetVerificationReviewSummary();
		// 	getCovenantReviewSummary();
		// 	getConvenantReviewTrand();
		// 	getAssetTapeSummaryByMonth();
		// 	getAssetTapeSummary();
		// }, 1000);
	};
	const handleAssetTypeChange = (e) => {
		// setTimeout(() => {
		// 	setSelectedAssetType(e.target.value);
		// 	getAssetTapeSummaryByMonth();
		// 	getAssetTapeSummary();
		// }, 1000);
	};
	useEffect(() => {
		// setSelectedAssetType(e.target.value);
		getAssetTapeSummaryByMonth();
		getAssetTapeSummary();
	}, [selectedAssetTypeId]);
	useEffect(() => {
		getAssetVerificationReviewSummaryTrand();
		getAssetVerificationReviewSummary();
		getCovenantReviewSummary();
		getConvenantReviewTrand();
		getAssetTapeSummaryByMonth();
		getAssetTapeSummary();
	}, [selectedDealId]);
	return (
		<>
			<SideBar />
			<div id="main_wraper">
				<AamsHeader />
				<div id="dash_board_page">
					<form class="row mb-3">
						<div class="col-auto">
							<select
								class="form-select bg-transparent text-light"
								aria-label="Default select example"
								onChange={(e) => {
									setSelectedDeal(() => e.target.value);

									// handleDealChange(e);
								}}
							>
								<option value="">Select Deal</option>
								{dealData &&
									dealData.map((d) => (
										<option value={d.id} selected={d.id == selectedDealId}>
											{d.name}
										</option>
									))}
								{/* <option value="2">Two</option>
								<option value="3">Three</option> */}
							</select>
						</div>
						{/* <div class="col-auto">
							<select
								class="form-select bg-transparent text-light"
								aria-label="Default select example"
							>
								<option selected>Originator</option>
								<option value="1">One</option>
								<option value="2">Two</option>
								<option value="3">Three</option>
							</select>
						</div> */}
						<div class="col-auto">
							<select
								class="form-select bg-transparent text-light"
								aria-label="Default select example"
								onChange={(e) => setSelectedAssetType(e.target.value)}
							>
								<option selected value="">
									Select Asset Type
								</option>
								{assetTypes &&
									assetTypes.map((a) => (
										<option value={a.id} selected={a.id == selectedAssetTypeId}>
											{a.name}
										</option>
									))}
								{/* <option value="2">Two</option>
								<option value="3">Three</option> */}
							</select>
						</div>
					</form>

					<div class="row">
						<div className="col-sm-12">
							<div className="card mb-3">
								<div className="card-body p-0 rounded-0 border-0">
									<div className="table-responsive">
										<table
											className="table table-bordered text-light mb-0"
											id="dealDetailsTableInDashboard"
										>
											<thead>
												<tr>
													<th>Deals (#)</th>
													<th style={{ "text-align": "end" }}>Deals ($)</th>
													<th>Currently Defaulted Deals</th>
													<th>Open Engagement Periods</th>
													<th>Possible Late Reports</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>{dealData.length}</td>
													<td style={{ "text-align": "end" }}>
														<NumberFormat
															value={dealSum}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"$"}
														/>
													</td>
													<td>{defaultDeal}</td>
													<td>{openEngagementPeriod}</td>
													<td>{lateReport}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div className="col-md-12 ">
							<h3 className="m-0 mb-3">Portfolio</h3>
						</div>
						<div class="col-md-3">
							<div class="card">
								<div class="card-body p-0 ">
									<h3 class="card-title  ">Portfolio Balance by Originator</h3>

									<Plot
										data={[
											{
												// values: [112, 454, 65, 544],
												values: originatorData.value,
												labels: originatorData.key,

												// labels: ["Blue", "Red", "Yellow", "Orange"],
												type: "pie",
											},
										]}
										layout={{
											plot_bgcolor: "#383838",
											paper_bgcolor: "#383838",
											font: {
												color: "#ffffff",
											},
										}}
										config={{ displayModeBar: false }}
										style={{ width: "100%", height: "100%" }}
									/>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="card">
								<div class="card-body p-0 ">
									<h3 class="card-title ">Portfolio Balance by Asset type</h3>

									<Plot
										data={[
											{
												// values: [112, 454, 65, 544],
												values: dealDataByDealName.value?.length
													? dealDataByDealName.value
													: [],
												labels: dealDataByDealName.key?.length
													? dealDataByDealName.key
													: [],

												// labels: ["Blue", "Red", "Yellow", "Orange"],
												type: "pie",
											},
										]}
										layout={{
											plot_bgcolor: "#383838",
											paper_bgcolor: "#383838",
											font: {
												color: "#ffffff",
											},
											yaxis: { fixedrange: true, automargin: true },
											itemsizing: "constant",
											xaxis: { fixedrange: true },
										}}
										config={{ displayModeBar: false }}
										style={{ width: "100%", height: "100%" }}
									/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="card">
								<div class="card-body p-0  ">
									<h3 class="card-title  ">Portfolio Balance by Month</h3>
									{/* <div class='d-flex align-items-center justify-content-center inner'>
                <span>No data found.</span>
              </div> */}
									<div class="m-0">
										<Plot
											data={[
												{
													x: dealSummeryByMonth.x,
													y: dealSummeryByMonth.y,
													name: "Sum of Orignal Amount",
													type: "bar",
												},
											]}
											layout={{
												plot_bgcolor: "#383838",
												paper_bgcolor: "#383838",
												font: {
													color: "#ffffff",
												},
												yaxis: {
													tickwidth: 1,
													fixedrange: true,
													automargin: true,
												},
												itemsizing: "constant",
												xaxis: { fixedrange: true },
											}}
											config={{ displayModeBar: false }}
											style={{ width: "100%", height: "100%" }}
										/>
									</div>
								</div>
							</div>
						</div>
						{/* <div class='col-md-6 mb-4'>
            <div class='monitor__graph'>
              <h3 class="card-title">Recent Convent Testing Results</h3>
              
              <Plot
                data={[
                  {
                    x: [
                      "Microwave",
                      "Washing Machine",
                      "Tv",
                      "Vacuum Cleaner",
                      "Hair Dryer",
                    ],
                    y: [4, 5, 6, 1, 4],
                    name: "2016",
                    type: "bar",
                    orientation: "h",
                  },
                ]}
                layout={{}}
              />
            </div>
          </div> */}
						<div className="col-md-12 ">
							<h3 className="m-0 mb-3">Covenant Tracking</h3>
						</div>
						<div class="col-md-6">
							<div class="card">
								<div class="card-body p-0 ">
									<h3 class="card-title">Recent Covenant Testing Results</h3>
									{/* <div class='d-flex align-items-center justify-content-center inner'>
                <span>No data found.</span>
              </div> */}
									<div class="m-0">
										<Plot
											data={convenantReviewSummery}
											// data={[
											//   {
											//     x: [20, 14, 23],
											//     y: ["giraffes", "orangutans", "monkeys"],
											//     name: "SF Zoo",
											//     orientation: "h",
											//     marker: {
											//       color: "rgba(55,128,191,0.6)",
											//       width: 1,
											//     },
											//     type: "bar",
											//   },

											//   {
											//     x: [12, 18, 29],
											//     y: ["giraffes", "orangutans", "monkeys"],
											//     name: "LA Zoo",
											//     orientation: "h",
											//     type: "bar",
											//     marker: {
											//       color: "rgba(255,153,51,0.6)",
											//       width: 1,
											//     },
											//   },
											// ]}
											layout={{
												plot_bgcolor: "#383838",
												paper_bgcolor: "#383838",
												font: {
													color: "#ffffff",
												},
												barmode: "stack",
												yaxis: {
													tickwidth: 1,
													fixedrange: true,
													automargin: true,
												},
												itemsizing: "constant",
												xaxis: { fixedrange: true },
											}}
											config={{ displayModeBar: false }}
											style={{ width: "100%", height: "100%" }}
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="card">
								<div class="card-body p-0 ">
									<h3 class="card-title">Convenant Testing Trands</h3>
									{/* <div class='d-flex align-items-center justify-content-center inner'>
                <span>No data found.</span>
              </div> */}
									<Plot
										// data={[
										//   {
										//     x: [1, 5, 13, 24, 35, 46, 60],
										//     y: [80, 40, 70, 65, 15, 75, 49],
										//     type: "scatter",
										//     name: "test1",
										//   },
										//   {
										//     x: [4, 9, 17, 21, 31, 42, 56],
										//     y: [64, 81, 3, 49, 25, 17, 26],
										//     type: "scatter",
										//     name: "test2",
										//   },
										// ]}
										data={Object.keys(convenantReviewSummeryTrand?.value)?.map(
											(v) => {
												return {
													x: convenantReviewSummeryTrand.key,
													y: convenantReviewSummeryTrand.value[v],
													type: "scatter",
													name: camelize(v),
												};
											}
										)}
										layout={{
											plot_bgcolor: "#383838",
											paper_bgcolor: "#383838",
											font: {
												color: "#ffffff",
											},
											yaxis: {
												tickwidth: 1,
												fixedrange: true,
												automargin: true,
											},
											itemsizing: "constant",
											xaxis: { fixedrange: true },
										}}
										config={{ displayModeBar: false }}
										style={{ width: "100%", height: "100%" }}
									/>
								</div>
							</div>
						</div>
						<div className="col-md-12 ">
							<p className="m-0 mb-3 note">
								* Includes the results for the most recent completed period for
								each Deal
							</p>
						</div>
						<div className="col-md-12 ">
							<h3 className="m-0 mb-3">Asset Verification</h3>
						</div>
						<div class="col-md-6 ">
							<div class="card">
								<div class="card-body p-0  ">
									<h3 class="card-title ">Recent Asset Verification Results</h3>
									{/* <div class='d-flex align-items-center justify-content-center inner'>
                <span>No data found.</span>
              </div> */}
									<div class="p-0">
										<Plot
											data={assetReviewSummery}
											// data={[
											//   {
											//     x: [20, 14, 23],
											//     y: ["giraffes", "orangutans", "monkeys"],
											//     name: "SF Zoo",
											//     orientation: "h",
											//     marker: {
											//       color: "rgba(55,128,191,0.6)",
											//       width: 1,
											//     },
											//     type: "bar",
											//   },

											//   {
											//     x: [12, 18, 29],
											//     y: ["giraffes", "orangutans", "monkeys"],
											//     name: "LA Zoo",
											//     orientation: "h",
											//     type: "bar",
											//     marker: {
											//       color: "rgba(255,153,51,0.6)",
											//       width: 1,
											//     },
											//   },
											// ]}

											layout={{
												plot_bgcolor: "#383838",
												paper_bgcolor: "#383838",
												font: {
													color: "#ffffff",
												},
												barmode: "stack",
												yaxis: {
													tickwidth: 1,
													fixedrange: true,
													automargin: true,
												},
												itemsizing: "constant",
												xaxis: { fixedrange: true },
											}}
											config={{ displayModeBar: false }}
											style={{ width: "100%", height: "100%" }}
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 ">
							<div class="card ">
								<div class="card-body p-0 ">
									<h3 class="card-title">Asset Verification Trands</h3>
									{/* <div class='d-flex align-items-center justify-content-center inner'>
                <span>No data found.</span>
              </div> */}
									<div class="m-0">
										<Plot
											// data={[
											//   {
											//     x: [1, 5, 13, 24, 35, 46, 60],
											//     y: [80, 40, 70, 65, 15, 75, 49],
											//     type: "scatter",
											//   },
											//   {
											//     x: [4, 9, 17, 21, 31, 42, 56],
											//     y: [64, 81, 3, 49, 25, 17, 26],
											//     type: "scatter",
											//   },
											// ]}
											data={Object.keys(assetReviewSummeryTrand.value).map(
												(v) => {
													return {
														x: assetReviewSummeryTrand.key,
														y: assetReviewSummeryTrand.value[v],
														type: "scatter",
														name: camelize(v),
													};
												}
											)}
											layout={{
												plot_bgcolor: "#383838",
												paper_bgcolor: "#383838",
												font: {
													color: "#ffffff",
												},
												yaxis: { fixedrange: true, automargin: true },
												itemsizing: "constant",
												xaxis: { fixedrange: true },
											}}
											config={{ displayModeBar: false }}
											style={{ width: "100%", height: "100%" }}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="col-md-12 ">
							<p className="m-0 mb-3 note">
								* Includes the results for the most recent completed period for
								each Deal
							</p>
						</div>
					</div>
				</div>
				<AamsFooter />
				{showAlert && (
					<Toaster show={showAlert} message={alertMessage} type={alertType} />
				)}
			</div>
		</>
	);
}

export default Blankpage;
