import { Field, FieldArray, Formik } from "formik";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./ConventReview.scss";

export default function ConventReviewForm({
  covenantReviews,
  results,
  completeCovReview,
  setCovenantReviews,
  submit,
  periodDetails,
}) {
  const [isEdit, setEdit] = useState(false);
  const [isAttrEdit, setAttrEdit] = useState(false);
  console.log("101 cov::", covenantReviews);
  const onDragEnd = (e) => {
    console.log(e);
    const sourceIndex = e.source.index;
    const destIndex = e.destination.index;
    const tempGroups = [...covenantReviews];
    tempGroups.splice(destIndex, 0, tempGroups.splice(sourceIndex, 1)[0]);
    console.log("101 tempGroups>>", tempGroups);
    setCovenantReviews(tempGroups);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Formik
        initialValues={{
          attributes: covenantReviews,
        }}
        onSubmit={(values, { resetForm }) => {}}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* {JSON.stringify(covenantReviews)} */}
            <div className="table-responsive">
              <table className="table table-bordered text-light mb-0">
                <thead className="table-dark">
                  <tr>
                    <th width="5%"></th>
                    <th width="10%">Category</th>
                    <th width="10%">Name</th>
                    <th width="15%">Comparison Value</th>
                    <th width="15%">Current Period Value</th>
                    {/* <th width="10%">Value to Compare</th> */}
                    <th width="20%">Test Description</th>
                    <th width="10%">Results</th>
                    <th width="10%">Status</th>
                    <th width="15%">Action</th>
                  </tr>
                </thead>
                <Droppable droppableId={"droppable-1"}>
                  {(provided, snapshot) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      <FieldArray
                        name="attributes"
                        render={(arrayHelpers) => (
                          <>
                            {values.attributes &&
                              values.attributes.length > 0 &&
                              values.attributes.map((attr, i) => (
                                <>
                                  <Draggable
                                    draggableId={"draggable-tr-" + i}
                                    index={i}
                                    key={i}
                                  >
                                    {(provided, snapshot) => (
                                      <tr
                                        key={"key_" + i}
                                        index={i}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                      >
                                        <td class="p-0 text-center">
                                          <span>
                                            {isEdit && (
                                              <span
                                                className="btn btn-link"
                                                color="primary"
                                                {...provided.dragHandleProps}
                                                // onClick={() => groupOrder(g)}
                                              >
                                                <i class="fas fas fa-sort text-info"></i>
                                              </span>
                                            )}
                                          </span>
                                        </td>
                                        <td>
                                          {attr.covenant.covenantCategoryName}
                                        </td>
                                        <td>{attr.covenant.name}</td>
                                        <td>
                                          {!attr.isEdit && attr.isComplete ? (
                                            attr.comparisonValue
                                          ) : (
                                            <div className="col-auto">
                                              <Field
                                                as="textarea"
                                                rows={1}
                                                className="form-control"
                                                placeholder={"Comparison value"}
                                                name={`attributes[${i}].comparisonValue`}
                                              ></Field>
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {!attr.isEdit && attr.isComplete ? (
                                            attr.note
                                          ) : (
                                            <div className="col-auto">
                                              <Field
                                                as="textarea"
                                                rows={1}
                                                className="form-control"
                                                placeholder={"Notes"}
                                                name={`attributes[${i}].note`}
                                              ></Field>
                                            </div>
                                          )}
                                        </td>
                                        {/* <td>{attr?.covenant?.value}</td> */}
                                        <td>{attr?.covenant?.description}</td>
                                        <td>
                                          {!attr.isEdit && attr.isComplete ? (
                                            <div
                                              className="row"
                                              id="CovenantformDiv"
                                            >
                                              <div className="col-auto">
                                                {attr.resultName}
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              className="row"
                                              id="CovenantformDiv"
                                            >
                                              {/* <label
                                                htmlFor="staticEmail"
                                                className="col-auto col-form-label"
                                              >
                                                Results{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>{" "}
                                              </label> */}
                                              <div className="col-auto">
                                                <Field
                                                  as="select"
                                                  class="form-control"
                                                  name={`attributes[${i}].resultID`}
                                                >
                                                  <option value="">
                                                    Select
                                                  </option>
                                                  {results &&
                                                    results.map((res1, r1) => {
                                                      return (
                                                        <option
                                                          key={r1}
                                                          value={res1.id}
                                                        >
                                                          {res1.name}
                                                        </option>
                                                      );
                                                    })}
                                                </Field>
                                              </div>
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {attr.isComplete
                                            ? "Completed"
                                            : "In progress"}
                                        </td>
                                        <td>
                                          {periodDetails?.statusCode !==
                                            "CLOSED" && (
                                            <>
                                              {attr.isComplete ? (
                                                <div className="col-auto">
                                                  {attr.isEdit ? (
                                                    <button
                                                      className="btn btn-main"
                                                      style={{
                                                        margin: "5px",
                                                      }}
                                                      title="Save"
                                                      onClick={() => {
                                                        setFieldValue(
                                                          `attributes[${i}].isEdit`,
                                                          false
                                                        );
                                                        // completeCovReview(attr.id)
                                                        completeCovReview(attr);
                                                        setAttrEdit(false);
                                                      }}
                                                    >
                                                      <i className="fas fa-save"></i>
                                                    </button>
                                                  ) : (
                                                    <button
                                                      className="btn btn-main"
                                                      style={{
                                                        margin: "5px",
                                                      }}
                                                      title="Edit"
                                                      onClick={
                                                        () => {
                                                          setFieldValue(
                                                            `attributes[${i}].isEdit`,
                                                            true
                                                          );
                                                          setAttrEdit(true);
                                                        }
                                                        // completeCovReview(attr.id)
                                                      }
                                                    >
                                                      <i className="fas fa-edit"></i>
                                                    </button>
                                                  )}
                                                </div>
                                              ) : (
                                                <div className="">
                                                  <button
                                                    className="btn btn-main p-2 m-1"
                                                    // style={{
                                                    //   margin: "5px",
                                                    // }}
                                                    title="Complete"
                                                    onClick={
                                                      () =>
                                                        completeCovReview(attr)
                                                      // completeCovReview(attr.id)
                                                    }
                                                  >
                                                    <i className="fas fa-check"></i>
                                                  </button>
                                                  <button
                                                    className="btn btn-main p-2 m-0"
                                                    // style={{
                                                    //   margin: "5px",
                                                    // }}
                                                    title="Cancel"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      setFieldValue(
                                                        `attributes[${i}].resultID`,
                                                        ""
                                                      );
                                                      setFieldValue(
                                                        `attributes[${i}].note`,
                                                        ""
                                                      );
                                                    }}
                                                  >
                                                    <i className="fa fa-times"></i>
                                                  </button>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                </>
                              ))}
                          </>
                        )}
                      />
                    </tbody>
                  )}
                </Droppable>
              </table>
              {periodDetails?.statusCode !== "CLOSED" && (
                <>
                  {values.attributes.length > 1 && !isAttrEdit && (
                    <div>
                      {isEdit ? (
                        <button
                          class="btn btn-primary m-2 px-3 py-2 "
                          onClick={() => {
                            setEdit(false);
                            submit();
                          }}
                          title="Save order"
                        >
                          <i class="fa fa-save "></i>
                        </button>
                      ) : (
                        <button
                          class="btn btn-primary m-2 p-2"
                          onClick={() => setEdit(true)}
                          title="Edit order"
                        >
                          <img src="assets/images/reorder.png" class="icon" />
                        </button>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </form>
        )}
      </Formik>
    </DragDropContext>
  );
}
