import React from "react";
import { ProgressBar } from "react-bootstrap";

export default function LoaderProgressBar({ now }) {
  //   const now = 60;
  return (
    <div className=" m-2">
      <ProgressBar now={now} label={`${now}%`} />
    </div>
  );
}
