import MyTooltrip from "components/MyTooltrip/MyTooltrip";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Table } from "react-bootstrap";
import {
  camelToTitle,
  isValidDate,
  lowercaseKeys,
  searchKeyword,
  strlimit,
  titleToCamel,
} from "../../helpers/data";
import "./PortfolioHistory.scss";

export default function TapListModal({
  showb,
  handleCloseb,
  data,
  copydata,
  setData,
  selectedType,
}) {
  const target = useRef(null);
  const [searchText, setSearchText] = useState(null);
  console.log("11 list modal>>", data);
  // const [assetTapeLocal, setAssetTapeLocal] = useState(data?.assetTapeDetails);
  const mapping = data?.mapping && JSON.parse(data?.mapping);
  const assetTapeDetails = data?.uploadType
    ? data?.uploadType.toLowerCase().trim() == "asset"
      ? data?.assetTapeDetails
      : data?.collectionTapeDetails
    : selectedType == "asset"
    ? data?.assetTapeDetails
    : data?.collectionTapeDetails;
  const assetTapeCopyDetails = data?.uploadType
    ? data?.uploadType.toLowerCase().trim() == "asset"
      ? copydata?.assetTapeDetails
      : copydata?.collectionTapeDetails
    : selectedType == "asset"
    ? copydata?.assetTapeDetails
    : copydata?.collectionTapeDetails;
  // console.log(
  //   {
  //     mapping,
  //     assetTapeDetails,
  //     data,
  //     selectedType,
  //     assetTapeCopyDetails,
  //     copydata,
  //   },
  //   data?.collectionTapeDetails,
  //   selectedType == "asset"
  //     ? data?.assetTapeDetails
  //     : data?.collectionTapeDetails,
  //   selectedType == "asset"
  // );
  const [errorField, setErrorField] = useState(null);

  // const handleChange = (e, index) => {
  //   console.log(e.target.value);
  //   const tempMappingData = [...mappingData];
  //   tempMappingData[index].mappingField = e.target.value;
  //   setMappingData(tempMappingData);
  // };

  const search = (searchText) => {
    if (searchText == "" || searchText.length < 3) {
      setData(copydata);
    } else {
      const res = searchKeyword(assetTapeCopyDetails, searchText);
      if (selectedType === "asset") {
        const { assetTapeDetails, ...rest } = data;
        setData({ ...rest, assetTapeDetails: res });
      } else {
        const { collectionTapeDetails, ...rest } = data;
        setData({ ...rest, collectionTapeDetails: res });
      }
    }
  };
  return (
    <Modal
      show={showb}
      onHide={handleCloseb}
      // size="lg"
      // fullscreen={true}
      // aria-labelledby="contained-modal-title-vcenter"
      centered
      className="eng_file_upload_modal full-sc"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title
          style={{
            margin: "0",
            width: "100%",
          }}
        >
          <div class="row justify-content-between">
            <div class="col">Tape list </div>
            <div class="col"></div>
            <div class="col">
              <div class="d-inline-block w-100">
                <div class="" style={{ width: "100%" }}>
                  <input
                    style={{ height: "43px" }}
                    type="text"
                    class="form-control p-2 text-white"
                    placeholder="Search by keyword(Give minimum 3 characters)"
                    aria-label="Search by keyword"
                    aria-describedby="basic-addon2"
                    onChange={(e) => search(e.target.value)}
                    // onChange={(e) => setSearchText(e.target.value)}
                    // onKeyDown={(e) => {
                    //   if (e.key === "Enter") {
                    //     search();
                    //   }
                    // }}
                  />
                </div>
                {/* <button
                  class="btn btn-outline-secondary  m-0 p-2  right-0 top-0 "
                  style={{ width: "15%" }}
                  type="button"
                  onClick={search}
                >
                  Search
                </button> */}
              </div>
            </div>
          </div>
          {/* {success && (
            <div
              class="alert alert-success"
              style={{
                padding: "5px",
                fontSize: 10,
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              {success}
            </div>
          )}
          {error && (
            <div
              class="alert alert-danger"
              style={{
                padding: "5px",
                fontSize: 12,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
              }}
            >
              {error}
              <a
                href="javascript:void(0);"
                onClick={handleShowc}
                style={{
                  position: "absolute",
                  right: 10,
                  backgroundColor: "#ccc",
                  padding: "0px 8px",
                  color: "#333",
                  borderRadius: 5,
                }}
              >
                View Details
              </a>
            </div>
          )} */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className=""
          style={{
            // border: "1px solid #000",
            maxHeight: "500px",
            overflow: "auto",
          }}
          id="scrollarea-content"
        >
          <Table
            className="table table-bordered text-light mb-0 short"
            id="tap_list_mod_table"
          >
            <thead className="table-dark">
              <tr>
                {mapping &&
                  Object.keys(mapping).map((k, index) => <th>{mapping[k]}</th>)}
              </tr>
            </thead>
            <tbody>
              {assetTapeDetails &&
                assetTapeDetails.map((a) => {
                  const aa = lowercaseKeys(a);
                  return (
                    <tr>
                      {mapping &&
                        Object.keys(mapping).map((k) => (
                          <td className="p-0">
                            {/* {moment(aa[k.toLocaleLowerCase()]).isValid()
                              ? moment(aa[k.toLocaleLowerCase()])
                              : aa[k.toLocaleLowerCase()]} */}
                            {isValidDate(aa[k.toLocaleLowerCase()])
                              ? moment(aa[k.toLocaleLowerCase()]).format(
                                  "YYYY-MM-DD"
                                )
                              : aa[k.toLocaleLowerCase()]}
                          </td>
                        ))}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>

        <div>
          <Button
            style={{
              fontSize: "16px",
              marginRight: "8px",
            }}
            className="btn btn-success"
            variant="success"
            type="button"
            onClick={handleCloseb}
          >
            <i class="fas fa-close"></i> Save
          </Button>
          <span class="float-end">
            Total Record: {assetTapeDetails && assetTapeDetails.length}
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
}
