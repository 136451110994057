import React from "react";
import "./MyBreadcrumb.scss";
import Breadcrumb from "react-bootstrap/Breadcrumb";
export default function MyBreadcrumb(props) {
  const items = props.items;
  return (
    <div class="my-breadcrumb-container">
      <Breadcrumb>
        {items &&
          items?.length > 0 &&
          items.map((i, k) => {
            if (k == items.length - 1) {
              return <Breadcrumb.Item active>{i.op}</Breadcrumb.Item>;
            } else {
              return <Breadcrumb.Item href={i.link}>{i.op}</Breadcrumb.Item>;
            }
          })}
      </Breadcrumb>
    </div>
  );
}
