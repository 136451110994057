import { useState, useEffect, useRef } from "react";
import { config } from "../../constant";
import axios from "axios";
import { Field, Formik } from "formik";
import * as yup from "yup";
import Loader from "../Loader";
import { sortByDate } from "helpers/data";
import { Button, Form, Modal } from "react-bootstrap";

import AssetVerificationView from "./AssetVerificationView";
import api from "config/api";
import "./AssetVerification.scss";
const getVerificationSrcUrl = config.url.API_URL + "verification-source";
const getValueTypeUrl = config.url.API_URL + "value-type";
const getSampleMethodUrl = config.url.API_URL + "sample-method";
const getAssetTypeUrl = config.url.API_URL + "asset-type";
const postAVSUrl = config.url.API_URL + "asset-verification";
const postAssetVerificationAttrUrl =
  config.url.API_URL + "asset-verification-attribute";
const getAssetVerificationAttrUrl =
  config.url.API_URL + "asset-verification-attribute";
const assetVerificationAttributeGroup =
  config.url.API_URL + "asset-verification-attribute-group";

const initialValues = {
  name: "",
  description: "",
  verificationSourceID: "",
  dataValueTypeID: "",
  value: "",
};

const initialSetupValues = {
  assetTypeID: "",
  sampleMethodID: "",
  sampleValue: "",
};

function AmssVerification(props) {
  // console.log("assetVerification id>>>",props)
  let [showstat, setShowsStat] = useState(0);
  const [assetVer, setAssetVer] = useState();
  const [values, setValues] = useState(initialValues);
  const [setupValues, setSetupValues] = useState(initialSetupValues);
  const [attributes, setAttributes] = useState([]);
  const [avs, setAvs] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);
  const [sampleMethods, setSampleMethods] = useState([]);
  const [verificationSrc, setVerificationSrc] = useState([]);
  const [valueTypes, setValueTypes] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [errorAttribures, setErrorArrributes] = useState(null);
  const [isGroupActive, setGroupActive] = useState(false);
  const [isGroupEdit, setGroupEdit] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isGroupEditModalShow, setGroupEditModalShow] = useState(false);
  const [isGroupOrderFlag, setGroupOrderFlag] = useState(false);
  const [isAttributeOrderFlag, setAttributeOrderFlag] = useState(false);

  const dragItem = useRef();
  const dragOverItem = useRef();

  const assetVerificationValidationSchema = yup.object().shape({
    name: yup.string().required("Name is a required field"),
    // value: yup.string().required("Value is a required field"),
    // dataValueTypeID: yup.string().required("Value type is a required field"),
    verificationSource: yup
      .string()
      .required("Verification source is a required field"),
    description: yup.string().required("Description is a required field"),
    assetVerificationAttributeGroupID: yup
      .string()
      .required("Asset verification attributes group is a required field"),
  });
  const assetVerificationGroupAddValidationSchema = yup.object().shape({
    name: yup.string().required("Group name is a required field"),
  });
  const handleCloseGroupModal = () => {
    setGroupEditModalShow(false);
  };
  useEffect(() => {
    setAssetVer(() => props.assetverifcation);
  }, [props]);
  const handleInputChange = (e) => {
    //   if(e.target.name === 'value'){
    // if(isNaN(e.target.value)) return false
    //   }
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    // console.log(values);
  };
  const handleSetupInputChange = (e) => {
    if (e.target.name === "sampleValue") {
      if (isNaN(e.target.value)) return false;
    }
    const { name, value } = e.target;
    setSetupValues({
      ...setupValues,
      [name]: value,
    });
    // console.log(setupValues);
  };

  const getAttributesWithAxios = async () => {
    if (props.assetverifcation !== "") {
      //   setLoading(true);
      const respAttributes = await axios.get(
        getAssetVerificationAttrUrl +
          "?AssetVerificationID=" +
          props.assetverifcation
      );
      const attr = respAttributes.data.responseData.sort(sortByDate);
      console.log("101 attr>", attr);
      setAttributes(attr);
      //   setLoading(false);
    }
  };

  const getAVSSetupWithAxios = async () => {
    if (props.assetverifcation !== "") {
      const respAvs = await axios.get(
        postAVSUrl + "?EngagementID=" + props.engagement
      );
      // console.log(respAvs.data.responseData);
      const avss = respAvs.data.responseData[0];
      setAvs(avss);
      setSetupValues({
        ...setupValues,
        ["assetTypeID"]: avss.assetTypeID,
        ["sampleMethodID"]: avss.sampleMethodID,
        ["sampleValue"]: avss.sampleValue,
      });
      //setSetupValues({ ...setupValues, ['sampleMethodID']: avss.sampleMethodID });
      //setSetupValues({ ...setupValues, ['sampleValue']: avss.sampleValue	});
      // console.log(setupValues);
    }
  };

  const getAssetTypesWithAxios = async () => {
    const assetTypesData = await axios.get(getAssetTypeUrl);
    //console.log(assetTypesData.data.responseData);
    let types = assetTypesData.data.responseData;
    setAssetTypes(types);
  };

  const getSampleMethodsWithAxios = async () => {
    const sampleMethodsData = await axios.get(getSampleMethodUrl);
    //console.log(sampleMethodsData.data.responseData);
    let methods = sampleMethodsData.data.responseData;
    setSampleMethods(methods);
  };

  const getAssetVerificationAttributesGroup = async () => {
    try {
      //   setLoading(true);
      const assetGroupData = await axios.get(
        assetVerificationAttributeGroup +
          "?assetVerificationID=" +
          props?.assetverifcation
      );
      console.log("101 assetVerificationAttributeGroup", assetGroupData);
      let groups = assetGroupData.data?.responseData;
      setGroups(groups);
      //   setLoading(false);
    } catch (error) {
      console.log("err>>>>", error.response);
      //   setLoading(false);
    }
  };
  const setAssetVerificationAttributesGroup = async (values) => {
    try {
      const dataArr = {
        ...values,
      };
      const headers = {
        "content-type": "application/json",
      };
      if (values.hasOwnProperty("id")) {
        const assetGroupData = await axios.put(
          assetVerificationAttributeGroup,
          dataArr,
          headers
        );
        handleCloseGroupModal();
      } else {
        const assetGroupData = await axios.post(
          assetVerificationAttributeGroup,
          dataArr,
          headers
        );
      }
      // console.log("assetVerificationAttributeGroup", assetGroupData);
      getAssetVerificationAttributesGroup();
    } catch (error) {
      console.log("err>>>>", error.response);
    }
  };
  const saveAVSSetup = () => {
    //engagementID: props.engagement, name: values.name, description: values.description, covenantTypeID: values.covenantTypeID, covenantCategoryID: values.covenantCategoryID, verificationSourceID: values.verificationSourceID, dataValueTypeID: values.dataValueTypeID, value: values.value
    const dataArr = {
      engagementID: props.engagement,
      assetTypeID: setupValues.assetTypeID,
      sampleMethodID: setupValues.sampleMethodID,
      sampleValue: setupValues.sampleValue,
    };
    const headers = {
      "content-type": "application/json",
    };
    setLoading(true);
    axios
      .post(postAVSUrl, dataArr, { headers })
      .then((avsresp) => {
        setLoading(false);
        props.getEngagementsWithAxios();
        // setAssetVer(()=>avsresp.data.responseData?.id);
        // console.log("resp>>", avsresp, avsresp.data.responseData?.id, assetVer);
        getAVSSetupWithAxios();
        //alert('Successfully created AVS Setup');
      })
      .catch((error) => {
        setLoading(false);
        // error response
      });
  };

  const editAVSSetup = () => {
    //engagementID: props.engagement, name: values.name, description: values.description, covenantTypeID: values.covenantTypeID, covenantCategoryID: values.covenantCategoryID, verificationSourceID: values.verificationSourceID, dataValueTypeID: values.dataValueTypeID, value: values.value
    const dataArr = {
      id: avs.id,
      assetTypeID: setupValues.assetTypeID,
      sampleMethodID: setupValues.sampleMethodID,
      sampleValue: setupValues.sampleValue,
    };
    setLoading(true);
    const headers = {
      "content-type": "application/json",
    };
    axios
      .put(postAVSUrl, dataArr, { headers })
      .then((avseresp) => {
        setLoading(false);
        getAVSSetupWithAxios();
        // alert("Setup Updated successfully");
      })
      .catch((error) => {
        // error response
        setLoading(false);
      });
  };
  // asset verification group reordering using drag and drop and api
  const reorderAssetVerification = async () => {
    let updateGroups = [];
    const assetVerificationID = groups[0]?.assetVerificationID;
    updateGroups = groups.map((g) => g?.id);
    // .filter((gg) => gg);
    console.log("Groups>>", groups, assetVerificationID, updateGroups);
    const headers = {
      "content-type": "application/json",
    };
    setLoading(true);
    axios
      .put(
        api.assetVerificationGroupReorder,
        {
          assetVerificationID: assetVerificationID,
          orderedAssetVerificationAttributeGroupIDs: updateGroups,
        },
        {
          headers,
        }
      )
      .then((resp) => {
        setLoading(false);
        // props.getEngagementsWithAxios();
        // setAssetVer(()=>avsresp.data.responseData?.id);
        // console.log("resp>>", avsresp, avsresp.data.responseData?.id, assetVer);
        getAssetVerificationAttributesGroup();
        setGroupOrderFlag(false);

        //alert('Successfully created AVS Setup');
      })
      .catch((error) => {
        setLoading(false);
        // error response
      });
  };
  const reorderAssetVerificationAttributes = async (data) => {
    let updateAttributes = [];
    const assetVerificationID = data[0]?.assetVerificationID;
    const assetVerificationAttributeGroupID =
      data[0].assetVerificationAttributeGroupID;
    updateAttributes = data.map((d) => d?.id);
    // .filter((gg) => gg);
    // console.log("Groups>>", groups, assetVerificationID, updateGroups);
    const headers = {
      "content-type": "application/json",
    };
    setLoading(true);
    axios
      .put(
        api.assetVerificationAttributesReorder,
        {
          assetVerificationID: assetVerificationID,
          assetVerificationAttributeGroupID: assetVerificationAttributeGroupID,
          orderedAssetVerificationAttributeIDs: updateAttributes,
        },
        {
          headers,
        }
      )
      .then((resp) => {
        setLoading(false);
        // props.getEngagementsWithAxios();
        // setAssetVer(()=>avsresp.data.responseData?.id);
        // console.log("resp>>", avsresp, avsresp.data.responseData?.id, assetVer);
        getAssetVerificationAttributesGroup();
        setAttributeOrderFlag(false);
        //alert('Successfully created AVS Setup');
      })
      .catch((error) => {
        setLoading(false);
        // error response
      });
  };
  const addNewAttribute = () => {
    setShowsStat(1);
    getVerificationSrcWithAxios();
    getValueTypesWithAxios();
  };

  const getVerificationSrcWithAxios = async () => {
    const verificationSrcData = await axios.get(getVerificationSrcUrl);
    //console.log(verificationSrcData.data.responseData);
    let verification = verificationSrcData.data.responseData;
    setVerificationSrc(verification);
  };

  const getValueTypesWithAxios = async () => {
    const valueTypesData = await axios.get(getValueTypeUrl);
    //console.log(valueTypesData.data.responseData);
    let valuet = valueTypesData.data.responseData;
    setValueTypes(valuet);
  };

  const backAVSList = () => {
    setShowsStat(0);
  };
  const saveAVSAttribute = (values) => {
    // e.preventDefault()
    setLoading(true);
    setErrorArrributes(null);
    //engagementID: props.engagement, name: values.name, description: values.description, covenantTypeID: values.covenantTypeID, covenantCategoryID: values.covenantCategoryID, verificationSourceID: values.verificationSourceID, dataValueTypeID: values.dataValueTypeID, value: values.value
    let dataAttrArr = {
      name: values.name,
      description: values.description,
      verificationSource: values.verificationSource,
      dataValueTypeID: values.dataValueTypeID,
      value: values.value,
      // assetVerificationID: assetVer,
    };
    if (values.hasOwnProperty("assetVerificationAttributeGroupID")) {
      dataAttrArr["assetVerificationAttributeGroupID"] =
        values.assetVerificationAttributeGroupID;
    }
    const headers = {
      "content-type": "application/json",
    };
    if (isEdit) {
      dataAttrArr.id = selectedAttributes.id;
      axios
        .put(postAssetVerificationAttrUrl, dataAttrArr, { headers })
        .then((attrresp) => {
          setLoading(false);
          setErrorArrributes(null);
          console.log(attrresp);
          if (attrresp.data.status) {
            setShowsStat(0);
            getAttributesWithAxios();
            getAssetVerificationAttributesGroup();

            setEdit(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("error>>", error.response);
          setErrorArrributes(error?.response?.data?.Message);
          // error response
        });
    } else {
      dataAttrArr.assetVerificationID = props.assetverifcation;
      setLoading(true);
      axios
        .post(postAssetVerificationAttrUrl, dataAttrArr, { headers })
        .then((attrresp) => {
          console.log(attrresp);
          setErrorArrributes(null);
          setLoading(false);
          if (attrresp.data.status) {
            setShowsStat(0);
            getAttributesWithAxios();
            getAssetVerificationAttributesGroup();
            setEdit(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          setErrorArrributes(error?.response?.data?.Message);
          // error response
        });
    }
  };

  const editAVSAttribute = (item) => {
    setShowsStat(1);
    console.log("item>>>", item);
    setEdit(true);
    setSelectedAttributes(item);
    // const initialValues = {
    //   name: item.name,
    //   description: item.description,
    //   verificationSource: item.verificationSource,
    //   dataValueTypeID: item.dataValueTypeID,
    //   value: item.value,
    // };
    // setValues(() => initialValues);
  };
  const delAVSAttribute = async (id) => {
    if (window.confirm("Are you sure you wish to delete the Attribute Test?")) {
      try {
        setLoading(true);
        const verificationSrcData = await axios.delete(
          postAssetVerificationAttrUrl + "/" + id
        );
        setLoading(false);
        getAttributesWithAxios();
      } catch (err) {
        setLoading(false);
        console.log("err");
      }
    }
  };
  const onGroupOrderFlagOpen = () => {
    if (isAttributeOrderFlag) {
      // setAttributeOrderFlag(false);
      alert("Save attributes changes first");
      return;
    }
    setGroupOrderFlag(true);
  };
  const onAttributesFlagOpen = () => {
    if (isGroupOrderFlag) {
      alert("Save group changes first");
      return;
    }
    setAttributeOrderFlag(true);
  };
  useEffect(() => {
    if (props.engagement) {
      //getPeriodsWithAxios();
      getAttributesWithAxios();
      getAVSSetupWithAxios();
    }

    getAssetTypesWithAxios();
    getSampleMethodsWithAxios();
    getVerificationSrcWithAxios();
    getValueTypesWithAxios();
    getAssetVerificationAttributesGroup();
  }, [props]);

  return (
    <>
      <div className="avs-setup" id="avsSetup">
        AVS Setup
      </div>
      <div className="form_holder">
        <div className="row">
          <div className="col-md-12">
            <div className="row inner-accordian-form-sec">
              <div className="col-md-4">
                <div className="form-group mb-2">
                  <label htmlFor="basicInput">
                    Asset Type <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select bg-transparent text-light"
                    aria-label="Default select example"
                    id="assetTypeID"
                    name="assetTypeID"
                    value={setupValues.assetTypeID}
                    onChange={handleSetupInputChange}
                  >
                    <option value="">Select</option>
                    {assetTypes &&
                      assetTypes.map((assetType, at) => {
                        return (
                          <option key={at} value={assetType.id}>
                            {assetType.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-2">
                  <label htmlFor="basicInput">
                    Sample Selection Method{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select bg-transparent text-light"
                    aria-label="Default select example"
                    id="sampleMethodID"
                    name="sampleMethodID"
                    value={setupValues.sampleMethodID}
                    onChange={(e) => {
                      handleSetupInputChange(e);
                      setSetupValues((p) => ({ ...p, sampleValue: 0 }));
                    }}
                  >
                    <option value="">Select</option>
                    {sampleMethods &&
                      sampleMethods.map((sampleMethod, sm) => {
                        return (
                          <option key={sm} value={sampleMethod.id}>
                            {sampleMethod.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mb-2">
                  {sampleMethods &&
                    sampleMethods
                      .filter((sm) => sm.id == setupValues.sampleMethodID)
                      .map((s) => {
                        if (s.code == "Fixed Count")
                          return (
                            <label htmlFor="basicInput">
                              Enter count <span className="text-danger">*</span>
                            </label>
                          );
                        else if (s.code == "Percentage")
                          return (
                            <label htmlFor="basicInput">
                              Enter % value{" "}
                              <span className="text-danger">*</span>
                            </label>
                          );
                        else
                          return (
                            <label htmlFor="basicInput">
                              Enter value <span className="text-danger">*</span>
                            </label>
                          );
                      })}
                  {!setupValues.sampleMethodID && (
                    <label htmlFor="basicInput">
                      Enter value <span className="text-danger">*</span>
                    </label>
                  )}
                  {/* <label htmlFor='basicInput'>Enter % value <span className='text-danger'>*</span></label> */}
                  <input
                    type="text"
                    className="form-control bg-transparent text-light"
                    id="sampleValue"
                    name="sampleValue"
                    value={setupValues.sampleValue}
                    onChange={handleSetupInputChange}
                    placeholder="Enter % Value"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          {assetVer === "" ? (
            <button
              className="btn btn-primary float-end"
              color="primary"
              onClick={saveAVSSetup}
              title="Save setup"
            >
              <i class="fas fa-save"></i>
            </button>
          ) : (
            <button
              className="btn btn-primary float-end"
              color="primary"
              onClick={editAVSSetup}
              title="Save setup"
            >
              <i class="fas fa-save"></i>
              {/* <i class="fas fa-edit"></i> */}
            </button>
          )}
        </div>
        <div className="clearfix"></div>

        <div
          className={
            showstat === 1
              ? ""
              : "avs-setup d-flex justify-content-end align-items-center"
          }
          style={showstat === 1 ? { display: "none" } : { display: "block" }}
          id="avsHead"
        >
          <div>
            {assetVer !== "" ? (
              <button
                onClick={addNewAttribute}
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title=""
                data-bs-original-title="Add New Covenant Checklist Item"
                aria-label="Add New Checklist Item"
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <Formik
          initialValues={{
            name: isEdit ? selectedAttributes.name : "",
            // value: isEdit ? selectedAttributes.value : "",
            // dataValueTypeID: isEdit ? selectedAttributes.dataValueTypeID : "",
            verificationSource: isEdit
              ? selectedAttributes.verificationSource
              : "",
            description: isEdit ? selectedAttributes.description : "",
            assetVerificationAttributeGroupID: isEdit
              ? selectedAttributes.assetVerificationAttributeGroupID
              : "",
          }}
          enableReinitialize={true}
          onSubmit={(values, { resetForm }) => {
            saveAVSAttribute(values);
            resetForm();
          }}
          validationSchema={assetVerificationValidationSchema}
        >
          {({
            handleChange,
            handleSubmit,
            touched,
            values,
            errors,
            resetForm,
          }) => (
            <form
              onSubmit={handleSubmit}
              className="form_holder p-0"
              style={
                showstat === 0 ? { display: "none" } : { display: "block" }
              }
            >
              {errorAttribures && (
                <div class="alert alert-danger">{errorAttribures}</div>
              )}
              <div className="avs-setup mb-2 d-flex justify-content-between">
                {" "}
                Attributes
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="row inner-accordian-form-sec">
                    <div className="col-md-4">
                      <div className="form-group mb-2">
                        <label htmlFor="basicInput">
                          Attribute test name{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          type="text"
                          className="form-control bg-transparent text-light"
                          id="name"
                          name="name"
                          placeholder="Enter Test Name"
                        />
                        {errors.name && touched.name ? (
                          <div class="text-danger">{errors.name}</div>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group mb-2">
                        <label htmlFor="basicInput">
                          Value to Compare{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          type="text"
                          className="form-control bg-transparent text-light"
                          id="value"
                          name="value"
                          // value={values.value}
                          // onChange={handleInputChange}
                          placeholder="Enter Comparison Value"
                        />
                        {errors.value && touched.value ? (
                          <div class="text-danger">{errors.value}</div>
                        ) : null}
                      </div>
                    </div> */}
                    {/* <div className="col-md-6">
                      <div className="form-group mb-2">
                        <label htmlFor="basicInput">
                          Value type <span className="text-danger">*</span>
                        </label>
                        <Field
                          as="select"
                          className="form-select bg-transparent text-light"
                          id="dataValueTypeID"
                          name="dataValueTypeID"
                        >
                          <option value="">Select</option>
                          {valueTypes &&
                            valueTypes.map((valueType, vt) => {
                              return (
                                <option key={vt} value={valueType.id}>
                                  {valueType.name}
                                </option>
                              );
                            })}
                        </Field>
                        {errors.dataValueTypeID && touched.dataValueTypeID ? (
                          <div class="text-danger">
                            {errors.dataValueTypeID}
                          </div>
                        ) : null}
                      </div>
                    </div> */}
                    <div className="col-md-4">
                      <div className="form-group mb-2">
                        <label htmlFor="basicInput">
                          Source Name <span className="text-danger">*</span>
                        </label>
                        <Field
                          type="text"
                          className=" form-control bg-transparent text-light"
                          id="VerificationSource"
                          name="verificationSource"
                          // value={values.VerificationSource}
                          // onChange={handleInputChange}
                          placeholder="Enter  Value"
                          // required
                        />
                        {errors.verificationSource &&
                        touched.verificationSource ? (
                          <div class="text-danger">
                            {errors.verificationSource}
                          </div>
                        ) : null}
                        {/* <select
                      className='form-select'
                      aria-label='Default select example'
                      name='verificationSourceID'
                      value={values.verificationSourceID}
                      onChange={handleInputChange}
                      required
                    >
                      <option value=''>Select</option>
                      {verificationSrc &&
                        verificationSrc.map((source, vs) => {
                          return (
                            <option key={vs} value={source.id}>
                              {source.name}
                            </option>
                          );
                        })}
                    </select> */}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group mb-2">
                        <label htmlFor="basicInput">
                          Group
                          <span className="text-danger">*</span>
                        </label>
                        {/* <fieldset className="form-group"> */}
                        <Field
                          as="select"
                          className="form-select bg-transparent text-light"
                          id="assetVerificationAttributeGroupID"
                          name="assetVerificationAttributeGroupID"
                          // value={values.dataValueTypeID}
                          // onChange={handleInputChange}
                          // required
                        >
                          <option value="">Select</option>
                          {groups &&
                            groups.map((g) => (
                              <option value={g?.id}>{g?.name}</option>
                            ))}
                        </Field>
                        {/* </fieldset> */}
                        {errors.assetVerificationAttributeGroupID &&
                        touched.assetVerificationAttributeGroupID ? (
                          <div class="text-danger">
                            {errors.assetVerificationAttributeGroupID}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group mb-2">
                        <label htmlFor="basicInput">
                          Description <span className="text-danger">*</span>
                        </label>
                        {/* <fieldset className="form-group"> */}
                        <Field
                          as="textarea"
                          className="form-control text-area-inner bg-transparent text-light"
                          id="description"
                          name="description"
                          // value={values.description}
                          // onChange={handleInputChange}
                          rows="3"
                          // required
                        />
                        {errors.description && touched.description ? (
                          <div class="text-danger">{errors.description}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div class="d-flex justify-content-between">
                  <div>
                    {" "}
                    <button
                      className="btn btn-primary"
                      color="primary"
                      onClick={() => {
                        backAVSList();
                        resetForm();
                      }}
                      type="button"
                      title="Back"
                    >
                      <i class="fas fa-arrow-left"></i>
                    </button>
                  </div>
                  <div></div>
                  <div>
                    {" "}
                    <button
                      type="submit"
                      className="btn btn-primary"
                      color="primary"
                      // onClick={saveAVSAttribute}
                      title="Save"
                    >
                      <i class="fas fa-save"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
        {!isAttributeOrderFlag && (
          <>
            {!isGroupActive ? (
              <>
                <div class="d-flex justify-content-between">
                  <div>
                    <button
                      className="btn create_group"
                      color="primary"
                      onClick={() => {
                        setGroupActive(true);
                        setSelectedGroup(null);
                        setGroupEdit(false);
                      }}
                    >
                      <i class="fas fa-plus-circle"></i> Create Group
                    </button>
                  </div>
                  <div>
                    {isGroupOrderFlag ? (
                      <button
                        className="btn create_group bg-primary "
                        color="primary"
                        onClick={() => {
                          reorderAssetVerification();
                        }}
                        title="Save group
                    order"
                      >
                        <i class="fa fa-save" aria-hidden="true"></i>
                      </button>
                    ) : (
                      <button
                        className="btn create_group bg-primary"
                        color="primary"
                        onClick={() => {
                          onGroupOrderFlagOpen();
                        }}
                        title="Edit group order"
                      >
                        <img src="assets/images/reorder.png" class="icon" />
                      </button>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <Formik
                initialValues={{
                  name: "",
                  assetVerificationID: props.assetverifcation,
                }}
                onSubmit={(values, { resetForm }) => {
                  // console.log(values);
                  setAssetVerificationAttributesGroup(values);
                  resetForm();
                }}
                validationSchema={assetVerificationGroupAddValidationSchema}
              >
                {({
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  errors,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row mt-3">
                      <div className="col-md-3">
                        <div className="deal-left-title">Group Name:</div>
                      </div>
                      <div className="col-md-4">
                        <div className="deal-right-text">
                          <Field
                            type="text"
                            className="form-control bg-transparent text-light"
                            id=""
                            placeholder="Enter Group Name"
                            name="name"
                          />
                          {errors.name && touched.name ? (
                            <div class="text-danger">{errors.name}</div>
                          ) : null}
                        </div>
                      </div>
                      <button
                        className="btn btn-primary col-md-1 "
                        color="primary"
                        style={{ marginTop: -2 }}
                        type="submit"
                        title="Save"
                      >
                        <i class="fas fa-save"></i>
                      </button>
                      <button
                        className="btn btn-default col-md-1 "
                        color="primary"
                        style={{ marginTop: -2 }}
                        type="button"
                        title="Back"
                        onClick={() => {
                          setGroupActive(false);
                          resetForm();
                        }}
                      >
                        <i class="fas fa-arrow-left"></i>
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </>
        )}
        {attributes &&
          attributes.filter((a) =>
            a?.assetVerificationAttributeGroupID ? false : true
          ).length > 0 && (
            <fieldset>
              <legend>
                Default{" "}
                {/* <span className="flote-end">
									<button className="btn btn-link" color="primary">
										<i class="fas fa-edit"></i>
									</button>
								</span> */}
              </legend>

              <div
                className="avs-table"
                id="avsTable"
                style={
                  showstat === 1 ? { display: "block" } : { display: "block" }
                }
              >
                <div className="table-responsive">
                  <table className="table table-bordered text-light mb-0">
                    <thead className="table-dark">
                      <tr>
                        <th>Attribute Test Name</th>
                        <th>Test Description </th>
                        <th>Source Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody id="avsTableApp">
                      {attributes &&
                        attributes
                          .filter((a) =>
                            a?.assetVerificationAttributeGroupID ? false : true
                          )
                          .map((attribute, attr) => {
                            return (
                              <tr key={attr}>
                                <td className="text-bold-500">
                                  {attribute.name}
                                </td>
                                <td>{attribute.description}</td>
                                <td>{attribute.verificationSource}</td>

                                <td>
                                  <div className="d-flex table-action">
                                    <a
                                      className="table-acton-icon"
                                      title="Edit Template"
                                      onClick={() =>
                                        editAVSAttribute(attribute)
                                      }
                                    >
                                      <i
                                        className="fa fa-edit text-info"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                    <a
                                      title="Delete "
                                      class="table-acton-icon ml-2"
                                      onClick={() =>
                                        delAVSAttribute(attribute.id)
                                      }
                                    >
                                      <i className="fas fa-trash text-info"></i>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              </div>
            </fieldset>
          )}

        {/* {groups &&
          groups.map((g, index) => (
            <fieldset index={index}>
              <legend>
                {g?.name}{" "}
                <span className="flote-end">
                  <button
                    className="btn btn-link"
                    color="primary"
                    onClick={() => {
                      setSelectedGroup(g);
                      setGroupEdit(true);
                      setGroupActive(true);
                      setGroupEditModalShow(true);
                    }}
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                </span>
              </legend>

              <div
                className="avs-table"
                id="avsTable"
                style={
                  showstat === 1 ? { display: "block" } : { display: "block" }
                }
              >
                <div className="table-responsive">
                  <table className="table table-bordered text-light mb-0">
                    <thead className="table-dark">
                      <tr>
                        <th>Attribute Test Name</th>
                        <th>Value to Compare </th>
                        <th>Source Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody id="avsTableApp">
                      {g?.assetVerificationAttributes &&
                        g?.assetVerificationAttributes.map(
                          (attribute, attr) => {
                            return (
                              <tr key={attr}>
                                <td className="text-bold-500">
                                  {attribute.name}
                                </td>
                                <td>{attribute.value}</td>
                                <td>{attribute.verificationSource}</td>

                                <td>
                                  <div className="d-flex table-action">
                                    <a
                                      className="table-acton-icon"
                                      title="Edit Template"
                                      onClick={() =>
                                        editAVSAttribute(attribute)
                                      }
                                    >
                                      <i
                                        className="fa fa-edit text-info"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                    <a
                                      title="Delete "
                                      class="table-acton-icon ml-2"
                                      onClick={() =>
                                        delAVSAttribute(attribute.id)
                                      }
                                    >
                                      <i className="fas fa-trash text-info"></i>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </fieldset>
          ))} */}

        <AssetVerificationView
          groups={[...groups]}
          setSelectedGroup={setSelectedGroup}
          setGroupEdit={setGroupEdit}
          setGroupActive={setGroupActive}
          setGroupEditModalShow={setGroupEditModalShow}
          delAVSAttribute={delAVSAttribute}
          editAVSAttribute={editAVSAttribute}
          showstat={showstat}
          setGroups={setGroups}
          isGroupOrderFlag={isGroupOrderFlag}
          reorderAssetVerificationAttributes={
            reorderAssetVerificationAttributes
          }
          setAttributeOrderFlag={setAttributeOrderFlag}
          isAttributeOrderFlag={isAttributeOrderFlag}
          onAttributesFlagOpen={onAttributesFlagOpen}
        />
      </div>
      <Modal
        show={isGroupEditModalShow}
        onHide={handleCloseGroupModal}
        className="eng_file_upload_modal"
        backdrop="static"
      >
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: selectedGroup?.name,
                id: selectedGroup?.id,
              }}
              onSubmit={(values) => {
                setAssetVerificationAttributesGroup(values);
                // getTapeAsset(values.selectedDeal, values.selectedDate);
              }}
              validationSchema={assetVerificationGroupAddValidationSchema}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({
                handleChange,
                touched,
                values,
                handleSubmit,
                errors,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Form.Group className="mb-1" controlId="formBasicPassword">
                    <Form.Label>Group</Form.Label>
                    <Field
                      name="name"
                      value={values.name}
                      // type="text"
                      placeholder="Group name"
                      class="form-control"
                    ></Field>
                    {errors.name && touched.name ? (
                      <div class="text-danger">{errors.name}</div>
                    ) : null}
                  </Form.Group>

                  <Button
                    className="btn btn-defult"
                    variant="defult"
                    type="button"
                    onClick={handleCloseGroupModal}
                    title="Back"
                  >
                    <i class="fas fa-arrow-left"></i>
                  </Button>
                  <Button
                    className="btn btn-defult"
                    variant="defult"
                    type="submit"
                    title="Save"
                  >
                    <i class="fas fa-save"></i>
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>
								Close
							</Button>
							<Button variant="primary" onClick={handleClose}>
								Save Changes
							</Button>
						</Modal.Footer> */}
      </Modal>
      <Loader isLoading={isLoading} />
      {/* </div> */}
    </>
  );
}
export default AmssVerification;
