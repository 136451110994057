import { dateFormat } from "helpers/utils";
import React, { useState } from "react";
import TapList from "./TapList";
import TapListModal from "./TapListModal";

export default function List({
  data,
  docDownload,
  tableSort,
  getTapeAssetDetails,
  selectedType,
  tapePreviewHandler,
}) {
  const [isOpen, setOpen] = useState(false);
  const [tapeData, setTapData] = useState(null);
  const [tapeCopyData, setTapCopyData] = useState(null);
  // const onClickHandler = (e, id) => {
  //   const hiddenElement = e.currentTarget.parentNode.parentNode.nextSibling;

  //   hiddenElement.className.indexOf("collapse show") > -1
  //     ? hiddenElement.classList.remove("show")
  //     : hiddenElement.classList.add("show");
  // };
  // console.log("101 list>>", data);
  // const onClickHandler = (d, index) => {
  //   setTapData(d);
  //   setTapCopyData(d);
  //   setOpen(true);
  // };
  // const onCloseHandler = () => {
  //   setOpen(false);
  //   setTapData(null);
  //   setTapCopyData(null);
  // };
  return (
    <div className="card rounded-0 border-0 mb-3 bg-transparent">
      <div className="p-0 rp-0 rounded-0 border-0 bg-transparent card-body">
        <div className="table-responsive">
          <table className="table table-bordered text-light mb-0 shot">
            <thead className="table-dark">
              <tr>
                <th>
                  <a type="button" onClick={() => tableSort("dealName")}>
                    Deal Name{" "}
                  </a>
                </th>
                <th>
                  <a
                    type="button"
                    onClick={() => tableSort("tapeMonthEndDate")}
                  >
                    Month End Date{" "}
                  </a>
                </th>
                <th>
                  <a type="button" onClick={() => tableSort("createdDate")}>
                    Date Uploaded
                  </a>
                </th>
                <th>
                  <a type="button" onClick={() => tableSort("createdBy")}>
                    Uploaded By
                  </a>
                </th>
                <th>
                  <a
                    type="button"
                    onClick={() =>
                      tableSort(
                        selectedType === "asset"
                          ? "numberOfAssets"
                          : "numberOfRecords"
                      )
                    }
                  >
                    Record Count
                  </a>
                </th>

                <th
                  style={{
                    width: "50px",
                  }}
                  // colSpan="2"
                >
                  {/* Link */}
                </th>

                {/* <th
									style={{
										width: "50px",
									}}
								>
									Action
								</th> */}
              </tr>
            </thead>
            <tbody>
              {data.length > 0 &&
                data.map((d, i) => (
                  // <TapList index={i} data={d}>
                  <tr>
                    {selectedType === "asset" ? (
                      <td>
                        {d?.numberOfAssets > 0 ? (
                          <a
                            href="javascript:void(0);"
                            onClick={(e) => tapePreviewHandler(d, null)}
                          >
                            {d?.dealName}
                          </a>
                        ) : (
                          d?.dealName
                        )}
                      </td>
                    ) : (
                      <td>
                        {d?.numberOfRecords > 0 ? (
                          <a
                            href="javascript:void(0);"
                            onClick={(e) => tapePreviewHandler(d, null)}
                          >
                            {d?.dealName}
                            {d?.uploadType.trim().toLowerCase() == "asset"
                              ? " (A)"
                              : " (C)"}
                          </a>
                        ) : (
                          d?.dealName
                        )}
                      </td>
                    )}

                    <td>
                      {d?.tapeMonthEndDate &&
                        dateFormat(d?.tapeMonthEndDate, "MM/DD/YYYY")}
                    </td>
                    <td>
                      {d?.createdDate &&
                        dateFormat(d?.createdDate, "MM/DD/YYYY")}
                    </td>
                    <td>{d?.createdBy}</td>
                    <td>
                      {selectedType === "asset"
                        ? d?.numberOfAssets
                        : d?.numberOfRecords}
                    </td>

                    {/* <td>
                      {d?.numberOfAssets > 0 && (
                        <>
                          <a
                            href="javascript:void(0)"
                            onClick={(e) => onClickHandler(d, i)}
                          >
                            <i className="fa fa-eye"></i>
                          </a>
                        </>
                      )}
                    </td> */}
                    {selectedType === "asset" ? (
                      <td>
                        {d?.numberOfAssets > 0 && (
                          <>
                            <a
                              href="javascript:void(0);"
                              className=" m-auto  btn btn-outline-primary border-0 btn btn-secondary p-1"
                              onClick={() =>
                                docDownload(
                                  d?.fileID,
                                  d?.dealName +
                                    "_" +
                                    (d?.tapeMonthEndDate &&
                                      dateFormat(
                                        d?.tapeMonthEndDate,
                                        "MM/DD/YYYY"
                                      )),
                                  "asset"
                                )
                              }
                            >
                              <i
                                className="fas fa-download"
                                style={{
                                  fontSize: "15px",
                                }}
                              ></i>
                            </a>
                          </>
                        )}
                      </td>
                    ) : (
                      <td>
                        {d?.numberOfRecords > 0 && (
                          <>
                            <a
                              href="javascript:void(0);"
                              className=" m-auto  btn btn-outline-primary border-0 btn btn-secondary p-1"
                              onClick={() =>
                                docDownload(
                                  d?.fileID,
                                  d?.dealName +
                                    "_" +
                                    (d?.tapeMonthEndDate &&
                                      dateFormat(
                                        d?.tapeMonthEndDate,
                                        "MM/DD/YYYY"
                                      )),
                                  "asset"
                                )
                              }
                            >
                              <i
                                className="fas fa-download"
                                style={{
                                  fontSize: "15px",
                                }}
                              ></i>
                            </a>
                          </>
                        )}
                      </td>
                    )}
                  </tr>
                  // </TapList>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* <TapListModal
        showb={isOpen}
        handleCloseb={onCloseHandler}
        data={tapeData}
        copydata={tapeCopyData}
        setData={setTapData}
        selectedType={selectedType}
      /> */}
    </div>
  );
}
