import axios from "axios";
import { config } from "constant";

const baseUrl = config.url.API_URL;

const get = async (url) => {
  return await axios.get(baseUrl + url);
};
const post = async (url, data, config = null) => {
  return await axios.post(baseUrl + url, data, config);
};

export const Network = { get, post };
