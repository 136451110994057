import Papa from "papaparse";

const parse = (file) => {
	return new Promise((resolve, reject) => {
		Papa.parse(file, {
			header: true,
			skipEmptyLines: true,
			complete: function (results) {
				console.log("result>>", results);
				resolve(results.data);
			},
		});
	});
};
const downloadCSV = (array) => {
	var csv = Papa.unparse(array);

	var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
	var csvURL = null;
	if (navigator.msSaveBlob) {
		csvURL = navigator.msSaveBlob(csvData, "errors.csv");
	} else {
		csvURL = window.URL.createObjectURL(csvData);
	}

	var tempLink = document.createElement("a");
	tempLink.href = csvURL;
	tempLink.setAttribute("download", "errors.csv");
	tempLink.click();
};
const downloadCSVFile = (text, name) => {
	const element = document.createElement("a");
	const file = new Blob([text], {
		type: "text/plain",
	});
	element.href = URL.createObjectURL(file);
	element.download = `${name}.csv`;
	document.body.appendChild(element);
	element.click();
};

export const CSVParser = { parse, downloadCSV, downloadCSVFile };
