import { isRestrict } from "helpers/utils";
import { isLogin } from "helpers/utils";
import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) => {
        return isLogin() ? (
          isRestrict() ? (
            <Redirect to="/error" />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};

export default PrivateRoute;
