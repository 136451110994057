import MyTooltrip from "components/MyTooltrip/MyTooltrip";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Table } from "react-bootstrap";
import { camelToTitle, strlimit, titleToCamel } from "./../../helpers/data";
import "./PortfolioHistory.scss";
import LoaderProgressBar from "components/LoaderProgressBar/LoaderProgressBar";
export default function FieldMappingModal({
  showb,
  handleCloseb,
  fileParseHeaderData,
  mappingFieldData,
  mappingData,
  submitTape,
  success,
  error,
  isDetailsError,
  handleShowc,
  setMappingData,
  machingData,
  setMachingData,
  uploadPercent,
}) {
  const target = useRef(null);
  const [copyMappingData, setCopyMappingData] = useState([]);
  const [copyMachingData, setCopyMachingData] = useState([]);
  let copyMachingData1 = machingData ? [...machingData] : [],
    copyMappingData1 = mappingData ? [...mappingData] : [];
  // useEffect(() => {
  // 	initialData();
  // }, []);
  const [errorField, setErrorField] = useState(null);
  // const [tempMapping, setTempMapping] = useState(mappingData);

  // const tempData = [...mappingData];
  // const updateMappingData = (h, m, index) => {
  //   // let tempData = [...tempMapping];
  //   if (titleToCamel(h) == m.fieldName) {
  //     tempData[index].mappingField = h;
  //     // setTempMapping(() => tempData);
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  console.log("101 map>>", {
    mappingData,
    fileParseHeaderData,
    machingData,
    isDetailsError,
  });
  // useEffect(() => {
  //   machingData && setCopyMachingData([...machingData]);
  //   mappingData && setCopyMappingData([...mappingData]);

  // }, [mappingData, machingData]);
  const handleChange = (e, index) => {
    // const tempMappingData = [...mappingData];
    // const tempMachingData = [...machingData];
    const tempMappingData = [...mappingData];
    const tempMachingData = [...machingData];
    const selectedValue = e.target.value;
    // const oldSelectedValue = tempMappingData[index].mappingField;
    // if (oldSelectedValue) {
    //   const index = tempMachingData.indexOf(titleToCamel(oldSelectedValue));
    //   if (index > -1) {
    //     tempMachingData.splice(index, 1);
    //   }
    // }
    // if (selectedValue !== "") {
    //   tempMachingData.push(titleToCamel(selectedValue));
    // }
    // tempMappingData[index].mappingField = selectedValue;

    const oldSelectedValue = tempMappingData[index].fieldDisplayName;
    // console.log(
    //   "102 map>>",
    //   mappingData,
    //   machingData,
    //   selectedValue,
    //   oldSelectedValue
    // );
    if (oldSelectedValue) {
      const index = tempMachingData.indexOf(oldSelectedValue);
      if (index > -1) {
        tempMachingData.splice(index, 1);
      }
    }
    if (selectedValue !== "") {
      tempMachingData.push(selectedValue);
    }
    tempMappingData[index].mappingField = selectedValue;
    // setMappingData(tempMappingData);
    // setMachingData(tempMachingData);
    // setCopyMappingData(tempMappingData);
    // setCopyMachingData(tempMachingData);
    copyMachingData1 = [...tempMachingData];
    copyMappingData1 = [...tempMappingData];
  };

  return (
    <Modal
      show={showb}
      onHide={handleCloseb}
      size=""
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="eng_file_upload_modal full-md "
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title
          style={{
            margin: "0",
            width: "100%",
          }}
        >
          <div>Internal asset details fields table </div>
          {success && (
            <div
              class="alert alert-success"
              style={{
                padding: "5px",
                fontSize: 10,
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              {success}
            </div>
          )}
          {error && (
            <div
              class="alert alert-danger"
              style={{
                padding: "5px",
                fontSize: 12,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
              }}
            >
              {error}
              {isDetailsError && isDetailsError == true && (
                <a
                  href="javascript:void(0);"
                  onClick={handleShowc}
                  style={{
                    position: "absolute",
                    right: 10,
                    backgroundColor: "#ccc",
                    padding: "0px 8px",
                    color: "#333",
                    borderRadius: 5,
                  }}
                >
                  View Details
                </a>
              )}
            </div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className=""
          style={{
            border: "1px solid #fff",
            maxHeight: "450px",
            overflow: "auto",
          }}
        >
          <Table className="table table-bordered text-light mb-0" responsive>
            <thead className="table-dark">
              <tr>
                <th>System mapping </th>
                <th>Uploaded csv </th>
                <th>Description </th>
              </tr>
            </thead>
            <tbody>
              {
                // mappingData &&
                //   Object.keys(mappingData)
                // .sort((a, b) => a.localeCompare(b))
                mappingData &&
                  mappingData.length > 0 &&
                  mappingData.map((m, index) => (
                    <tr key={`map_${Math.random()}`}>
                      {/* <td>{camelToTitle(m)} </td> */}
                      <td width={"25%"}>
                        {m.fieldDisplayName}
                        {m?.required && <span class="error">*</span>}
                      </td>
                      <td width={"25%"}>
                        <select
                          name={`mappingSelect[${m.fieldName}]`}
                          defaultValue={m?.mappingField}
                          class="form-select bg-transparent text-light"
                          onChange={(e) => handleChange(e, index)}
                        >
                          {/* <option value="">Select</option> */}
                          <option value="">DO NOT MAP</option>
                          {fileParseHeaderData &&
                            Object.keys(fileParseHeaderData)
                              .sort((a, b) => a.localeCompare(b))
                              .map((h) => (
                                <option
                                  value={h}
                                  selected={
                                    h.toLowerCase().trim() ==
                                    m.fieldDisplayName.toLowerCase().trim()
                                  }
                                  // selected={titleToCamel(h) == m.fieldName}
                                  disabled={machingData.includes(
                                    // titleToCamel(h)
                                    h.trim()
                                  )}
                                >
                                  {h}
                                </option>
                              ))}
                        </select>{" "}
                      </td>
                      <td width={"50%"} className={"text-start"}>
                        <MyTooltrip msg={m.description} />
                        {/* <div
                          onMouseEnter={(e) => (
                            <MyTooltrip show={true} target={e.target} />
                          )}
                          onMouseLeave={(e) => (
                            <MyTooltrip show={false} target={e.target} />
                          )}
                        >
                          {strlimit(m.description, 30)}
                        </div> */}
                        {/* <div>{m.description}</div> */}
                      </td>
                    </tr>
                  ))
              }
            </tbody>
          </Table>
        </div>
        {parseInt(uploadPercent) > 0 && (
          <LoaderProgressBar now={uploadPercent} />
        )}
        <div>
          <Button
            style={{
              fontSize: "16px",
              marginRight: "8px",
            }}
            className="btn btn-success"
            variant="success"
            type="button"
            onClick={handleCloseb}
          >
            <i class="fas fa-close"></i> Cancel
          </Button>
          <Button
            style={{
              fontSize: "16px",
              marginRight: "8px",
            }}
            className="btn btn-success"
            variant="success"
            type="button"
            onClick={async (e) => {
              // await setMachingData([...copyMachingData]);
              // await setMappingData([...copyMappingData]);
              await setMachingData([...copyMachingData1]);
              await setMappingData([...copyMappingData1]);
              submitTape(e);
            }}
          >
            <i class="fas fa-arrow-right"></i> Next
            {/* <i class="fas fa-check"></i> Submit */}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
