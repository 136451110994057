export const UPDATE_CLIENT_ACTION = "UPDATE_CLIENT_ACTION";
export const STORE_CLIENT_ACTION = "STORE_CLIENT_ACTION";

export const updateClient = (clientId) => ({
	type: UPDATE_CLIENT_ACTION,
	payload: { clientId },
});

export const storeClients = (clients) => ({
	type: STORE_CLIENT_ACTION,
	payload: { clients },
});
