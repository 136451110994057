import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";

export default function Toaster(props) {
	const [showA, setShowA] = useState(props.show);
	const toggleShowA = () => setShowA(!showA);
	useEffect(() => {
		if (showA) {
			setTimeout(() => {
				toggleShowA();
			}, 3000);
		}
	}, [showA]);
	return (
		<Toast
			show={showA}
			onClose={toggleShowA}
			style={{ position: "absolute", top: 10, right: 10 }}
			autohide={true}
			bg={props.type}
		>
			<Toast.Body style={{ color: "#ffffff" }}>{props.message}</Toast.Body>
		</Toast>
	);
}
