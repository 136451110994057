import React, { useState, useEffect } from "react";
import { config } from "../constant";
import axios from "axios";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Example from "./example";
import { Container } from "./container";
import AmssPeriod from "./AmssPeriod";
import AmssCovenant from "./Convenant/AmssCovenant";
import AmssVerification from "./AssetVerification/AmssVerification";

// reactstrap components
import // Input,
"reactstrap";
import Loader from "./Loader";
// core components
const getEngagementUrl = config.url.API_URL + "engagement";
const postEngagementUrl = config.url.API_URL + "engagement";

function AamsHeader(props) {
  //find engagements in a deal
  const [engagements, setEngagements] = useState([]);
  const [selectedEng, setSelectedEng] = useState(0);
  const [toggle, setToggle] = useState(1);
  const [text, setText] = useState("New Engagement");
  const [textCopy, setTextCopy] = useState("New Engagement");
  const [text1, setText1] = useState("");
  const [toggle1, setToggle1] = useState("");
  const [errorText, setErrorText] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);
  const [isEngEdit, setEngEdit] = useState(false);
  const [board, setBoard] = useState([]);
  const [boardErr, setBoardErr] = useState("");
  const [editEng, setEditEng] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const getEngagementsWithAxios = async () => {
    // setLoading(true);
    const resp = await axios.get(getEngagementUrl + "?DealID=" + props.deal);
    // console.log(resp.data.responseData);
    const engagements = resp.data.responseData;
    setEngagements(engagements);
    setSelectedEng(engagements[selectedIndex]?.id);
    // setLoading(false);
  };
  //*****************************************end find engagement********************************************
  //*****************************************Add Engagement************************************************
  const [engagementContent, setEngagementContent] = useState(0);
  const editEngagement = async (eng) => {
    setEngagementContent(1);
    setEngEdit(true);
    // const resp = await axios.get(getEngagementUrl + "/" + id);
    // // console.log(resp.data.responseData);
    // const engagements = resp.data.responseData;
    setEditEng((p) => eng);
    let temp = [];
    if (eng.isWaterfallGenerationEnabled)
      temp.push({
        key: "isWaterfallGenerationEnabled",
        value: "Waterfall Generation",
      });
    if (eng.isCovenantTrackingEnabled)
      temp.push({
        key: "isCovenantTrackingEnabled",
        value: "Covenant Tracking",
      });
    if (eng.isReportGenerationEnabled)
      temp.push({
        key: "isReportGenerationEnabled",
        value: "Report Generation",
      });
    if (eng.isVerificationServicesEnabled)
      temp.push({
        key: "isVerificationServicesEnabled",
        value: "Verificaion Services",
      });
    if (eng.isCashAdministrationEnabled)
      temp.push({
        key: "isCashAdministrationEnabled",
        value: "Cash Administration",
      });

    setBoard((p) => [...temp]);
  };
  const updateEngagementDetails = async () => {
    // console.log("update>>",editEng); return;
    let data = { ...editEng };
    for (let i = 0; i < board.length; i++) {
      data[board[i].key] = true;
    }
    const headers = {
      "content-type": "application/json",
    };
    axios
      .put(postEngagementUrl, data, { headers })
      .then((engresp) => {
        if (engresp.data.status) {
          setEngagementContent(0);
          setEngEdit(false);
          // alert("Successfully created an engagement")
          getEngagementsWithAxios();
        }
      })
      .catch((error) => {
        // error response
      });
  };
  const addEngagement = (e) => {
    e.preventDefault();
    setBoard([]);
    setText("New Engagement");
    const tempSelectIndex = parseInt(engagements.length);
    // setTimeout(()=>{
    setSelectedEng((p) => engagements.length);
    // },1000)
    setEngagementContent(1);

    // console.log("addEngagement",engagements.length,selectedEng,tempSelectIndex);
  };

  const deleteNewEngagement = () => {
    //engagementContent = 0;
    setEngagementContent(0);
  };

  const saveEngagement = () => {
    if (board.length <= 0)
      return setBoardErr("Atleast One Engagement required");

    if (text == "New Engagement")
      return setBoardErr("Please rename the engagement name first");
    if (text == " ")
      return setBoardErr("Please enter the engagement name first");

    setErrorText(false);
    const dataArr = {
      dealID: props.deal,
      name: text,
      isWaterfallGenerationEnabled: false,
      isCovenantTrackingEnabled: false,
      isReportGenerationEnabled: false,
      isVerificationServicesEnabled: false,
      isCashAdministrationEnabled: false,
    };
    for (let i = 0; i < board.length; i++) {
      dataArr[board[i].key] = true;
    }
    setLoading(true);

    const headers = {
      "content-type": "application/json",
    };
    axios
      .post(postEngagementUrl, dataArr, { headers })
      .then((engresp) => {
        // console.log(engresp);
        setLoading(false);
        setEngagementContent(0);
        if (engresp.data.status) {
          // setSelectedIndex((p) => p + 1);
          // alert("Successfully created an engagement")
          setErrorResponse(null);
          getEngagementsWithAxios();
        }
      })
      .catch((error) => {
        setLoading(false);
        // error response
        console.log("err>>", error.response);
        setErrorResponse(error.response.data.Message);
      });
  };
  function toggleInput() {
    setTextCopy(text);
    let tog = toggle === 1 ? 0 : 1;
    setToggle(tog);
    tog === 0 && setText("");
    tog === 1 && text === "" && setText(textCopy);
  }
  function handleDblChange(event) {
    setText(event.target.value);
  }
  async function deleteEngagement(event) {
    // console.log(event.target.id);
    // alert("Successfully Deleted")
    if (window.confirm("Are you sure you wish to delete the Engagement?")) {
      setLoading(true);
      const headers = {
        "content-type": "application/json",
      };
      await axios.delete(getEngagementUrl + "/" + event.target.id, { headers });
      setLoading(false);
      getEngagementsWithAxios();
    }
  }

  function toggleInput1(id) {
    setToggle1(id);
  }

  function handleDblChange2(event) {
    setText1(event.target.value);
  }

  const updateEngagement = async (id) => {
    //console.log(periodRows);
    if (text1 == "") {
      return setErrorText("Please enter the engagement name first");
    }
    setErrorText(false);
    const resp = await axios.get(getEngagementUrl + "/" + id);
    // console.log(resp.data.responseData);
    const engagements = resp.data.responseData;
    const dataArr = {
      id: id,
      name: text1,
      isWaterfallGenerationEnabled: engagements.isWaterfallGenerationEnabled,
      isCovenantTrackingEnabled: engagements.isCovenantTrackingEnabled,
      isReportGenerationEnabled: engagements.isReportGenerationEnabled,
      isVerificationServicesEnabled: engagements.isVerificationServicesEnabled,
      isCashAdministrationEnabled: engagements.isCashAdministrationEnabled,
    };
    const headers = {
      "content-type": "application/json",
    };
    setLoading(true);
    axios
      .put(postEngagementUrl, dataArr, { headers })
      .then((engresp) => {
        setLoading(false);
        if (engresp.data.status) {
          setToggle1("");
          setText1("");
          getEngagementsWithAxios();
          setErrorText(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // error response
      });
  };

  const updateEngagementCancel = () => {
    setToggle1("");
    setText1("");
    getEngagementsWithAxios();
    setErrorText(false);
  };
  //******************************************end add engagement************************************************
  //******************************************start period section**********************************************
  let [periodRows, setPeriodRows] = useState("");
  const [periodCnt, setPeriodCnt] = useState(1);

  // Receive data from TableRow
  const handleChange = (data) => {
    periodRows[data.index] = data;
  };

  const saveNewPeriod = (e) => {
    console.log("saveperiod:" + e);
    /*const dataArr = { dealID: props.deal, name:'Engagement1', isWaterfallGenerationEnabled:true, isCovenantTrackingEnabled:true, isReportGenerationEnabled:false, isVerificationServicesEnabled:true, isCashAdministrationEnabled:false };
		const headers = { 
			'content-type': 'application/json'
		};
		axios.post(postEngagementUrl, dataArr, {headers})
		.then((engresp) => {
			console.log(engresp);
			engagementContent = 0;
			setEngagementContent(engagementContent);
			if(engresp.data.status){
				
			}
			
		})
		.catch((error) => {
		// error response
		});*/
  };

  // Add New Table Row
  const addNewPeriod = (engid) => {
    setPeriodCnt(periodCnt + 1);
    periodRows +=
      "<tr><td>" +
      periodCnt +
      '</td><td><input type="text" name="startDate' +
      periodCnt +
      '" className="form-control bg-transparent text-light" readonly="true"  placeholder="08/15/22"/></td><td><input type="text" name="endDate' +
      periodCnt +
      '" className="form-control bg-transparent text-light" readonly="true"  placeholder="08/15/22"/></td><td><input type="text" name="deliveryDate' +
      periodCnt +
      '" className="form-control bg-transparent text-light" readonly="true"  placeholder="08/15/22"/></td><td><button onClick={saveNewPeriod} className="btn btn-primary">Open Period</button></td></tr>';
    setPeriodRows(periodRows);
    setPeriodCnt(periodCnt + 1);
  };

  function handleChange2(newboard) {
    console.log("handleChange2>>>>", newboard);
    // setBoard((p)=>[...newboard])
  }

  //****************************************period section end*****************************************
  useEffect(() => {
    //console.log(props.deal);
    if (props.deal) {
      getEngagementsWithAxios();
    }
  }, [props]);

  return (
    <>
      <ul className="nav nav-tabs tab-one m-0 p-0" id="myTab" role="tablist">
        {engagements.map((eng, i) => (
          <li className="nav-item" role="presentation" key={i}>
            {toggle1 !== "" && toggle1 === eng.id ? (
              <>
                {/* <input
                  type="text"
                  value={text1}
                  class="form-control"
                  onChange={handleDblChange2}
                  onBlur={() => {
                    // setErrorText(false);
                    updateEngagement(eng.id);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      // setErrorText(false);
                      updateEngagement(eng.id);
                    }
                  }}
                />
                <button onClick={() => updateEngagementCancel()}>
                  <i class="fas fa-times"></i>
                </button> */}
                <div class="input-group mb-3">
                  <input
                    type="text"
                    value={text1}
                    class="form-control bg-transparent text-light "
                    onChange={handleDblChange2}
                    style={{ height: 53 }}
                    onBlur={() => {
                      // setErrorText(false);
                      updateEngagement(eng.id);
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        // setErrorText(false);
                        updateEngagement(eng.id);
                      }
                    }}
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text me-2 editBtn"
                      id="basic-addon2"
                      onClick={() => updateEngagementCancel()}
                    >
                      <i class="fas fa-times"></i>
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <button
                className={`nav-link ${selectedEng == eng.id ? "active" : ""}`}
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target={"#tab-" + eng.id}
                type="button"
                role="tab"
                aria-controls={eng.id}
                aria-selected="false"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedEng(eng.id);
                  console.log("select>>", i, eng.id);
                }}
              >
                <span onDoubleClick={() => toggleInput1(eng.id)}>
                  {eng.name}
                </span>
                <span className="font-btn-hover">
                  <i
                    className="fa fa-edit m-2"
                    aria-hidden="true"
                    id={eng.id}
                    onClick={() => editEngagement(eng)}
                  ></i>
                  {!isEngEdit && (
                    <i
                      className="fa fa-trash"
                      aria-hidden="true"
                      id={eng.id}
                      onClick={deleteEngagement}
                    ></i>
                  )}
                </span>
              </button>
            )}
          </li>
        ))}
        {engagementContent === 1 && !isEngEdit ? (
          <li className="nav-item active" role="presentation">
            {toggle === 1 ? (
              <button
                className="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <span onDoubleClick={toggleInput}>{text}</span>
                <i
                  className="fa fa-trash"
                  aria-hidden="true"
                  onClick={deleteNewEngagement}
                ></i>
              </button>
            ) : (
              <>
                {/* <input
                  type="text"
                  value={text}
                  class="form-control"
                  onChange={handleDblChange}
                  onBlur={toggleInput}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      toggleInput();
                    }
                  }}
                />
                <button onClick={() => toggleInput()}>
                  <i class="fas fa-times"></i>
                </button> */}
                <div class="input-group mb-3">
                  <input
                    type="text"
                    value={text}
                    class="form-control bg-transparent text-light "
                    onChange={handleDblChange}
                    onBlur={toggleInput}
                    style={{ height: 40 }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        toggleInput();
                      }
                    }}
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text me-2 editBtn"
                      id="basic-addon2"
                      onClick={() => toggleInput()}
                    >
                      <i class="fas fa-times"></i>
                    </span>
                  </div>
                </div>
              </>
            )}
          </li>
        ) : (
          ""
        )}
        <li className="nav-item squre_tab ml-auto" role="presentation">
          {!isEngEdit && (
            <button
              className="nav-link"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Add Engagement"
              type="button"
              onClick={addEngagement}
            >
              <i className="fa fa-plus-square" aria-hidden="true"></i>
            </button>
          )}
        </li>
      </ul>
      <div className="tab-content new-conent" id="myTabContent">
        {!isEngEdit &&
          engagements.map((engagement, j) => (
            <div
              className={`tab-pane fade  ${
                selectedEng == engagement.id
                  ? engagementContent === 0
                    ? "show active"
                    : ""
                  : ""
              }`}
              id={"tab-" + engagement.id}
              role="tabpanel"
              aria-labelledby="profile-tab"
              key={j}
            >
              <div className="tab-content p-2">
                {errorText && (
                  <div class="alert alert-danger">
                    {" "}
                    Please rename the engagement name first
                  </div>
                )}
                {errorResponse && (
                  <div class="alert alert-danger"> {errorResponse}</div>
                )}
                <AmssPeriod
                  engagement={engagement.id}
                  periods={engagement.periods}
                />

                <div id="child_accordion">
                  <div
                    className="accordion accordion-flush"
                    id="childaccordionFlush"
                  >
                    {/* <!--   Waterfall Generation	--> */}
                    {engagement.isWaterfallGenerationEnabled ? (
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingwaterfallGeneration"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-waterfallGeneration"
                            aria-expanded="false"
                            aria-controls="flush-waterfallGeneration"
                          >
                            Waterfall Generation
                          </button>
                        </h2>
                        <div
                          id="flush-waterfallGeneration"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingwaterfallGeneration"
                          data-bs-parent="#childaccordionFlush"
                        >
                          <div className="accordion-body text-light">
                            {" "}
                            Waterfall Generation
                            <br />
                            <br />
                            Placeholder content for this accordion, which is
                            intended to demonstrate the{" "}
                            <code>.accordion-flush</code> className. This is the
                            first item's accordion body.
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <!--   Report Generation	--> */}
                    {engagement.isReportGenerationEnabled ? (
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingreportGeneration"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-reportGeneration"
                            aria-expanded="false"
                            aria-controls="flush-reportGeneration"
                          >
                            Report Generation
                          </button>
                        </h2>
                        <div
                          id="flush-reportGeneration"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingreportGeneration"
                          data-bs-parent="#childaccordionFlush"
                        >
                          <div className="accordion-body">
                            {" "}
                            Report Generation
                            <br />
                            <br />
                            Placeholder content for this accordion, which is
                            intended to demonstrate the{" "}
                            <code>.accordion-flush</code> className. This is the
                            first item's accordion body.
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <!--   Covenant Tracking	--> */}
                    {engagement.isCovenantTrackingEnabled ? (
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingcovenanttracking"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-covenanttracking"
                            aria-expanded="false"
                            aria-controls="flush-covenanttracking"
                          >
                            Covenant Tracking
                          </button>
                        </h2>
                        <div
                          id="flush-covenanttracking"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingcovenanttracking"
                          data-bs-parent="#childaccordionFlush"
                        >
                          <div className="accordion-body">
                            <AmssCovenant engagement={engagement.id} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <!--   Verification Services	--> */}
                    {engagement.isVerificationServicesEnabled ? (
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingverificaionServices"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-verificaionServices"
                            aria-expanded="false"
                            aria-controls="flush-verificaionServices"
                          >
                            Asset Verification
                          </button>
                        </h2>
                        <div
                          id="flush-verificaionServices"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingverificaionServices"
                          data-bs-parent="#childaccordionFlush"
                        >
                          <div className="accordion-body">
                            <AmssVerification
                              engagement={engagement.id}
                              getEngagementsWithAxios={getEngagementsWithAxios}
                              assetverifcation={
                                engagement.assetVerifications.length > 0
                                  ? engagement.assetVerifications[0].id
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <!--   Cash  administration	--> */}
                    {engagement.isCashAdministrationEnabled ? (
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingcashAdministration"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-cashAdministration"
                            aria-expanded="false"
                            aria-controls="flush-cashAdministration"
                          >
                            Cash Administration
                          </button>
                        </h2>
                        <div
                          id="flush-cashAdministration"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingcashAdministration"
                          data-bs-parent="#childaccordionFlush"
                        >
                          <div className="accordion-body">
                            {" "}
                            Cash Administration
                            <br />
                            <br />
                            Placeholder content for this accordion, which is
                            intended to demonstrate the{" "}
                            <code>.accordion-flush</code> className. This is the
                            first item's accordion body.
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        {/*  */}
        {!isEngEdit ? (
          <div
            className={
              engagementContent === 1
                ? "tab-pane fade show active"
                : "tab-pane fade"
            }
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="tab-content p-2">
              {boardErr && <div class="alert alert-danger"> {boardErr}</div>}
              {errorResponse && (
                <div class="alert alert-danger"> {errorResponse}</div>
              )}

              <DndProvider backend={HTML5Backend}>
                <Container
                  onChange={handleChange2}
                  board={board}
                  setBoard={setBoard}
                />
              </DndProvider>
              <div className="save-engage-btn mt-2 mb-2 text-center">
                <button className="btn btn-primary" onClick={saveEngagement}>
                  Save Engagement
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              engagementContent === 1
                ? "tab-pane fade show active"
                : "tab-pane fade"
            }
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="tab-content p-2">
              {errorText && (
                <div class="alert alert-danger">
                  {" "}
                  Please rename the engagement name first
                </div>
              )}
              {errorResponse && (
                <div class="alert alert-danger"> {errorResponse}</div>
              )}

              <DndProvider backend={HTML5Backend}>
                <Container
                  onChange={handleChange2}
                  board={board}
                  setBoard={setBoard}
                />
              </DndProvider>
              <div className="save-engage-btn mt-2 mb-2 text-center">
                <button
                  className="btn btn-primary"
                  onClick={updateEngagementDetails}
                >
                  Save Engagement
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Loader isLoading={isLoading} />
    </>
  );
}

export default AamsHeader;
