import React from "react";

// reactstrap components
import {

} from "reactstrap";

// core components
import SideBar from "components/SideBar.js";
import AamsHeader from "components/AamsHeader.js";
// import AamsFooter from "components/AamsFooter.js";

function Index() {

  return (
    <>

      <SideBar />
      <div id="main_wraper">
        <AamsHeader />

        {/*<AamsFooter />*/}

      </div>


    </>
  );
}

export default Index;
