import React, { useState, useEffect, useContext } from "react";
import { config } from "./../../constant";
import axios from "axios";
import { Formik, Field } from "formik";
import queryString from "query-string";

// reactstrap components
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  UncontrolledCollapse,
} from "reactstrap";
import * as yup from "yup";
import "./DealEngagement.scss";

// core components
import SideBar from "components/SideBar.js";
import AamsHeader from "components/AamsHeader.js";
import AamsFooter from "components/AamsFooter.js";
import AamssEngagementSetup from "components/aamsEngagement_Setup.js";
import moment from "moment";
import NumberFormat from "react-number-format";
import { camelToTitle } from "helpers/data";
import Loader from "components/Loader";
import CurrencyInput from "react-currency-input-field";
import { axiosAuthHeaderSet } from "helpers/utils";
import { useSelector } from "react-redux";
import Toaster from "components/Toaster";
import { AppContext } from "App";
import DealParameters from "./DealParameters";
import DealInfoEditForm from "./DealInfoEditForm";
import MyBreadcrumb from "components/MyBreadcrumb/MyBreadcrumb";

const getDealDetailsUrl = config?.url?.API_URL + "deal/";
const getPaymentPeriodUrl = config?.url?.API_URL + "payment-period";
const getDealFlagUrl = config?.url?.API_URL + "deal-flag?DealID=";
const postDealFlagUrl = config?.url?.API_URL + "deal-flag";
const getDealUrl = config.url.API_URL + "deal";

function DealEngagement(props) {
  const [dealDetails, setDealDetails] = useState([]);
  const [isEditDealInfo, setEditDealInfoStatus] = useState(false);
  const [dealTypes, setDealTypes] = useState([]);
  const [dealRoles, setDealRoles] = useState([]);
  const [dealId, setDealId] = useState(null);
  const [dealPartyMembers, setPartyMember] = useState([]);
  const [isDealParameterEdit, setDealParameterEdit] = useState(false);
  const [paymentPeriods, setPaymentPeriods] = useState([]);
  const [dealFlags, setDealFlags] = useState([]);
  const [dealFlagToggle, setDealFlagToggle] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const homeData = useSelector((state) => state.home);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState("info");
  const appContext = useContext(AppContext);
  const [checked, setChecked] = useState(false);
  const [params, setParams] = useState(null);
  const today = new Date();
  const breadcrumbs = [
    {
      op: "Home",
      link: "/",
    },
    {
      op: "Deals",
      link: "/deal-Listing",
    },
    {
      op: "Deal-Engagement",
      link: "",
    },
  ];
  today.setHours(0, 0, 0, 0);

  const dealParameterFormValidationSchema = yup.object().shape({
    id: yup.string(),
    initialPaymentDate: yup
      // .string()
      .date()
      .required("Initial Payment Date  is a required field"),
    determinationDateDescription: yup.string().nullable(),
    paymentDateDescription: yup.string().nullable(),
    paymentPeriodID: yup
      .string()
      .required("Payment period is a required field"),
  });
  const dealPartyMemberAddFormValidationSchema = yup.object().shape({
    name: yup.string().required("Party is a required field"),
    roleID: yup.string().required("Role ID is a required field"),
    dealID: yup.string(),
    primaryContact: yup
      .string()
      .required("Primary Contact is a required field"),
  });
  const getDealFlagWithAxios = async () => {
    try {
      const response = await axios.get(getDealFlagUrl + params?.id);
      const dealDetails = response.data.responseData;
      dealDetails.map((element) => {
        if (element.isSet === true) {
          // if (element.flagCode == "DEFAULTED_FINANCIAL") setChecked(true);
          // else setChecked(false);
          // console.log("element.flagCode>>", element.flagCode, checked);
          setDealFlagToggle(element);
        }
      });
      setDealFlags(dealDetails);
    } catch (error) {
      // setAlertType("danger");
      // console.log("error>>", error);
      // setShowAlert(true);
      // setAlertMessage(error.response?.data?.Message);
      if (error.response.status == 401) {
        appContext.handleLogout();
      }
    }
  };
  const getDealDetailsWithAxios = async () => {
    setLoading(true);
    try {
      const response = await axios.get(getDealDetailsUrl + params?.id);
      // console.log("102 deal>>>>", response);
      const dealDetails = response.data.responseData;
      setDealDetails(dealDetails);
      setLoading(false);

      ////console.log(posts);
    } catch (error) {
      // setAlertType("danger");
      //   setLoading(false);
      console.log("error>>", error);
      // setShowAlert(true);
      // setAlertMessage(error.response?.data?.Message);
    }
  };
  const getPaymentPeriods = async () => {
    try {
      //   setLoading(true);
      const res = await axios.get(getPaymentPeriodUrl);
      ////console.log(res.data.responseData);
      const paymentPeriods = res.data.responseData;
      setPaymentPeriods(paymentPeriods);
      //   setLoading(false);
    } catch (error) {
      //   setLoading(false);
      // setAlertType("danger");
      // console.log("error>>", error);
      // setShowAlert(true);
      // setAlertMessage(error.response?.data?.Message);
    }
  };
  const setDealDetailsWithAxios = async (values) => {
    setLoading(true);
    try {
      const response = await axios.put(config?.url?.API_URL + "deal", {
        ...values,
        isActive: true,
      });
      // console.log("101 response", response);
      const dealDetails = response.data.responseData;

      getDealDetailsWithAxios();
      setEditDealInfoStatus(false);
      setDealParameterEdit(false);
      setLoading(false);

      ////console.log(posts);
    } catch (error) {
      setLoading(false);
      // setAlertType("danger");
      // console.log("error>>", error);
      // setShowAlert(true);
      // setAlertMessage(error.response?.data?.Message);

      //console.log("error update>>>", err);
    }
  };
  const getDealTypes = async () => {
    try {
      //   setLoading(true);

      const response = await axios.get(config?.url?.API_URL + "deal-type");
      //console.log("dealTypes:", response);
      const data = response.data.responseData;
      setDealTypes([...data]);
      //   setLoading(false);

      ////console.log(posts);
    } catch (error) {
      //   setLoading(false);
      // setAlertType("danger");
      // console.log("error>>", error);
      // setShowAlert(true);
      // setAlertMessage(error.response?.data?.Message);
    }
  };
  const getDealRoles = async () => {
    try {
      const response = await axios.get(config?.url?.API_URL + "deal-role");
      //console.log("dealTypes:", response);
      const data = response.data.responseData;
      setDealRoles([...data]);
      ////console.log(posts);
    } catch (error) {
      // setAlertType("danger");
      // console.log("error>>", error);
      // setShowAlert(true);
      // setAlertMessage(error.response?.data?.Message);
    }
  };
  const getDealPartyMember = async () => {
    try {
      const response = await axios.get(
        config?.url?.API_URL + "deal-party-member?DealID=" + params?.id
      );
      //console.log("getDealPartyMember:", response);
      const data = response.data.responseData;
      setPartyMember([...data]);
    } catch (error) {
      // setAlertType("danger");
      // console.log("error>>", error);
      // setShowAlert(true);
      // setAlertMessage(error.response?.data?.Message);
    }
  };
  const setDealPartyMember = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post(
        config?.url?.API_URL + "deal-party-member",
        values
      );
      setLoading(false);
      ////console.log(response);
      const dealDetails = response.data.responseData;
      // alert("Deal party member has been updated successfully");
      getDealPartyMember();
      ////console.log(posts);
    } catch (error) {
      setLoading(false);
      // setAlertType("danger");
      // console.log("error>>", error);
      // setShowAlert(true);
      // setAlertMessage(error.response?.data?.Message);
      //console.log("error update>>>", err);
    }
  };
  const delDealPartyMember = async (id) => {
    try {
      if (
        window.confirm("Are you sure you wish to delete the deal party member?")
      ) {
        setLoading(true);
        const response = await axios.delete(
          config?.url?.API_URL + "deal-party-member/" + id
        );
        setLoading(false);
        // alert("Party member has been deleted successfully");
        getDealPartyMember();
      }
    } catch (err) {
      setLoading(false);
      alert("Server error. Please try after sometime.");
    }
  };

  const handleFlagChange = async (e) => {
    // const flags = e.target.value.split(",");
    setLoading(true);
    let values2 = {};
    // const selectedFlag =
    // 	dealFlags &&
    // 	dealFlags.filter((df) => {
    // 		return df.flagCode == "DEFAULTED_FINANCIAL";
    // 		// if (checked) return df.flagCode == "DEFAULTED_FINANCIAL";
    // 		// else return df.flagCode == "DEFAULTED_OTHER";
    // 	});
    // if (checked) {
    // }
    // const values = {
    // 	dealID: params?.id,
    // 	flagID: selectedFlag[0]?.flagID,
    // 	isSet: checked,
    // };
    const flagsPromise = dealFlags.map((item, index) => {
      if (e)
        if (item.flagID == e)
          return axios.post(postDealFlagUrl, {
            dealID: params?.id,
            flagID: item.flagID,
            isSet: true,
          });
        else {
          return axios.post(postDealFlagUrl, {
            dealID: params?.id,
            flagID: item.flagID,
            isSet: false,
          });
        }
      else {
        // if (item.flagCode == "DEFAULTED_FINANCIAL")
        // 	return axios.post(postDealFlagUrl, {
        // 		dealID: params?.id,
        // 		flagID: item.flagID,
        // 		isSet: false,
        // 	});
        // else {
        return axios.post(postDealFlagUrl, {
          dealID: params?.id,
          flagID: item.flagID,
          isSet: false,
        });
        // }
      }
    });
    // console.log("101 value2", values2);

    // setDealFlagToggle(flags[1]);
    try {
      Promise.all(flagsPromise).then((data) => {
        setChecked(!checked);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }

    // console.log("flagres =>", response);
    // const response2 = await axios.post(postDealFlagUrl, values2);
    // console.log("flagres =>", response2);
  };
  const parseParamsFromUrl = () => {
    try {
      let paramsTemp = queryString.parse(props.location.search);
      setParams(paramsTemp);
      setDealId(() => paramsTemp.id);
    } catch (error) {}
  };
  const onDelete = async (id) => {
    try {
      if (window.confirm("Are you sure! You want to delete this deal?")) {
        const resp = await axios.delete(getDealUrl + "/" + id);

        props.history.replace("/deal-Listing");
        // alert("Deal has been deleted successfully");
        // getDealsWithAxios(isActive);
        // parseParamsFromUrl();
      }
    } catch (err) {
      console.log("error in remove>>", err);
    }
  };
  useEffect(() => {
    axiosAuthHeaderSet();
    getDealDetailsWithAxios();
    getDealTypes();
    getDealRoles();

    getDealPartyMember();
    getPaymentPeriods();
    getDealFlagWithAxios();
  }, [homeData, dealId]);
  useEffect(() => {
    parseParamsFromUrl();
    //console.log("props>>>", props, dealId);
  }, []);
  return (
    <>
      <SideBar />
      <div id="main_wraper" className="">
        <AamsHeader />
        <div id="deal_engagement_details_page">
          <div className="clent-box-row new-two-row">
            {/* <div className='row new-two-row-one'>
              <div className='col-md-2 pr-0 '>
                <div className='client-name left-box-client'>
                  Client's name:
                </div>
              </div>
              <div className='col-md-10 pl-0 '>
                <div className='client-name-text right-box-client'>
                  Metropolitan
                </div>
              </div>
            </div> */}
            <MyBreadcrumb items={breadcrumbs} />
            <div className="row new-two-row-two">
              <div className="col-md-2 pr-0 ">
                <div className="client-name left-box-client">Deal name:</div>
              </div>
              <div className="col-md-10 pl-0 ">
                <div className="client-name-text right-box-client">
                  {dealDetails.name}
                  {/*<Button href='blank' className='btn btn-link m-0 p-0'>
                    <i className='fas fa-edit'></i>
                      </Button>*/}
                </div>
              </div>
            </div>
          </div>

          <Row>
            <Col sm="9">
              {/* start */}
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="true"
                      aria-controls="flush-collapseOne"
                    >
                      Deal Information
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapsed show"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {isEditDealInfo ? (
                        <>
                          <DealInfoEditForm
                            dealDetails={dealDetails}
                            setDealDetailsWithAxios={setDealDetailsWithAxios}
                            dealTypes={dealTypes}
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            className="deal-edit dealDeleteBtn"
                            title="Edit deal"
                            onClick={() => setEditDealInfoStatus((ds) => !ds)}
                          >
                            <i className="fas fa-edit" aria-hidden="true"></i>
                          </Button>
                          <Button
                            className="deal-edit "
                            title="Delete deal"
                            onClick={() => onDelete(params?.id)}
                          >
                            <i className="fas fa-trash" aria-hidden="true"></i>
                          </Button>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="deal-left-title">Deal Name:</div>
                            </div>
                            <div className="col-md-8">
                              <div className="deal-right-text">
                                {dealDetails.name}{" "}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-4">
                              <div className="deal-left-title">Deal Type:</div>
                            </div>
                            <div className="col-md-8">
                              <div className="deal-right-text">
                                {dealDetails.dealTypeName}{" "}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-4">
                              <div className="deal-left-title">
                                Deal Description:
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="deal-right-text">
                                {dealDetails.description}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-4">
                              <div className="deal-left-title">Originator:</div>
                            </div>
                            <div className="col-md-8">
                              <div className="deal-right-text">
                                {dealDetails.originator}
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col-md-4">
                              <div className="deal-left-title">
                                Deal Closing Date: <br />{" "}
                                <small>If already Closed</small>
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="deal-right-text">
                                {dealDetails.closingDate &&
                                  new Date(
                                    dealDetails.closingDate
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    day: "numeric",
                                    month: "numeric",
                                  })}{" "}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-4">
                              <div className="deal-left-title">
                                Initial Deal Size
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="deal-right-text">
                                {/* {dealDetails.initialSize} */}
                                <NumberFormat
                                  value={dealDetails.initialSize}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Deal Parties
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body p-1">
                      {/* <Button href='blank' className='deal-edit'>
                        <i className='fas fa-edit' aria-hidden='true'></i>
                      </Button> */}

                      <div className="container-fluid">
                        <Formik
                          initialValues={{
                            roleID: "",
                            name: "",
                            primaryContact: "",
                            dealID: params?.id,
                          }}
                          validationSchema={
                            dealPartyMemberAddFormValidationSchema
                          }
                          onSubmit={(values, { resetForm }) => {
                            // console.log("values>>", values, params?.id);
                            setDealPartyMember(values);
                            resetForm();
                            getDealPartyMember();
                          }}
                          enableReinitialize={true}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            /* and other goodies */
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div class="row pt-4 pb-4">
                                <div class="col-md-4 col-lg-auto">
                                  <Field
                                    as="select"
                                    class="form-select bg-transparent text-light"
                                    id="roleID"
                                    name="roleID"
                                  >
                                    <option>Select Role</option>
                                    {dealRoles?.map((dr) => (
                                      <option value={dr.id}>{dr?.name}</option>
                                    ))}
                                  </Field>
                                  {errors.roleID && touched.roleID ? (
                                    <div class="text-danger">
                                      {errors.roleID}
                                    </div>
                                  ) : null}
                                </div>
                                <div class="col-md-4 col-lg-auto">
                                  <Field
                                    type="text"
                                    class="form-control bg-transparent text-light"
                                    id="name"
                                    placeholder="Party  "
                                    name="name"
                                  />
                                  {errors.name && touched.name ? (
                                    <div class="text-danger">{errors.name}</div>
                                  ) : null}
                                  <Field
                                    type="hidden"
                                    class="form-control bg-transparent text-light"
                                    id="dealID"
                                    placeholder="Party  "
                                    name="dealID"
                                  />
                                </div>
                                <div class="col-md-4 col-lg-auto">
                                  <Field
                                    type="text"
                                    class="form-control bg-transparent text-light"
                                    id="primaryContact"
                                    placeholder="Primary Contact"
                                    name="primaryContact"
                                  />
                                  {errors.primaryContact &&
                                  touched.primaryContact ? (
                                    <div class="text-danger">
                                      {errors.primaryContact}
                                    </div>
                                  ) : null}
                                </div>
                                <div class="party-save col-lg-auto">
                                  {/* <a
                                href='javascript:void(0);'
                                class='btn btn-danger'
                              >
                                <i
                                  class='fa fa-arrow-left'
                                  aria-hidden='true'
                                ></i>
                              </a> */}
                                  <button
                                    href="javascript:void(0)"
                                    class="btn btn-primary m-0"
                                    type="submit"
                                    title="Save"
                                  >
                                    <i class="fas fa-save"></i>
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-striped mb-0 table-bordered ">
                          <thead className="table-dark">
                            <tr>
                              <th width="20%">Deal/Role</th>
                              <th width="30%">Party</th>
                              <th width="30%">Primary Contact</th>
                              <th width="20%">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dealPartyMembers?.map((d) => {
                              return (
                                <tr>
                                  <td>{d?.roleName} </td>
                                  <td>{d?.name} </td>
                                  <td>{d?.primaryContact}</td>
                                  <td>
                                    <button
                                      className="small_button_custom"
                                      onClick={() => delDealPartyMember(d?.id)}
                                    >
                                      <i class="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                            {/* <tr>
                            <td>Angelina Vitale </td>
                            <td>Test </td>
                            <td>Test</td>
                          </tr>
                          <tr>
                            <td>Angelina Vitale </td>
                            <td>Test </td>
                            <td>Test</td>
                          </tr>
                          <tr>
                            <td>Angelina Vitale </td>
                            <td>Test </td>
                            <td>Test</td>
                          </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      AMSS Engagement Setup
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <AamssEngagementSetup deal={dealDetails.id} />
                    </div>
                  </div>
                </div>
              </div>
              {/* end */}
            </Col>
            <Col sm="3">
              <Card className="mt-3 rounded-0  mb-3 bg-transparent">
                <CardBody className="p-0 rounded-0 bg-transparent ">
                  <CardTitle tag="h4">Deal Flags</CardTitle>
                  <div className="py-3 text-center">
                    <div className="form-check form-switch p-1">
                      <label
                        className="form-check-label left "
                        htmlFor="flexSwitchCheckDefault"
                      >
                        {" "}
                        Defaulted ?{" "}
                      </label>

                      {/* <Input
												className="form-check-input "
												type="checkbox"
												role="switch"
												id="flexSwitchCheckDefault"
												checked={checked}
												onChange={(v) => {
													// setChecked(!checked);
													// setTimeout(() => {
													// handleFlagChange();
													// }, 1000);
												}}
											/> */}

                      {dealFlagToggle == null ? (
                        <Input
                          className="form-check-input "
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          onClick={() => setDealFlagToggle(true)}
                        />
                      ) : (
                        <Input
                          className="form-check-input "
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked
                          onClick={(e) => {
                            setDealFlagToggle(null);
                            handleFlagChange(null);
                          }}
                        />
                      )}
                      {dealFlagToggle ? (
                        <select
                          name="dealFlag"
                          className="form-select bg-transparent text-light  ml-0 mr-0 mt-3"
                          onChange={(e) => handleFlagChange(e.target.value)}
                          style={{ marginLeft: "15px" }}
                          value={dealFlagToggle.flagID}
                        >
                          <option>Select</option>
                          {dealFlags?.map((dr) => (
                            <option value={dr.flagID}>{dr?.flagName}</option>
                          ))}
                        </select>
                      ) : // <label style={{ paddingLeft: "15px" }}>
                      // 	{dealFlagToggle.replace("DEFAULTED_", "")}
                      // </label>
                      null}

                      {/* {dealFlags?.map((dr, i) => {
												if (dr.isSet) {
													return (
														<div>{dr.flagCode.replace("DEFAULTED_", "")}</div>
													);
												}
											})} */}
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card className="mt-3 rounded-0 bg-transparent mb-3">
                <CardBody className="p-0 rounded-0 bg-transparent ">
                  <CardTitle tag="h4">
                    Deal Parameters{" "}
                    <span className="float-end ">
                      <Button
                        // color="primary"
                        id="toggler"
                        style={{
                          margin: "0 8px 0 0",
                          padding: "0",
                          backgroundColor: "rgba(0,0,0,0)",
                        }}
                      >
                        <i className="fas fa-eye"></i>
                      </Button>
                    </span>
                  </CardTitle>

                  <UncontrolledCollapse toggler="#toggler" defaultOpen={true}>
                    <DealParameters
                      dealDetails={dealDetails}
                      setDealDetailsWithAxios={setDealDetailsWithAxios}
                      dealParameterFormValidationSchema={
                        dealParameterFormValidationSchema
                      }
                      isDealParameterEdit={isDealParameterEdit}
                      setDealParameterEdit={setDealParameterEdit}
                      paymentPeriods={paymentPeriods}
                    />
                  </UncontrolledCollapse>
                </CardBody>
              </Card>
              <div></div>
            </Col>
          </Row>
        </div>
        <Loader isLoading={isLoading} />
        <AamsFooter />
        {showAlert && (
          <Toaster show={showAlert} message={alertMessage} type={alertType} />
        )}
      </div>
    </>
  );
}

export default DealEngagement;
