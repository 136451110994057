import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { config } from "../constant";
// reactstrap components
import { Button, Card, CardBody, CardTitle } from "reactstrap";

// core components
import SideBar from "components/SideBar.js";
import AamsHeader from "components/AamsHeader.js";
import AamsFooter from "components/AamsFooter.js";
import NumberFormat from "react-number-format";
import Loader from "components/Loader";
import { axiosAuthHeaderSet } from "helpers/utils";
import { useSelector } from "react-redux";
import Toaster from "components/Toaster";
import { AppContext } from "App";
import { sortedByProperty } from "helpers/data";
import MyBreadcrumb from "components/MyBreadcrumb/MyBreadcrumb";

const getDealUrl = config.url.API_URL + "deal";

function DealList() {
  const [deals, setDeals] = useState([]);
  const [dealsCopy, setDealsCopy] = useState([]);
  const [isActive, setActive] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const homeData = useSelector((state) => state.home);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState("info");
  const appContext = useContext(AppContext);
  const [sortType, setSortType] = useState(null);
  const breadcrumbs = [
    {
      op: "Home",
      link: "/",
    },
    {
      op: "Deals",
      link: "/deal-Listing",
    },
  ];
  const history = useHistory();
  const getDealsWithAxios = async (isActive) => {
    try {
      setLoading(true);
      const resp = await axios.get(getDealUrl + "?IncludeInactive=true");
      console.log("getDealsWithAxios", resp, isActive);
      setActive(isActive);
      const deals = resp.data.responseData;
      const filterData = deals.filter((d) => {
        return d.isActive.toString() == isActive.toString();
      });
      console.log("filterData>>", filterData, isActive);
      setDeals(filterData);
      // setDeals(deals);
      setDealsCopy(deals);
      setLoading(false);
    } catch (error) {
      // setAlertType("danger");
      // console.log("error>>", error);
      // setShowAlert(true);
      // setAlertMessage(error.response?.data?.Message);
      if (error.response.status == 401) {
        appContext.handleLogout();
      }
    }
    //console.log(posts);
  };
  const searchDeal = (e) => {
    setSearchText(e.target.value);
    var search = new RegExp(e.target.value, "gi");
    const tempDeal =
      dealsCopy.length &&
      dealsCopy.filter((d) => {
        if (
          d.name.match(search) &&
          d.isActive.toString() == isActive.toString()
        )
          return d;
      });
    setDeals(tempDeal);
  };
  const handleSelection = (e) => {
    console.log("handle>>", typeof e.target.value, e.target.value, isActive);
    if (e.target.value == "true") {
      setActive(true);
    } else {
      setActive(false);
    }
    // if(isActive ===e.target.value){

    // }else{

    //   setActive((p)=>!p)
    // }
    console.log("handle>>", typeof e.target.value, e.target.value, isActive);
    setTimeout(() => {
      getDealsWithAxios(true);
    }, 1000);
  };
  const onDelete = async (id) => {
    if (window.confirm("Are you sure! You want to delete this deal?")) {
      const resp = await axios.delete(getDealUrl + "/" + id);
      console.log(resp);
      // alert("Deal has been deleted successfully");
      getDealsWithAxios(isActive);
    }
  };

  const tableSort = (property) => {
    console.log(property);
    setSortType((t) => {
      if (t == "asc") return "desc";
      else return "asc";
    });
    const tempData = sortedByProperty(deals, property, sortType);
    setDeals(tempData);
  };
  useEffect(() => {
    axiosAuthHeaderSet();
    getDealsWithAxios(true);
  }, [homeData]);

  return (
    <>
      <SideBar />
      <div id="main_wraper" className="">
        <AamsHeader />
        <div id="deal_listing_page">
          <div className="clent-box-row">
            {/* <div className='row'>
              <div className='col-md-2 pr-0'>
                <div className='client-name left-box-client'>
                  Client's name:
                </div>
              </div>
              <div className='col-md-10 pl-0'>
                <div className='client-name-text right-box-client'>
                  Metropoliton
                </div>
              </div>
            </div> */}
          </div>
          <div className="clearfix"></div>
          <MyBreadcrumb items={breadcrumbs} />

          <div class="d-flex justify-content-between">
            <a class="btn btn-outline-primary" href="add-deals">
              New deal <i className=" fa  fa-arrow-circle-right"></i>
            </a>
          </div>
          <div className="clearfix"></div>

          <Card className="card rounded-0 border-0 mb-3 bg-transparent">
            <CardBody className="p-0 rp-0 rounded-0 border-0 bg-transparent">
              <CardTitle tag="h4" style={{ padding: 0, margin: 0 }}>
                <div
                  class="row justify-content-between"
                  style={{
                    paddingLeft: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    paddingRight: 0,
                    marginBottom: 10,
                  }}
                >
                  <div class="col-md-2" style={{ padding: 0, margin: 0 }}>
                    {" "}
                    <div class="Option">
                      <select
                        name="optionSelect"
                        onChange={(e) => {
                          setActive(e.target.value);
                          getDealsWithAxios(e.target.value);
                          setSearchText("");
                        }}
                        class="form-select bg-transparent text-light"
                      >
                        <option value={true} selected={isActive == true}>
                          Active
                        </option>
                        <option value={false} selected={isActive == false}>
                          Inactive
                        </option>
                      </select>
                    </div>{" "}
                  </div>
                  <div class="col-md-7 text-center">Deal Listing</div>
                  <div class="col-md-3" style={{ padding: 0, margin: 0 }}>
                    <div class="search">
                      <input
                        type="text"
                        class="form-control bg-transparent text-light"
                        placeholder="Search by deal name..."
                        value={searchText}
                        onChange={searchDeal}
                      />
                    </div>
                  </div>
                </div>
              </CardTitle>

              <div className="table-responsive">
                <table className="table table-bordered text-light mb-0">
                  <thead className="table-dark">
                    <tr>
                      <th>
                        <a type="button" onClick={() => tableSort("name")}>
                          Deal
                        </a>
                      </th>
                      <th>
                        <a
                          type="button"
                          onClick={() => tableSort("originator")}
                        >
                          Originator
                        </a>
                      </th>
                      <th style={{ "white-space": "nowrap" }}>
                        <a
                          type="button"
                          onClick={() => tableSort("dealTypeName")}
                        >
                          Asset Type
                        </a>
                      </th>
                      {/* <th>State</th> */}

                      <th style={{ "white-space": "nowrap" }}>
                        <a
                          type="button"
                          onClick={() => tableSort("isDefaultedFinancial")}
                        >
                          Defaulted
                        </a>
                      </th>
                      {/* <th style={{ "white-space": "nowrap" }}>
                        <a
                          type="button"
                          onClick={() => tableSort("isDefaultedOther")}
                        >
                          Defaulted (Other)
                        </a>
                      </th> */}
                      <th style={{ "white-space": "nowrap" }}>
                        <a
                          type="button"
                          onClick={() => tableSort("closingDate")}
                        >
                          Close date
                        </a>
                      </th>
                      <th style={{ "white-space": "nowrap" }}>Is Active</th>
                      <th
                        style={{ "white-space": "nowrap", "text-align": "end" }}
                      >
                        <a
                          type="button"
                          onClick={() => tableSort("initialSize")}
                        >
                          Original Amount
                        </a>
                      </th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {deals.map((deal, i) => (
                      <tr>
                        <td>
                          <a
                            href="javascript:void(0);"
                            onClick={() =>
                              history.replace("/deal-engagement?id=" + deal.id)
                            }
                          >
                            <strong>{deal.name}</strong>
                          </a>
                        </td>
                        <td>{deal.originator}</td>
                        <td style={{ "white-space": "nowrap" }}>
                          {deal.dealTypeName}
                        </td>
                        {/* <td>{deal.regionAbbreviation}</td> */}

                        <td>
                          {!deal.isDefaultedFinancial && !deal.isDefaultedOther
                            ? "Performing"
                            : deal.isDefaultedFinancial &&
                              !deal.isDefaultedOther
                            ? "Non-performing (Financial) "
                            : "Non-performing (Other) "}
                        </td>
                        {/* <td>{deal.isDefaultedOther ? "TRUE" : "FALSE"}</td> */}
                        <td>
                          {deal.closingDate &&
                            new Date(deal.closingDate).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                day: "numeric",
                                month: "numeric",
                              }
                            )}
                        </td>
                        <td>{deal.isActive ? "Active" : "Inactive"} </td>
                        <td style={{ "text-align": "end" }}>
                          {deal.initialSize && (
                            <span class="text-end">
                              <NumberFormat
                                value={deal.initialSize}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </span>
                          )}
                        </td>
                        {/* <td>
                          <div
                            className="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic outlined example"
                          >
                            <Button
                              className="btn m-0 btn-outline-primary border-0"
                              //color="primary"
                              // href={"deal-engagement/" + deal.id}
                              onClick={() =>
                                history.replace(
                                  "/deal-engagement?id=" + deal.id
                                )
                              }
                            >
                              <i className="fa fa-eye" />
                            </Button>
                            <Button
                              className="btn m-0 btn-outline-danger border-0"
                              //color="danger"
                              href="javascript:void(0);"
                              onClick={() => onDelete(deal.id)}
                            >
                              <i className="fa fa-trash" aria-hidden="true" />
                            </Button>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </div>
        <AamsFooter />
        {showAlert && (
          <Toaster show={showAlert} message={alertMessage} type={alertType} />
        )}
        <Loader isLoading={isLoading} />
      </div>
    </>
  );
}

export default DealList;
