import React, { useState } from "react";
import "assets/css/style.css";
import { SignInButton } from "components/AzureButton";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest, graphConfig } from "authConfig";
import { setLoginData } from "helpers/utils";
import axios from "axios";
import { setClientStorage } from "helpers/utils";
import { config } from "constant";
import { useDispatch } from "react-redux";
import { updateClient } from "actions/homeAction";
import { storeClients } from "actions/homeAction";
import { setRestrictAccount } from "helpers/utils";
import { setErrorMessage } from "helpers/utils";

const clientUrl = config.url.API_URL + "client";

export default function LoginPage() {
  const { instance, accounts, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  // const name = accounts[0] && accounts[0].name;
  const [isLogin, setLogin] = useState(false);

  function RequestAccessToken() {
    // if (!isLogin) {
    // setLogin(true);
    if (accounts.length > 0) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance
        .acquireTokenSilent(request)
        .then((response) => {
          console.log("request>>", response);
          // callMsGraph(response.accessToken);
          saveToken(response);
          // axios.defaults.headers.common[
          // 	"Authorization"
          // ] = `Bearer ${response.accessToken}`;
          setAccessToken(response.accessToken);
          loadClients(response.accessToken)
            .then((data) => {
              setError(null);
              setRestrictAccount(false);
              setErrorMessage(null);
              window.location.href = "/dashboard";
            })
            .catch((err) => {
              console.log("err>>>", err?.response);
              //   alert(err?.response?.data?.Message);
              setRestrictAccount(true);
              // setError(err?.response?.data?.Message);
              setErrorMessage(err?.response?.data?.Message);
              setTimeout(() => {
                window.location.href = "/error";
              }, 1000);
            });
        })
        .catch((e) => {
          instance.acquireTokenSilent(request).then((response) => {
            console.log("request>>", response);
            // callMsGraph(response.accessToken);
            saveToken(response);
            // axios.defaults.headers.common[
            // 	"Authorization"
            // ] = `Bearer ${response.accessToken}`;
            setAccessToken(response.accessToken);
            loadClients(response.accessToken)
              .then((data) => {
                setError(null);
                setRestrictAccount(false);
                setErrorMessage(null);
                window.location.href = "/dashboard";
              })
              .catch((err) => {
                console.log("err>>>", err?.response);
                setRestrictAccount(true);
                // setError(err?.response?.data?.Message);
                setErrorMessage(err?.response?.data?.Message);
                setTimeout(() => {
                  window.location.href = "/error";
                }, 1000);
                // alert(err?.response?.data?.Message);
              });
          });
        });
    }
    // }
  }
  function saveToken(token) {
    setLoginData(token);
  }
  const loadClients = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await axios.get(clientUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!resp?.data?.isError && resp?.data?.responseData) {
          setClientStorage(resp?.data?.responseData?.id);
          dispatch(updateClient(resp?.data?.responseData?.id));
          dispatch(storeClients(resp?.data?.responseData));
        }

        console.log("resp loadClients>>", resp);
        return resolve(resp);
      } catch (err) {
        return reject(err);
      }
    });
  };
  function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers,
    };

    return fetch(graphConfig.graphMeEndpoint, options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
  return (
    <div className="welcome_page">
      <div id="log-form">
        <div id="login-form-wrap">
          <div class="logo">
            <img
              class="m-5"
              alt="..."
              src={require("assets/images/logo-dashboard.png").default}
            ></img>
          </div>
          <h2>Welcome to amss</h2>
          {error && <div class="alert alert-danger">{error}</div>}
          {/* <h2>Login</h2> */}
          {/* <form id="login-form">
					<p>
						<input
							type="text"
							id="username"
							name="username"
							placeholder="Username"
							required
						/>
						<i class="validation">
							<span></span>
							<span></span>
						</i>
					</p>
					<p>
						<input
							type="text"
							id="Password"
							name="Password"
							placeholder="Password"
							required
						/>
						<i class="validation">
							<span></span>
							<span></span>
						</i>
					</p>
					<p>
						<input type="submit" id="login" value="Login" />
					</p>
				</form> */}
          <div id="create-account-wrap">
            <p>
              <UnauthenticatedTemplate>
                <SignInButton />
              </UnauthenticatedTemplate>
              <AuthenticatedTemplate>
                {/* <p class="text-default">
									You are signed in!{" "}
									<button onClick={RequestAccessToken}>proceed</button> to
									Dashbard
								</p> */}
                {RequestAccessToken()}
              </AuthenticatedTemplate>
              {/* Not a member? <a href="#">Create Account</a> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
