import { useState, useEffect } from "react";
import { config } from "../constant";
import axios from "axios";
import moment from "moment";
import { Formik, Field } from "formik";
import * as yup from "yup";
import Loader from "./Loader";
import { sortByDate } from "helpers/data";
const getPeriodUrl = config.url.API_URL + "period";
const postPeriodUrl = config.url.API_URL + "period";
const getPeriodStatus = config.url.API_URL + "period-status";

const initialValues = {
  startDate: "",
  endDate: "",
  deliveryDate: "",
};
const periodValidationSchema = yup.object().shape({
  startDate: yup.string().required("Start date is a required field"),
  endDate: yup.string().required("End date is a required field"),
  deliveryDate: yup.string().required("Delivery date is a required field"),
});
function AmssPeriod(props) {
  let [periodCnt, setPeriodCnt] = useState(props.periods.length + 1);
  let [showAdd, setShowAdd] = useState(1);
  const [periodRows, setPeriodRows] = useState(initialValues);
  const [periodRowsDB, setPeriodRowsDB] = useState(props.periods);
  const [periodId, setPeriodId] = useState("");
  const [esd, setEsd] = useState("");
  const [eed, setEed] = useState("");
  const [edd, setEdd] = useState("");
  const [isNewPeriod, setNewPeriod] = useState(false);
  const [periodStatus, setPeriodStatus] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [errorResponse, setError] = useState(null);
  //console.log(periodRowsDB);
  // const getPeriodsStatus = async () => {
  //     const resp = await axios.get(getPeriodStatus );
  //     //console.log(resp.data.responseData);
  //     const periods = resp.data.responseData;
  //     setPeriodStatus(periods);
  //     // console.log("engagements>>", engagements);
  //   };
  const getPeriodsWithAxios = async () => {
    const resp = await axios.get(
      getPeriodUrl + "?EngagementID=" + props.engagement
    );
    //console.log(resp.data.responseData);
    const periods = resp.data.responseData.sort(sortByDate);
    setPeriodRowsDB(periods);
    // console.log("engagements>>", engagements);
  };
  const addNewPeriodRow = () => {
    setNewPeriod(true);
    console.log("new ");
  };
  const addPeriodRow = () => {
    setPeriodCnt(periodCnt + 1);
    setShowAdd(0);

    let curdate = new Date().toISOString();
    const dataArr = {
      engagementID: props.engagement,
      startDate: curdate,
      endDate: curdate,
      deliveryDate: curdate,
    };
    setLoading(true);
    const headers = {
      "content-type": "application/json",
    };
    axios
      .post(postPeriodUrl, dataArr, { headers })
      .then((periodresp) => {
        //console.log(periodresp);
        setLoading(false);
        setPeriodCnt(periodCnt + 1);
        if (periodresp.data.status) {
          getPeriodsWithAxios();
          setPeriodId(periodresp.data.responseData.id);
        }
      })
      .catch((error) => {
        // error response
        setLoading(false);
      });
    //console.log(periodRows);
    //console.log(periodCnt);
  };

  const cancelPeriodRow = (periodid) => {
    setPeriodCnt(periodCnt - 1);
    setShowAdd(1);

    const headers = {
      "content-type": "application/json",
    };
    axios
      .delete(postPeriodUrl + "/" + periodid)
      .then((periodresp) => {
        //console.log(periodresp);
        setPeriodCnt(periodCnt + 1);
        if (periodresp.data.status) {
          //console.log("period added");
          // window.location.reload();
          // alert('Successfully deleted a period');
          getPeriodsWithAxios();
          //setPeriodRows([]);
        }
      })
      .catch((error) => {
        // error response
      });
    //console.log(periodRows);
    //console.log(periodCnt);
  };

  const saveNewPeriod = (values) => {
    //console.log(props);
    //console.log(periodRows[0].startDate);
    setError(null);
    setLoading(true);
    const dataArr = {
      engagementID: props.engagement,
      startDate: values.startDate,
      endDate: values.endDate,
      deliveryDate: values.deliveryDate,
      // statusCode: "OPEN"
      statusCode: "NOT_STARTED",
    };
    const headers = {
      "content-type": "application/json",
    };
    axios
      .post(postPeriodUrl, dataArr, { headers })
      .then((periodresp) => {
        setLoading(false);
        //console.log(periodresp);
        //setPeriodCnt(periodCnt + 1);
        if (periodresp.data.status) {
          //console.log("period added");
          // window.location.reload();
          getPeriodsWithAxios();
          // setPeriodRows([]);
          setNewPeriod(false);
          setShowAdd(1);
          // setEsd('');
          // setEed('');
          // setEdd('');
        }
      })
      .catch((error) => {
        console.log(error.response);
        setError(error.response?.data?.Message);
        setLoading(false);
        // error response
      });
  };

  // };
  // const saveNewPeriod = (periodId) => {
  //   //console.log(props);
  //   //console.log(periodRows[0].startDate);
  // if(periodRows.startDate===''){
  // 	setEsd('Please enter start date');
  // }else if(periodRows.endDate===''){
  // 	setEed('Please enter end date');
  // }else if(periodRows.deliveryDate===''){
  // 	setEdd('Please enter delivery date');
  // }else{
  // 	const dataArr = {
  // 	  id: periodId,
  // 	  startDate: periodRows.startDate,
  // 	  endDate: periodRows.endDate,
  // 	  deliveryDate: periodRows.deliveryDate,
  // 	  // statusCode: "OPEN"
  // 	  statusCode: "NOT_STARTED"
  // 	};
  // 	const headers = {
  // 	  "content-type": "application/json",
  // 	};
  // 	axios
  // 	  .put(postPeriodUrl, dataArr, { headers })
  // 	  .then((periodresp) => {
  // 		//console.log(periodresp);
  // 		//setPeriodCnt(periodCnt + 1);
  // 		if (periodresp.data.status) {
  // 		  //console.log("period added");
  // 		  // window.location.reload();
  // 		  getPeriodsWithAxios()
  // 		  setPeriodRows([]);
  // 			setShowAdd(1);
  // 			setEsd('');
  // 			setEed('');
  // 			setEdd('');
  // 		}
  // 	  })
  // 	  .catch((error) => {
  // 		// error response
  // 	  });
  // }

  // };

  const closePeriod = (period, status) => {
    //console.log(props);
    //console.log(periodRows[0].startDate);
    // const statusClose = periodStatus.filter(p=>p.code==status)[0]
    // console.log("closePeriod>>", statusClose,periodStatus,period)
    const dataArr = {
      id: period.id,
      startDate: period.startDate,
      endDate: period.endDate,
      deliveryDate: period.deliveryDate,
      determinationDate: period.determinationDate,
      statusCode: status,
      // statusID : statusClose?.id,
      paymentDate: period.paymentDate,
    };
    const headers = {
      "content-type": "application/json",
    };
    setLoading(true);
    axios
      .put(postPeriodUrl, dataArr, { headers })
      .then((periodresp) => {
        //console.log(periodresp);
        //setPeriodCnt(periodCnt + 1);
        setLoading(false);
        if (periodresp.data.status) {
          //console.log("period added");
          // window.location.reload();
          getPeriodsWithAxios();
          setPeriodRows([]);
          setShowAdd(1);
        }
      })
      .catch((error) => {
        setLoading(false);
        // error response
      });
  };

  const deletePeriod = (period) => {
    //console.log(props);
    //console.log(periodRows[0].startDate);
    // const statusClose = periodStatus.filter(p=>p.code==status)[0]
    // console.log("closePeriod>>", statusClose,periodStatus,period)
    if (window.confirm("Are you sure you want to delete this period?")) {
      const headers = {
        "content-type": "application/json",
      };
      setLoading(true);
      axios
        .delete(postPeriodUrl + "/" + period?.id, { headers })
        .then((periodresp) => {
          //console.log(periodresp);
          //setPeriodCnt(periodCnt + 1);
          setLoading(false);
          if (periodresp.data.status) {
            //console.log("period added");
            // window.location.reload();
            getPeriodsWithAxios();
            setPeriodRows([]);
            setShowAdd(1);
          }
        })
        .catch((error) => {
          setLoading(false);
          // error response
        });
    }
  };

  const handleChange = (evnt) => {
    const { name, value } = evnt.target;
    //const list = [...periodRows];
    //list[index][name] = value;
    if (evnt.target.name === "startDate") {
      setEsd("");
    }
    if (evnt.target.name === "endDate") {
      setEed("");
    }
    if (evnt.target.name === "deliveryDate") {
      setEdd("");
    }
    setPeriodRows({
      ...periodRows,
      [name]: value,
    });
  };

  useEffect(() => {
    //console.log(props);
    // getPeriodsStatus();
    if (props.engagement) {
      getPeriodsWithAxios();
      //console.log(periodRowsDB);
    }
  }, [props]);

  return (
    <>
      <div className="avs-setup d-flex justify-content-between align-items-center">
        Periods
        <div>
          {showAdd === 1 ? (
            <button
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title=""
              data-bs-original-title="Add New Period"
              aria-label="Add New Period"
              onClick={addNewPeriodRow}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="covenant-table" id="periodTable">
        <div class="row p-2 mt-3">
          {isNewPeriod && (
            <Formik
              initialValues={initialValues}
              onSubmit={saveNewPeriod}
              validationSchema={periodValidationSchema}
            >
              {({ handleChange, handleSubmit, touched, values, errors }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    {errorResponse && (
                      <div className="alert alert-danger">{errorResponse}</div>
                    )}
                    <div class="row" key={"new_period_0"}>
                      <div class="col">
                        <label for="startDate">Start Date</label>
                        <Field
                          type="date"
                          name="startDate"
                          id="startDate"
                          // onChange={(evnt) => handleChange(evnt)}
                          // // value={periodRows.startDate}
                          // min={moment().format('YYYY-MM-DD')}
                          className="form-control bg-transparent text-light"
                          placeholder="08/15/22"
                        />
                        {errors.startDate && touched.startDate ? (
                          <div class="text-danger">{errors.startDate}</div>
                        ) : null}
                      </div>
                      <div class="col">
                        <label for="endDate">End Date</label>
                        <Field
                          type="date"
                          name="endDate"
                          id="endDate"
                          // onChange={(evnt) => handleChange(evnt)}
                          // value={periodRows.endDate}
                          min={
                            values.startDate &&
                            moment(values.startDate)
                              .add(1, "day")
                              .format("YYYY-MM-DD")
                          }
                          className="form-control bg-transparent text-light"
                          placeholder="08/15/22"
                        />
                        {errors.endDate && touched.endDate ? (
                          <div class="text-danger">{errors.endDate}</div>
                        ) : null}
                      </div>
                      <div class="col">
                        <label for="deliveryDate">Delivery Date</label>
                        <Field
                          type="date"
                          name="deliveryDate"
                          id="deliveryDate"
                          // onChange={(evnt) => handleChange(evnt)}
                          // value={periodRows.deliveryDate}
                          min={
                            values.endDate &&
                            moment(values.endDate)
                              .add(1, "day")
                              .format("YYYY-MM-DD")
                          }
                          className="form-control bg-transparent text-light"
                          placeholder="08/15/22"
                        />
                        {errors.deliveryDate && touched.deliveryDate ? (
                          <div class="text-danger">{errors.deliveryDate}</div>
                        ) : null}
                      </div>
                      <div class="">
                        <button
                          // onClick={()=>saveNewPeriod(data2.id)}
                          type="submit"
                          className="btn btn-primary align-item-end"
                        >
                          <i class="fas fa-save"></i>
                        </button>
                        {/* <button
                              onClick={()=>cancelPeriodRow(data2.id)}
                              className='btn btn-danger'
                            >
                                <i class="fa fa-trash" aria-hidden="true"></i>
                            </button> */}
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          )}
        </div>
        <div className="table-responsive">
          <table className="table table-bordered text-light mb-0">
            <thead>
              <tr>
                <th>Period</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Delivery Date</th>
                <th width="15%"></th>
                <th width="15%"></th>
              </tr>
            </thead>
            <tbody id="periodsTableBody">
              {periodRowsDB &&
                periodRowsDB.map((data2, i) => {
                  // if(data2.statusCode!=="NOT_STARTED"){
                  return (
                    <tr key={i}>
                      <td className="text-bold-500 periodCountTd">{i + 1}</td>
                      <td>{moment(data2.startDate).format("MM-DD-YYYY")}</td>
                      <td>{moment(data2.endDate).format("MM-DD-YYYY")}</td>
                      <td>{moment(data2.deliveryDate).format("MM-DD-YYYY")}</td>
                      <td>{data2.statusCode} </td>
                      <td>
                        {data2.statusCode === "OPEN" && (
                          <button
                            onClick={() => closePeriod(data2, "CLOSED")}
                            className="btn btn-primary"
                            type="button"
                            title="Close Period"
                          >
                            <i class="fas fa-folder text-white"></i>
                          </button>
                        )}
                        {data2.statusCode === "CLOSED" && (
                          <button
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Are you sure you want to reopen this period?"
                                )
                              ) {
                                closePeriod(data2, "OPEN");
                              }
                            }}
                            className="btn btn-primary"
                            type="button"
                            title="Reopen Period"
                          >
                            <i class="fas fa-folder-open"></i>
                          </button>
                        )}
                        {data2.statusCode === "NOT_STARTED" && (
                          <>
                            <button
                              onClick={() => closePeriod(data2, "OPEN")}
                              className="btn btn-primary mr-2"
                              type="button"
                              title="Open Period"
                            >
                              <i class="fa fa-folder-open"></i>
                            </button>
                            <button
                              onClick={() => deletePeriod(data2)}
                              className="btn btn-danger"
                              type="button"
                              title="Delete Period"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  );

                  // }else{
                  // 	return (
                  //         <tr key={i}>
                  //           <td className='text-bold-500 periodCountTd'>
                  //             {/* {data.periodNo} */}
                  //           </td>
                  //           <td>
                  //             <input
                  //               type='date'
                  //               name='startDate'
                  //               onChange={(evnt) => handleChange(evnt)}
                  //               value={periodRows.startDate}
                  //               min={moment().format('YYYY-MM-DD')}
                  //               className='form-control bg-transparent text-light'
                  //               placeholder='08/15/22'
                  //             />
                  //   {esd}
                  //           </td>
                  //           <td>
                  //             <input
                  //               type='date'
                  //               name='endDate'
                  //               onChange={(evnt) => handleChange(evnt)}
                  //               value={periodRows.endDate}
                  //               min={periodRows.startDate && moment(periodRows.startDate).add(1,'day').format('YYYY-MM-DD')}
                  //               className='form-control bg-transparent text-light'
                  //               placeholder='08/15/22'
                  //             />
                  //   {eed}
                  //           </td>
                  //           <td>
                  //             <input
                  //               type='date'
                  //               name='deliveryDate'
                  //               onChange={(evnt) => handleChange(evnt)}
                  //               value={periodRows.deliveryDate}
                  //               min={periodRows.endDate && moment(periodRows.endDate).add(1,'day').format('YYYY-MM-DD')}
                  //               className='form-control bg-transparent text-light'
                  //               placeholder='08/15/22'
                  //             />
                  //   {edd}
                  //           </td>
                  //           <td>
                  //             <button
                  //               onClick={()=>saveNewPeriod(data2.id)}
                  //               className='btn btn-primary'
                  //             >
                  //               <i class="fas fa-save"></i>
                  //             </button>
                  //   <button
                  //               onClick={()=>cancelPeriodRow(data2.id)}
                  //               className='btn btn-danger'
                  //             >
                  //                 <i class="fa fa-trash" aria-hidden="true"></i>
                  //             </button>
                  //           </td>
                  // <td></td>
                  //         </tr>
                  //       );
                  // }
                })}
            </tbody>
          </table>
        </div>
        <Loader isLoading={isLoading} />
      </div>
    </>
  );
}
export default AmssPeriod;
