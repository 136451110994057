import axios from "axios";
import moment from "moment";
export const isLogin = () => {
  const token = sessionStorage.getItem("token");
  if (token) return true;
  else return false;
};
export const isRestrict = () => {
  const isRestrict = sessionStorage.getItem("isRestrict");
  console.log("100 isRestrict", isRestrict);
  if (isRestrict && isRestrict == "true") return true;
  else return false;
};
export const setRestrictAccount = async (status = true) => {
  sessionStorage.setItem("isRestrict", status);
};
export const getErrorMessage = () => {
  return sessionStorage.getItem("errorMessage");
};
export const setErrorMessage = async (msg = null) => {
  sessionStorage.setItem("errorMessage", msg);
};
export const setLoginData = async (r) => {
  sessionStorage.setItem("token", r.accessToken);
  sessionStorage.setItem("name", r.account.name);
  sessionStorage.setItem("email", r.account.username);
  // window.location.href = "/dashboard";
};
export const logout = async () => {
  return new Promise((resolve) => {
    sessionStorage.clear();
    window.location.href = "/";
    return resolve(true);
  });
};
export const setClientStorage = (id) => {
  return new Promise((resolve) => {
    sessionStorage.setItem("client_id", id);
    return resolve(true);
  });
};

export const getName = () => {
  return sessionStorage.getItem("name");
};
export const getToken = () => {
  return sessionStorage.getItem("token");
};
export const getClientStorage = () => {
  return sessionStorage.getItem("client_id");
};
export const axiosAuthHeaderSet = () => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${sessionStorage.getItem("token")}`;
  // axios.defaults.headers.common["Client-ID"] = `${sessionStorage.getItem(
  // 	"client_id"
  // )}`;
};

export const dateFormat = (d, f) => {
  // console.log("dateFormat", moment(d));
  return moment(d).format(f);
};
