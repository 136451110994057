import React from "react";
import { Button, Modal } from "react-bootstrap";
import { ModuleKind } from "typescript";

export default function ErrorModal({
  showc,
  handleClosec,
  data,
  errorsDownload,
}) {
  console.log("error>>", data);
  return (
    <Modal
      show={showc}
      onHide={handleClosec}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="eng_file_upload_modal"
    >
      <Modal.Title>Errors</Modal.Title>
      <Modal.Body
        style={{
          margin: "0",
        }}
      >
        <ul
          style={{
            color: "red",
          }}
        >
          {data &&
            data.length > 0 &&
            data.map((d) => <li>{d?.errorDetails}</li>)}
        </ul>
        <div>
          <Button
            style={{
              fontSize: "16px",
              marginRight: "8px",
            }}
            className="btn btn-success"
            variant="success"
            type="button"
            onClick={handleClosec}
          >
            <i class="fas fa-close"></i> Cancel
          </Button>
          <Button
            style={{
              fontSize: "16px",
              marginRight: "8px",
            }}
            className="btn btn-success"
            variant="success"
            type="button"
            onClick={errorsDownload}
          >
            <i class="fas fa-check"></i> Download Error List
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
