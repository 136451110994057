import React, { useContext, useEffect, useState } from "react";
// import { config } from "../constant";
// import axios from "axios";
// import { Formik, Field, FieldArray } from "formik";
//import React, { useState } from 'react';
//import Button from 'react-bootstrap/Button';
//import Modal from 'react-bootstrap/Modal';

// reactstrap components
//import { Field } from "formik";
import { Button, Modal, Form, Table } from "react-bootstrap";

// core components
import SideBar from "components/SideBar.js";
import AamsHeader from "components/AamsHeader.js";
import AamsFooter from "components/AamsFooter.js";
import FileUploadModal from "./FileUploadModal";
import FieldMappingModal from "./FieldMappingModal";
import List from "./List";
import { Network } from "services/Network";
import { API } from "services/API";
import { dateFormat } from "helpers/utils";
import { CSVParser } from "services/CSVParser";
import ErrorModal from "./ErrorModal";
import Loader from "components/Loader";
import { sortedByProperty } from "helpers/data";
import { AppContext } from "App";
import { titleToCamel } from "helpers/data";
import api from "config/api";
import { toFindDuplicates } from "helpers/data";
import MyBreadcrumb from "components/MyBreadcrumb/MyBreadcrumb";
import TapListModal from "./TapListModal";

function PortfolioList(props) {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const [showb, setShowb] = useState(false);
  const handleShowb = () => setShowb(true);
  const [uploadType, setUploadType] = useState("asset");
  const handleCloseb = () => {
    setShowb(false);
    setSuccess(null);
    setError(null);
    setErrorResponse(null);
    setUploadPercent(0);
  };
  const breadcrumbs = [
    {
      op: "Home",
      link: "/",
    },
    {
      op: "Portfolio history",
      link: "/portfolio-history",
    },
  ];
  const [showc, setShowc] = useState(false);
  const handleShowc = () => setShowc(true);
  const handleClosec = () => setShowc(false);
  const [isLoading, setLoading] = useState(false);
  const [sortType, setSortType] = useState(null);
  const [uploadPercent, setUploadPercent] = useState(0);

  const [deals, setDeals] = useState([]);
  const [monthEndDateList, setMonthEndDateList] = useState([]);
  const [assetTapeList, setAssetTapList] = useState([]);
  const [assetTapeListCopy, setAssetTapListCopy] = useState([]);
  const [selectedTapeAsset, setSelectedTapeAsset] = useState(null);
  const [isUploadStepOneDone, setUploadStepOneDone] = useState(false);
  const [isUploadStepTwoDone, setUploadStepTwoDone] = useState(false);
  const [isUploadStepThreeDone, setUploadStepThreeDone] = useState(false);
  const [isFileOverwrite, setFileOverwrite] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileParseData, setFileParseData] = useState([]);
  const [mappingFieldData, setMappingFieldData] = useState([]);
  const [mappingData, setMappingData] = useState(null);
  const [machingData, setMachingData] = useState([]);
  const [error, setError] = useState(null);
  const [isDetailsError, setDetailsError] = useState(null);
  const [errorRespose, setErrorResponse] = useState(null);
  const [success, setSuccess] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [selectedType, setSelectedType] = useState("asset");
  const appContext = useContext(AppContext);
  const [isTapePreviewOpen, setTapePreviewOpen] = useState(false);
  const [tapeData, setTapData] = useState(null);
  const [tapeCopyData, setTapCopyData] = useState(null);
  const [previewTapeOpenType, setPreviewTapeOpenType] = useState("asset");
  const tapePreviewHandler = (d, type) => {
    setTapData(d);
    setTapCopyData(d);
    setTapePreviewOpen(true);
    setPreviewTapeOpenType(type);
  };
  const tapePreviewCloseHandler = () => {
    setTapePreviewOpen(false);
    setTapData(null);
    setTapCopyData(null);
    getPortfolioHistoryList();
  };
  const handleClose = () => {
    setShow(false);
    setUploadStepOneDone(false);
    setUploadStepTwoDone(false);
    setUploadStepThreeDone(false);
    setSelectedTapeAsset(null);
    setSuccess(null);
    setError(null);
    setDetailsError(null);
    setErrorResponse(null);
    setMonthEndDateList(null);
    setUploadPercent(0);
  };
  const back = () => {
    if (isUploadStepOneDone && isUploadStepTwoDone && isUploadStepThreeDone) {
      setUploadStepThreeDone(false);
    } else if (isUploadStepOneDone && isUploadStepTwoDone) {
      setUploadStepTwoDone(false);
      setUploadStepOneDone(false);
    }
  };
  const searchDeal = (e) => {
    console.log("e>>", e.target.value);
    setSearchText(e.target.value);
    var search = new RegExp(e.target.value, "gi");
    const tempAssetTapeList =
      assetTapeListCopy.length &&
      assetTapeListCopy.filter((d) => {
        if (d.dealName.match(search)) return d;
      });
    setAssetTapList(tempAssetTapeList);
  };
  const tableSort = (property) => {
    console.log(property);
    setSortType((t) => {
      if (t == "asc") return "desc";
      else return "asc";
    });
    const tempData = sortedByProperty(assetTapeList, property, sortType);
    setAssetTapList(tempData);
  };

  const getPortfolioHistoryList = async () => {
    try {
      setLoading(true);
      let res;
      let temp1;
      if (selectedType == "asset") {
        res = await Network.get(API.assetTapeMonth);
        temp1 = await getTapAssetDetailsFromList(res?.data?.responseData);
      } else if (selectedType === "collection") {
        res = await Network.get(API.collectionTapeMonth);
        temp1 = await getTapCollectionDetailsFromList(res?.data?.responseData);
      } else {
        res = await Network.get(API.uploadTapeMonth);
        temp1 = await getTapDetailsFromList(res?.data?.responseData);
        console.log("all tape>>", res);
        // temp1 = await getTapCollectionDetailsFromList(res?.data?.responseData);
      }
      if (res?.data?.status == "Success")
        // setAssetTapList(res?.data?.responseData);
        setAssetTapListCopy(res?.data?.responseData);
      // const temp2 = await getTapCollectionDetailsFromList(
      //   res?.data?.responseData
      // );
      console.log("101 res", { temp1 });
      setAssetTapList([...temp1]);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (err.response.status == 401) {
        appContext.handleLogout();
      }
    }
  };
  const getTapeAssetDetails = async (data, index) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("getTapeAssetDetails", data, index, data[index].id);
        if (data[index] && data[index]?.id) {
          const res = await Network.get(
            api.assetTapeDetails + "/" + data[index].id
          );
          data[index].assetTapeDetails =
            res?.data?.responseData?.assetTapeDetails;
          data[index].mapping = res?.data?.responseData?.mapping;
          resolve(data);
        } else {
          data[index].assetTapeDetails = [];
          data[index].mapping = null;
          resolve(data);
        }
        // if (res.status === "Success") {
      } catch (err) {
        // reject(err);
        resolve(data);
      }
    });
  };
  const getTapeCollectionDetails = async (data, index) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(data, index, data[index].id);
        if (data[index] && data[index]?.id) {
          const res = await Network.get(
            API.collectionTape + "/" + data[index].id
          );
          data[index].collectionTapeDetails =
            res?.data?.responseData?.collectionTapeDetails;
          data[index].mapping = res?.data?.responseData?.mapping;
          resolve(data);
        } else {
          data[index].collectionTapeDetails = [];
          data[index].mapping = null;
          resolve(data);
        }
        // if (res.status === "Success") {
      } catch (err) {
        resolve(data);
      }
    });
  };
  const getTapAssetDetailsFromList = (data) => {
    return new Promise((resolve, reject) => {
      Promise.all(
        data.map(async (d, i) => {
          return await getTapeAssetDetails(data, i);
        })
      )
        .then((data1) => {
          console.log("all res 1>>", data, data1);
          // setAssetTapList(data);
          resolve(data);
        })
        .catch((err) => resolve(data));
    });
  };
  const getTapCollectionDetailsFromList = (data) => {
    return new Promise((resolve, reject) => {
      Promise.all(
        data.map(async (d, i) => {
          const temp = await getTapeCollectionDetails(data, i);
          return temp;
        })
      )
        .then((data1) => {
          console.log("all res 1>>", data, data1);
          // setAssetTapList(data);
          resolve(data);
        })
        .catch((err) => resolve(data));
    });
  };
  const getTapDetailsFromList = (data) => {
    return new Promise((resolve, reject) => {
      Promise.all(
        data.map(async (d, i) => {
          if (d?.uploadType.trim().toLowerCase() === "asset") {
            return await getTapeAssetDetails(data, i);
          } else {
            return await getTapeCollectionDetails(data, i);
          }
        })
      )
        .then((data1) => {
          console.log("all res 1>>", data, data1);
          // setAssetTapList(data);
          resolve(data);
        })
        .catch((err) => resolve(data));
    });
  };
  const downloadSample = async () => {
    try {
      let res;
      if (uploadType === "asset") res = await Network.get(API.sampleTapFileUrl);
      else res = await Network.get(API.sampleCollectionFileUrl);
      console.log("res>>>", res);
      CSVParser.downloadCSVFile(
        res.data,
        uploadType === "asset"
          ? "SampleAssetTapeFile"
          : "SampleCollectionTapeFile"
      );
    } catch (err) {
      console.log("err>>", err.response);
    }
  };
  const docDownload = async (id, fileName, status) => {
    try {
      let data = [];
      let res;
      // if (status === "asset") {
      res = await Network.get(API.assetTapeDocDownload + "/" + id);
      CSVParser.downloadCSVFile(res.data, fileName);
      // } else {
      //   console.log(id);
      //   CSVParser.downloadCSVFile(id.toString(), fileName);
      // }
    } catch (err) {
      console.log("err>>", err.response);
      alert(err.response?.data?.Message);
    }
  };
  const errorsDownload = () => {
    // console.log("error>>", errorRespose);
    CSVParser.downloadCSV(uploadType === "asset" ? errorRespose : errorRespose);
  };
  const getDeals = async () => {
    try {
      const res = await Network.get(API.getDeals);
      // if (res.status === "Success") {
      setDeals(res?.data?.responseData);

      // }
    } catch (err) {
      console.log("err>>", err.response);
    }
  };
  const getMothlyPeriod = async (dealId) => {
    try {
      setLoading(true);
      let res;
      if (uploadType === "asset") {
        res = await Network.get(API.assetTapeMonth + "?dealId=" + dealId);
      } else {
        res = await Network.get(API.collectionTapeMonth + "?dealId=" + dealId);
      }
      // if (res.status === "Success") {
      setMonthEndDateList(res?.data?.responseData);
      setLoading(false);
      // }
    } catch (err) {
      setLoading(false);
      // if (err.response.status == 401) {
      // 	appContext.handleLogout();
      // }
    }
  };
  const getTapeAsset = async (dealId, date) => {
    try {
      setError(null);
      setDetailsError(null);
      setSuccess(null);
      setLoading(true);
      let res;
      if (uploadType === "asset") {
        res = await Network.get(
          API.assetTapeMonth +
            "?dealID=" +
            dealId +
            "&tapeYear=" +
            dateFormat(date, "YYYY") +
            "&tapeMonth=" +
            dateFormat(date, "MM")
        );
      } else {
        res = await Network.get(
          API.collectionTapeMonth +
            "?dealID=" +
            dealId +
            "&tapeYear=" +
            dateFormat(date, "YYYY") +
            "&tapeMonth=" +
            dateFormat(date, "MM")
        );
      }
      // if (res.status === "Success") {
      const result = res?.data?.responseData;
      if (result.length > 0) {
        setSelectedTapeAsset(result[0]);
        if (result[0].isTapeUploaded) {
          setUploadStepOneDone(true);
        } else {
          setUploadStepOneDone(true);
          setUploadStepTwoDone(true);
        }
      } else {
        // setUploadStepOneDone(true);
        setError(
          "There are no periods associated with the deal. Please try with another date."
        );
      }

      // }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      // if (error.response.status == 401) {
      // 	appContext.handleLogout();
      // }
      console.log("err>>", err.response);
    }
  };

  const onOverwriteFileSelect = async (type) => {
    if (type) {
      setUploadStepTwoDone(true);
      setFileOverwrite(true);
    } else {
      setUploadStepTwoDone(false);
      setFileOverwrite(false);
      setUploadStepOneDone(false);
    }
  };
  const parseFile = async (e) => {
    try {
      // setUploadedFile(e.target.files[0]);
      // const result = await CSVParser.parse(e.target.files[0]);
      setUploadedFile(e);
      const result = await CSVParser.parse(e);
      console.log("101 parse data", result);
      setFileParseData(result);
    } catch (err) {
      console.log("file parse err>>", err);
    }
  };
  const uploadFile = async () => {
    setUploadStepThreeDone(true);
    setShow(false);
    setUploadStepOneDone(false);
    setUploadStepTwoDone(false);
    setUploadStepThreeDone(false);
    handleShowb();

    getMappingData();
  };
  const getMappingData = async () => {
    try {
      setLoading(true);
      let res;
      if (uploadType === "asset") res = await Network.get(API.assetTapeMapping);
      else res = await Network.get(API.collectionTapeMapping);
      setMappingFieldData(res.data.responseData);
      //   console.log("11 res>", res.data.responseData);
      initialData(res.data.responseData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("mapping data get error:", err);
      // if (err.response.status == 401) {
      // 	appContext.handleLogout();
      // }
    }
  };
  const initialData = (mapData) => {
    const tempMatch = [];
    mapData &&
      mapData.length > 0 &&
      mapData.map((m, index) => {
        mapData[index].mappingField = null;
        fileParseData &&
          fileParseData[0] &&
          Object.keys(fileParseData[0]).map((k) => {
            // if (titleToCamel(k).toLowerCase() == m.fieldName.toLowerCase()) {
            if (
              k.trim().toLowerCase() == m.fieldDisplayName.trim().toLowerCase()
            ) {
              if (
                !tempMatch.includes(m.fieldDisplayName.trim().toLowerCase())
              ) {
                tempMatch.push(m.fieldDisplayName.trim());
              }
              return (mapData[index].mappingField = k);
            }
          });
      });
    console.log("101  ", fileParseData[0], mapData, tempMatch);

    setMappingData(mapData);
    setMachingData(tempMatch);
  };
  const submitTape = async (e) => {
    e.preventDefault();
    setUploadPercent(0);
    setError(null);
    setSuccess(null);
    setDetailsError(null);

    setErrorResponse(null);
    let arr = [];

    const duplicateElementa = toFindDuplicates(mappingData, "mappingField");
    console.log("duplicateElementa>> ", duplicateElementa);

    mappingData.map((k) =>
      arr.push({ columnName: k.mappingField, PropertyName: k.fieldName })
    );
    // Object.keys(mappingData).map((k) =>
    //   arr.push({ columnName: mappingData[k], PropertyName: k })
    // );

    let frmData = new FormData();
    frmData.append("file", uploadedFile);
    frmData.append("dealId", selectedTapeAsset?.dealID);
    frmData.append(
      "tapeMonth",
      dateFormat(selectedTapeAsset?.tapeMonthEndDate, "MM")
    );
    frmData.append(
      "tapeYear",
      dateFormat(selectedTapeAsset?.tapeMonthEndDate, "YYYY")
    );
    frmData.append("mapping", JSON.stringify(arr));
    try {
      let res;
      if (uploadType == "asset") {
        const config = {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            setUploadPercent(percent);
            console.log(" 101 onUploadProgress", progressEvent.loaded, percent);
          },
        };
        res = await Network.post(API.assetTape, frmData, config);
        //   console.log("post res>>", res);
        setSuccess("Data has been successfully uploaded");
        setTimeout(() => {
          handleCloseb();
          console.log("101 res>>>", res);
          const { batchDetails } = res?.data?.responseData;
          tapePreviewHandler(batchDetails, uploadType);
          // getPortfolioHistoryList();
        }, 1000);
      } else {
        const config = {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            setUploadPercent(percent);
            console.log(" 101 onUploadProgress", progressEvent.loaded, percent);
          },
        };
        res = await Network.post(API.collectionTape, frmData, config);
        setSuccess("Data has been successfully uploaded");
        console.log("102 res", res);

        setTimeout(() => {
          handleCloseb();
          const { batchDetails } = res?.data?.responseData;
          tapePreviewHandler(batchDetails, uploadType);
          // getPortfolioHistoryList();
        }, 1000);
      }
    } catch (err) {
      console.log("post err>>", err, err.response);

      if (err.response.status == 401) {
        appContext.handleLogout();
      }
      if (err.response?.data?.responseData) {
        setError("Some fields are missing.");
        setDetailsError(true);
        console.log(
          "101 uploadErrors",
          err.response?.data?.responseData?.uploadErrors
        );
        setErrorResponse(err.response?.data?.responseData?.uploadErrors);
      }
      if (err.response?.data?.Message) {
        setError(err.response?.data?.Message);
        setDetailsError(null);

        setErrorResponse([]);
      }
    }
  };
  useEffect(() => {
    getPortfolioHistoryList();
    getDeals();
  }, []);
  useEffect(() => {
    getPortfolioHistoryList();
    //  getDeals();
  }, [selectedType]);
  // useEffect(() => {
  // 	searchHandler();
  // }, [search]);

  return (
    <>
      <SideBar />
      <div id="main_wraper" className="">
        <AamsHeader />

        <div id="engage_list_page">
          <div className="clearfix"></div>
          <MyBreadcrumb items={breadcrumbs} />
          {/* <Button
						className="btn btn-outline-primary"
						variant="primary"
						onClick={handleShow}
					>
						Add <i className=" fa  fa-arrow-circle-right"></i>
					</Button> */}

          <ErrorModal
            showc={showc}
            handleClosec={handleClosec}
            data={uploadType === "asset" ? errorRespose : errorRespose}
            errorsDownload={errorsDownload}
            uploadType={uploadType}
          />

          {/* <Button
						className="btn btn-outline-primary"
						variant="primary"
						onClick={handleShowb}
					>
						Upload Tape <i className=" fa  fa-arrow-circle-right"></i>
					</Button> */}
          <FileUploadModal
            show={show}
            handleClose={handleClose}
            back={back}
            isUploadStepOneDone={isUploadStepOneDone}
            isUploadStepTwoDone={isUploadStepTwoDone}
            isUploadStepThreeDone={isUploadStepThreeDone}
            deals={deals}
            getMothlyPeriod={getMothlyPeriod}
            monthEndDateList={monthEndDateList}
            getTapeAsset={getTapeAsset}
            selectedTapeAsset={selectedTapeAsset}
            onOverwriteFileSelect={onOverwriteFileSelect}
            parseFile={parseFile}
            uploadFile={uploadFile}
            success={success}
            error={error}
            isDetailsError={isDetailsError}
            downloadSample={downloadSample}
            uploadType={uploadType}
            setUploadType={setUploadType}
          />
          <FieldMappingModal
            showb={showb}
            handleCloseb={handleCloseb}
            mappingFieldData={mappingFieldData}
            fileParseHeaderData={
              fileParseData && fileParseData.length && fileParseData[0]
            }
            mappingData={mappingData}
            machingData={machingData}
            setMachingData={setMachingData}
            submitTape={submitTape}
            success={success}
            error={error}
            isDetailsError={isDetailsError}
            handleShowc={handleShowc}
            setMappingData={setMappingData}
            uploadPercent={uploadPercent}
          />
          <div className="clearfix"></div>
          <div
            class="row justify-content-between"
            style={{
              paddingLeft: 0,
              marginLeft: 0,
              marginRight: 0,
              paddingRight: 0,
              marginBottom: 10,
            }}
          >
            {/* <div class="col-md-2" style={{ padding: 0, margin: 0 }}>
							<div class="Option">
								<select
									name="optionSelect"
									class="form-select bg-transparent text-light"
								>
									<option value={true}>Active</option>
									<option value={false}>Inactive</option>
								</select>
							</div>
						</div> */}
            <div class="col-md-3" style={{ padding: 0, margin: 0 }}>
              {/* <div class="Option"> */}
              <Button
                className="btn btn-outline-primary "
                variant="primary"
                onClick={handleShow}
              >
                Add <i className=" fa  fa-arrow-circle-right"></i>
              </Button>
              {/* </div> */}
            </div>
            <div class="col-md-4 text-center"></div>
            <div class="col-md-3 p-0">
              <div class="row">
                <label class="col-lg-4 col-xs-4" style={{ marginTop: 20 }}>
                  Select Type
                </label>
                <div class="col-lg-8 col-xs-8">
                  <select
                    class="form-control bg-transparent text-light"
                    value={selectedType}
                    onChange={(e) => setSelectedType(e?.target?.value)}
                    style={{ marginTop: 10 }}
                  >
                    <option disabled>Select</option>
                    <option value="all">All</option>
                    <option value="asset">Asset Tape</option>
                    <option value="collection">Collection Tape</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-2" style={{ padding: 0, margin: 0 }}>
              <div class="search">
                <input
                  type="text"
                  class="form-control bg-transparent text-light"
                  placeholder="Search by deal name..."
                  value={searchText}
                  onChange={searchDeal}
                  style={{ marginTop: 10 }}
                />
              </div>
            </div>
          </div>
          <List
            data={assetTapeList}
            docDownload={docDownload}
            tableSort={tableSort}
            getTapeAssetDetails={getTapeAssetDetails}
            selectedType={selectedType}
            tapePreviewHandler={tapePreviewHandler}
          />
          <TapListModal
            showb={isTapePreviewOpen}
            handleCloseb={tapePreviewCloseHandler}
            data={tapeData}
            copydata={tapeCopyData}
            setData={setTapData}
            selectedType={previewTapeOpenType}
          />
        </div>

        <AamsFooter />
        <Loader isLoading={isLoading} />
      </div>
    </>
  );
}

export default PortfolioList;
