import AamsHeader from "components/AamsHeader";
import SideBar from "components/SideBar";
import React from "react";
import "./ErrorPage.scss";
import { getErrorMessage } from "helpers/utils";

export default function ErrorPage() {
  return (
    <>
      <div id="main_wraper" className="main_wraper_over">
        <AamsHeader />
        <div id="deal_listing_page">
          <div className="clent-box-row d-flex justify-content-center align-items-center">
            <div class="col-sm-6 ">
              <div class="alert alert-danger">{getErrorMessage()}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
