import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// pages for this kit
import Index from "views/Index.js";
import Dashboard from "views/dashboard";
import DealList from "views/deal-listing.js";
import AddDeals from "views/add-deals.js";
import Engagelist from "views/engage-list.js";
import EngagementDetails from "views/EngagementDetails";
import DealEngagement from "views/DealEngagement/index.js";
import Blankpage from "views/blankPage.js";
import Dragpage from "components/dnd.js";
import LoginPage from "views/login";
import PrivateRoute from "PrivateRoute";
import PublicRoute from "PublicRoute";
import PortfolioList from "views/PortfolioHistory/index.js";
import ErrorPage from "views/ErrorPage/ErrorPage";

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Switch>
          <PublicRoute path="/" component={LoginPage} restricted={true} exact />
          {/* <PrivateRoute path="/index" component={Blankpage} /> */}
          <PublicRoute path="/error" component={ErrorPage} exact />

          <PrivateRoute path="/dashboard" component={Blankpage} exact />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/deal-Listing`}
            component={DealList}
            exact
          />

          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/engage-list`}
            component={Engagelist}
            exact
          />

          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/add-deals`}
            component={AddDeals}
            exact
          />

          <PrivateRoute
            path="/engagement-details"
            // path="/engagement-details/:id/:periodid"
            component={EngagementDetails}
            exact
          />

          <PrivateRoute
            path="/deal-engagement"
            component={DealEngagement}
            exact
          />

          <PrivateRoute
            path="/portfolio-history"
            component={PortfolioList}
            exact
          />
          <PrivateRoute path="/drag" component={Dragpage} exact />

          {/* <Redirect to="/index" /> */}
          {/* <Redirect from="/" to="/index" /> */}
        </Switch>
      </Switch>
    </BrowserRouter>
  );
}
