import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import homeReducer from "./homeReducer";

const appReducer = combineReducers({ home: homeReducer });
const rootReducer = (state, action) => {
	if (action.type === "USER_LOGOUT") {
		storage.removeItem("persist:root");
		return appReducer(undefined, action);
	}

	return appReducer(state, action);
};
export default rootReducer;
