import React from "react";

// reactstrap components
import {
 // Button,
  Card,
 CardBody,
 CardTitle,
} from "reactstrap";

// core components
import SideBar from "components/SideBar.js";
import AamsHeader from "components/AamsHeader.js";
import AamsFooter from "components/AamsFooter.js";

function Dashboard() {
  return (
    <>
      <SideBar />
      <div id="main_wraper">
        <AamsHeader />
     

        <div id="dash_board_page">

<div className="row">

<div className="col-sm-8">
  <Card >
    <CardBody className="p-0">
    <CardTitle tag="h4">Deal Overview</CardTitle>
    <div className="table-responsive">
    <img
      alt="..."
      src={require("assets/images/bar_chart.png").default}
      ></img>                     
    </div>
    </CardBody>
  </Card>
</div>
<div className="col-sm-4">
<Card >
    <CardBody className="p-0">
    <CardTitle tag="h4">Deals</CardTitle>
    <div className="table-responsive text-center">
    <img
      alt="..."
      src={require("assets/images/round_chart.png").default}
      ></img>                  
    </div>
    </CardBody>
  </Card>
</div>
<div className="col-sm-4">
<Card >
    <CardBody className="p-0 ">
    <CardTitle tag="h4">Deals Update </CardTitle>
  

<div className="d-flex justify-content-between align-items-center p-2 deal_update">
        <div >
        <h5 className="m-0 mt-2">20158</h5>
        <p>Total Deals</p>
        </div>
        <div >
        <i className="fas fa-level-down-alt"></i>
        <span>20% Higher</span>
        </div>  
</div>




<div className="d-flex justify-content-between align-items-center p-2 deal_update">
<div>
<h5 className="m-0">2015</h5>
<p>Running Deals</p>
</div>
<div>
<i className="fas fa-level-down-alt"></i>
<span>20% Higher</span>
</div>  
</div>  

<div className="d-flex justify-content-between align-items-center  p-2 deal_update">
<div>
<h5 className="m-0">20158</h5>
<p>Closed Deals</p>
</div>
<div>
<i className="fas fa-level-up-alt"></i>
<span>20% Higher</span>
</div>                                           
</div>                                    



<div className="d-flex justify-content-between align-items-center  p-2 deal_update">
<div >
<h5 className="m-0">20158</h5>
<p>Defaulted Deals</p>
</div>
<div >
<i className="fas fa-level-up-alt"></i>
<span>20% Higher</span>
</div>
</div>



                                           
    </CardBody>
  </Card>
</div>
<div className="col-sm-8">
<Card >
    <CardBody className="p-0">
    <CardTitle tag="h4">Recent Activities </CardTitle>
    <div className="table-responsive">
    <table className="table table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Borrower Name</th>
                                                    <th>Deal Name </th>
                                                    <th>Manager</th>
                                                    <th>Lender</th>
                                                    <th>Agent</th>
                                                    <th>Services</th>
                                                    <th>Deal Size</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-bold-500">Angelina Vitale</td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td><span className="table-amout">$ 946 </span> </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-bold-500">Angelina Vitale</td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td><span className="table-amout">$ 946 </span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-bold-500">Angelina Vitale</td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td><span className="table-amout">$ 946 </span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-bold-500">Angelina Vitale</td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td><span className="table-amout">$ 946 </span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-bold-500">Angelina Vitale</td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td>Test </td>
                                                    <td><span className="table-amout">$ 946 </span></td>
                                                </tr>
                                            </tbody>
                                        </table>   
    </div>
    </CardBody>
  </Card>
</div>

</div>

</div>




        <AamsFooter />
     </div>
    </>
  );
}

export default Dashboard;
