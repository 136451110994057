import { useState, useEffect } from "react";
import { config } from "../../constant";
import axios from "axios";
import { Field, Formik } from "formik";
import * as yup from "yup";
import Loader from "../Loader";
import { sortByDate } from "helpers/data";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ConvenantItem from "./ConvenantItem";
import api from "config/api";
import Toaster from "components/Toaster";

const getCovenantTypeUrl = config.url.API_URL + "covenant-type";
const getCovenantCategoryUrl = config.url.API_URL + "covenant-category";
const getVerificationSrcUrl = config.url.API_URL + "verification-source";
const getValueTypeUrl = config.url.API_URL + "value-type";
const postCovenantUrl = config.url.API_URL + "covenant";
const getCovenantUrl = config.url.API_URL + "covenant";

const initialValues = {
  name: "",
  description: "",
  covenantTypeID: "",
  covenantCategoryID: "",
  verificationSourceID: "",
  dataValueTypeID: "",
  value: "",
};

function AmssCovenant(props) {
  let [showstat, setShowsStat] = useState(0);
  const [covenants, setCovenants] = useState([]);
  const [covenantTypes, setCovenantTypes] = useState([]);
  const [covenantCategories, setCovenantCategories] = useState([]);
  const [verificationSrc, setVerificationSrc] = useState([]);
  const [valueTypes, setValueTypes] = useState([]);
  const [values, setValues] = useState(initialValues);
  const [isEdit, setEdit] = useState(false);
  const [selectedId, setSeletedId] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState("");
  const assetVerificationValidationSchema = yup.object().shape({
    covenantCategoryID: yup
      .string()
      .required("Convenant category is a required field"),
    name: yup.string().required("Name is a required field"),
    value: yup.string(),
    // dataValueTypeID: yup.string().required("Value type is a required field"),
    verificationSource: yup
      .string()
      .required("Verification source is a required field"),
    description: yup.string().required("Description is a required field"),
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    // console.log(values);
  };

  const getCovenantsWithAxios = async () => {
    // setLoading(true);
    const respCovenants = await axios.get(
      getCovenantUrl + "?EngagementID=" + props.engagement
    );
    // setLoading(false);
    console.log("101 respCovenants>>", respCovenants);
    const covs = respCovenants.data.responseData.sort(sortByDate);
    setCovenants(covs);
    //console.log(posts);
  };

  const getCovenantTypesWithAxios = async () => {
    const covenantTypesData = await axios.get(getCovenantTypeUrl);
    // console.log(covenantTypesData.data.responseData);
    let types = covenantTypesData.data.responseData;
    setCovenantTypes(types);
  };

  const getCovenantCategoriesWithAxios = async () => {
    const covenantCategoriesData = await axios.get(getCovenantCategoryUrl);
    // console.log(covenantCategoriesData.data.responseData);
    let categories = covenantCategoriesData.data.responseData;
    setCovenantCategories(categories);
  };

  const getVerificationSrcWithAxios = async () => {
    const verificationSrcData = await axios.get(getVerificationSrcUrl);
    // console.log(verificationSrcData.data.responseData);
    let verification = verificationSrcData.data.responseData;
    setVerificationSrc(verification);
  };

  const getValueTypesWithAxios = async () => {
    const valueTypesData = await axios.get(getValueTypeUrl);
    // console.log(valueTypesData.data.responseData);
    let valuet = valueTypesData.data.responseData;
    setValueTypes(valuet);
  };

  const addNewAttribute = () => {
    setShowsStat(1);
    getCovenantTypesWithAxios();
    getCovenantCategoriesWithAxios();
    getVerificationSrcWithAxios();
    getValueTypesWithAxios();
  };
  const backCovenantList = () => {
    setShowsStat(0);
  };
  const saveCovenantAttribute = (values, resetForm) => {
    // e.preventDefault();
    setShowsStat(0);
    setLoading(true);
    //engagementID: props.engagement, name: values.name, description: values.description, covenantTypeID: values.covenantTypeID, covenantCategoryID: values.covenantCategoryID, verificationSourceID: values.verificationSourceID, dataValueTypeID: values.dataValueTypeID, value: values.value
    let dataArr = {
      engagementID: props.engagement,
      name: values.name,
      description: values.description,
      covenantTypeID: values.covenantTypeID,
      covenantCategoryID: values.covenantCategoryID,
      verificationSource: values.verificationSource,
      dataValueTypeID: values.dataValueTypeID,
      value: values.value,
    };
    // console.log("balues>>>", dataArr); return;
    setError(false);
    setMessage("");
    const headers = {
      "content-type": "application/json",
    };
    if (isEdit) {
      dataArr.id = selectedId.id;
      axios
        .put(postCovenantUrl, dataArr, { headers })
        .then((covenantresp) => {
          // console.log(covenantresp);
          setLoading(false);
          if (covenantresp.data.status) {
            setValues({ ...initialValues });
            getCovenantTypesWithAxios();
            getCovenantCategoriesWithAxios();
            getVerificationSrcWithAxios();
            getValueTypesWithAxios();
            getCovenantsWithAxios();
            setShowsStat(0);
            setEdit(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
          setMessage(error?.response?.Message);
          alert(error?.response?.data?.Message);
          // error response
          console.log("error>>", error?.response);
        });
    } else {
      setLoading(true);
      axios
        .post(postCovenantUrl, dataArr, { headers })
        .then((covenantresp) => {
          setLoading(false);
          // console.log(covenantresp);
          if (covenantresp.data.status) {
            setValues({ ...initialValues });
            getCovenantTypesWithAxios();
            getCovenantCategoriesWithAxios();
            getVerificationSrcWithAxios();
            getValueTypesWithAxios();
            getCovenantsWithAxios();
            setShowsStat(0);
            setEdit(false);
          }
        })
        .catch((error) => {
          // setError(true);
          // setMessage(error?.response?.message);
          setLoading(false);
          // // error response
          alert(error?.response?.data?.Message);
          console.log("error>>", error?.response);
        });
    }
  };

  const reorderCovenantTracking = async () => {
    let updateConvenantReviews = [];
    const engagementID = props.engagement;
    updateConvenantReviews = covenants.map((g) => g?.id);
    // .filter((gg) => gg);
    const headers = {
      "content-type": "application/json",
    };
    setLoading(true);
    axios
      .put(
        api.conventReviewReorder,
        {
          engagementID: engagementID,
          orderedCovenantIDs: updateConvenantReviews,
        },
        {
          headers,
        }
      )
      .then((resp) => {
        setLoading(false);
        // props.getEngagementsWithAxios();
        // setAssetVer(()=>avsresp.data.responseData?.id);
        // console.log("resp>>", avsresp, avsresp.data.responseData?.id, assetVer);
        // getCovenantReviewWithAxios();
        //alert('Successfully created AVS Setup');
      })
      .catch((error) => {
        setLoading(false);
        // error response
      });
  };
  useEffect(() => {
    getCovenantTypesWithAxios();
    getCovenantCategoriesWithAxios();
    getVerificationSrcWithAxios();
    getValueTypesWithAxios();
    getCovenantsWithAxios();
  }, [props]);

  const onEdit = (data) => {
    setShowsStat(1);
    setEdit(true);
    setSeletedId(data);
    // const initialValues = {
    //   name: data.name,
    //   description: data.description,
    //   covenantTypeID: data.covenantTypeID,
    //   covenantCategoryID: data.covenantCategoryID,
    //   verificationSource: data.verificationSource,
    //   dataValueTypeID: data.dataValueTypeID,
    //   value: data.value,
    // };
    // setValues(() => initialValues);
  };
  return (
    <>
      <div
        className={
          showstat === 1
            ? ""
            : "avs-setup d-flex justify-content-between align-items-center"
        }
        style={showstat === 1 ? { display: "none" } : { display: "block" }}
      >
        Covenant Tracking checklists
        <div>
          <button
            onClick={addNewAttribute}
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title=""
            data-bs-original-title="Add New Covenant Checklist Item"
            aria-label="Add New Covenant Checklist Item"
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <Formik
        onSubmit={(values, { resetForm }) => {
          saveCovenantAttribute(values, resetForm);
          // resetForm();
        }}
        initialValues={{
          covenantCategoryID: isEdit ? selectedId.covenantCategoryID : "",
          name: isEdit ? selectedId.name : "",
          verificationSource: isEdit ? selectedId.verificationSource : "",
          // dataValueTypeID: isEdit ? selectedId.dataValueTypeID : "",
          value: isEdit ? selectedId.value : "",
          description: isEdit ? selectedId.description : "",
        }}
        enableReinitialize={true}
        validationSchema={assetVerificationValidationSchema}
      >
        {({
          handleChange,
          handleSubmit,
          touched,
          values,
          errors,
          resetForm,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="form_holder p-0"
            style={showstat === 0 ? { display: "none" } : { display: "block" }}
          >
            {JSON.stringify(errors)}
            <div className="avs-setup mb-2 d-flex justify-content-between">
              {" "}
              Attributes
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="row inner-accordian-form-sec">
                  <div className="col-md-4">
                    <div className="form-group mb-2">
                      <label htmlFor="basicInput">
                        Covenant Category <span className="text-danger">*</span>
                      </label>
                      <Field
                        as="select"
                        className="form-select  bg-transparent text-light"
                        aria-label="Default select example"
                        name="covenantCategoryID"
                        // value={values.covenantCategoryID}
                        // onChange={handleInputChange}
                        // required
                      >
                        <option value="">Select</option>
                        {covenantCategories &&
                          covenantCategories.map((covenantCategory, cc) => {
                            return (
                              <option key={cc} value={covenantCategory.id}>
                                {covenantCategory.name}
                              </option>
                            );
                          })}
                      </Field>
                      {errors.covenantCategoryID &&
                      touched.covenantCategoryID ? (
                        <div class="text-danger">
                          {errors.covenantCategoryID}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-2">
                      <label htmlFor="basicInput">
                        Covenant Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control  bg-transparent text-light"
                        id="name"
                        name="name"
                        // value={values.name}
                        // onChange={handleInputChange}
                        placeholder="Covenant Name"
                        // required
                      />
                      {errors.name && touched.name ? (
                        <div class="text-danger">{errors.name}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group mb-2">
                      <label htmlFor="basicInput">
                        Source Name <span className="text-danger">*</span>
                      </label>

                      <Field
                        type="text"
                        className="form-control  bg-transparent text-light"
                        id="verificationSource"
                        name="verificationSource"
                        // value={values.verificationSource}
                        // onChange={handleInputChange}
                        placeholder="Source Name"
                        // required
                      />
                      {errors.verificationSource &&
                      touched.verificationSource ? (
                        <div class="text-danger">
                          {errors.verificationSource}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* <div className="col-md-3">
                    <div className="form-group mb-2">
                      <label htmlFor="basicInput">
                        Value to compare <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        className="form-control  bg-transparent text-light"
                        id="value"
                        name="value"
                        // value={values.value}
                        // onChange={handleInputChange}
                        placeholder="compare value"
                        // required
                      />
                      {errors.value && touched.value ? (
                        <div class="text-danger">{errors.value}</div>
                      ) : null}
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <label htmlFor="">
                      Description <span className="text-danger">*</span>
                    </label>
                    <Field
                      as="textarea"
                      className="form-control text-area-inner  bg-transparent text-light"
                      id="description"
                      name="description"
                      // value={values.description}
                      // onChange={handleInputChange}
                      rows="3"
                      // required
                    />
                    {errors.description && touched.description ? (
                      <div class="text-danger">{errors.description}</div>
                    ) : null}
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group mb-2">
                      <label htmlFor="basicInput">
                        Value type <span className="text-danger">*</span>
                      </label>
                      <Field
                        as="select"
                        className="form-select  bg-transparent text-light"
                        id="dataValueTypeID"
                        name="dataValueTypeID"
                      >
                        <option value="">Select</option>
                        {valueTypes &&
                          valueTypes.map((valueType, vt) => {
                            return (
                              <option key={vt} value={valueType.id}>
                                {valueType.name}
                              </option>
                            );
                          })}
                      </Field>
                      {errors.dataValueTypeID && touched.dataValueTypeID ? (
                        <div class="text-danger">{errors.dataValueTypeID}</div>
                      ) : null}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div>
              <button
                className="btn btn-primary"
                color="primary"
                onClick={() => {
                  backCovenantList();
                  resetForm();
                }}
                title="Back"
              >
                <i class="fas fa-arrow-left"></i>
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                color="primary"
                title="Save"
              >
                <i class="fas fa-save"></i>
              </button>
            </div>
          </form>
        )}
      </Formik>
      <div
        className="covenant-table"
        id="covenantTable"
        style={showstat === 1 ? { display: "block" } : { display: "block" }}
      >
        <ConvenantItem
          covenants={covenants}
          setCovenants={setCovenants}
          onEdit={onEdit}
          submit={reorderCovenantTracking}
        />
      </div>
      <Loader isLoading={isLoading} />
      <Toaster show={isError} message={message} />
    </>
  );
}
export default AmssCovenant;
