import React, { createContext } from "react";
import Router from "./Router";
import { MsalProvider } from "@azure/msal-react";
import { logout } from "helpers/utils";
import { useDispatch } from "react-redux";
import { handleAzureLogout } from "components/AzureButton";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";
export const AppContext = createContext({});

export default function App({ msalInstance }) {
  const dispatch = useDispatch();
  const handleLogout = async (mode = null) => {
    console.log("101 log mode>>", mode);
    if (mode !== "logout") {
      alert("Your session has timed out due to inactivity");
    }
    await handleAzureLogout(msalInstance);

    await logout();

    dispatch({
      type: "USER_LOGOUT",
    });
  };
  const { configData } = window;
  return (
    <AppContext.Provider value={{ handleLogout, configData }}>
      <CacheBuster
        currentVersion={version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={"."}
      >
        <MsalProvider instance={msalInstance}>
          <Router />
        </MsalProvider>
      </CacheBuster>
    </AppContext.Provider>
  );
}
