import { useState, useEffect } from "react";
import { config } from "../../constant";
import axios from "axios";
import { Field, FieldArray, Formik } from "formik";
import Loader from "../Loader";
import { sortByDate } from "helpers/data";
import "./PeriodSummaryReport.scss";
const getSummaryReportUrl =
  config.url.API_URL + "asset-verification-attribute-review";

function PeriodSummaryReport({
  generateReport,
  summaryReports,
  saveNotes,
  periodDetails,
}) {
  let [showstat, setShowsStat] = useState(0);

  const openNotes = () => {
    setShowsStat(1);
  };
  return (
    <>
      <h1 className="mt-2 mb-2">
        Period Summary Report{" "}
        <button title="Generate Report" onClick={() => generateReport()}>
          <i class="fa fa-solid fa-chart-bar"></i>
        </button>
      </h1>
      <Formik
        initialValues={{
          attributes:
            summaryReports.length &&
            summaryReports.map((s) => {
              return { ...s, isEdit: false };
            }),
        }}
        onSubmit={(values) => saveNotes(values)}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="table-responsive">
              <table className="table table-bordered text-light mb-0">
                <thead className="table-dark">
                  <tr>
                    <th width="150">Group</th>
                    <th width="250">Attribute Name</th>
                    <th width="200">Total Tested</th>
                    <th width="150"># Passed</th>
                    <th width="100"># Failed</th>
                    <th width="25%">Exception Description </th>
                    <th width="25%">Remediation Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <FieldArray
                    name="attributes"
                    render={(arrayHelpers) => (
                      <>
                        {values.attributes &&
                          values.attributes.length > 0 &&
                          values.attributes.map((attr, i) => (
                            <>
                              {/* {summaryReports &&
								summaryReports.map((summaryReport, sr) => {
									return ( */}
                              <tr key={"attr" + i}>
                                <td>
                                  {
                                    attr?.assetVerificationAttribute
                                      ?.assetVerificationAttributeGroupName
                                  }
                                </td>
                                <td>
                                  {attr?.assetVerificationAttribute?.name}
                                </td>
                                <td>{attr.testedTotal}</td>
                                <td>{attr.testedPassed}</td>
                                <td>{attr.testedFailed}</td>
                                <td>
                                  {/* {!attr.isEdit ? (
                                  <>
                                    <div className="avs_text">
                                      {attr?.mitigationPlan}
                                    </div>
                                  </>
                                ) : (
                                  <> */}
                                  <div id="avs_textfild">
                                    <Field
                                      as="textarea"
                                      class="form-control"
                                      id=""
                                      rows="1"
                                      name={`attributes[${i}].mitigationPlan`}
                                    />
                                  </div>
                                  {/* </>
                                )} */}
                                </td>
                                <td>
                                  {/* {!attr.isEdit ? (
                                  <> */}
                                  {/* <button
                                      id="avs_note_edit"
                                      className="avs_note_edit btn btn-sm btn-link"
                                      onClick={() => {
                                        setFieldValue(
                                          `attributes[${i}].isEdit`,
                                          !attr.isEdit
                                        );
                                      }}
                                    >
                                      {" "}
                                      <i className="fas fa-pen-square"></i>{" "}
                                    </button> */}
                                  {/* <div className="avs_text">{attr?.notes}</div> */}
                                  {/* </>
                                ) : (
                                  <> */}
                                  {/* <button
                                      id="avs_note_edit"
                                      className="avs_note_edit btn btn-sm btn-link"
                                      onClick={() => {
                                        setFieldValue(
                                          `attributes[${i}].isEdit`,
                                          !attr.isEdit
                                        );
                                        saveNotes(values.attributes[i]);
                                      }}
                                    >
                                      {" "}
                                      <i className="fas fa-save"></i>{" "}
                                    </button> */}
                                  <div id="avs_textfild">
                                    <Field
                                      as="textarea"
                                      class="form-control"
                                      id=""
                                      rows="1"
                                      name={`attributes[${i}].notes`}
                                    />
                                  </div>
                                  {/* </>
                                )} */}
                                </td>
                              </tr>

                              {/* })} */}
                            </>
                          ))}
                      </>
                    )}
                  />
                </tbody>
              </table>
              {periodDetails?.statusCode !== "CLOSED" && (
                <div className="d-flex float-right">
                  <button class="btn btn-primary p-2">Save</button>
                </div>
              )}
            </div>
          </form>
        )}
      </Formik>
      {/* {isLoading && <Loader isLoading={isLoading} />} */}
    </>
  );
}
export default PeriodSummaryReport;
