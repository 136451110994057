import React, { useState, useEffect, useContext } from "react";
import { config } from "../../constant";
import axios from "axios";
import { Formik, Field, FieldArray } from "formik";
import queryString from "query-string";

// reactstrap components
import { Button } from "reactstrap";

// core components
import SideBar from "components/SideBar.js";
import AamsHeader from "components/AamsHeader.js";
import AamsFooter from "components/AamsFooter.js";
import PeriodSummaryReport from "components/PeriodSummaryReport/PeriodSummaryReport.js";
import { Modal, Spinner } from "react-bootstrap";
import { attributes } from "plotly.js/lib/bar";
import Loader from "components/Loader";
import { axiosAuthHeaderSet } from "helpers/utils";
import Toaster from "components/Toaster";
import ConventReviewForm from "./ConventReviewForm";
import { sortByDate } from "helpers/data";
import api from "config/api";
import { AppContext } from "./../../App";
import MyBreadcrumb from "components/MyBreadcrumb/MyBreadcrumb";

const getEngagementDetailsUrl = config.url.API_URL + "engagement/";
const getPeriodDetailsUrl = config.url.API_URL + "period/";
const getDealDetailsUrl = config.url.API_URL + "deal/";
const getResultUrl = config.url.API_URL + "result";
const getCovenantReviewUrl = config.url.API_URL + "covenant-review";
const postCovenantReviewUrl = config.url.API_URL + "covenant-review";
const getAssetVerificationUrl = config.url.API_URL + "asset-verification";
const documentManageUrl = config.url.API_URL + "document";
const decumentDownloadUrl = config.url.API_URL + "document-download";
const assetVerificationReviewUrl =
  config.url.API_URL + "asset-verification-review";
const assetVerificationAssetAttributeReviewsUrl =
  config.url.API_URL + "asset-verification-asset-attribute-reviews";
const getSummaryReportUrl =
  config.url.API_URL + "asset-verification-attribute-review";

const covRevValues = {
  resultID: "",
  note: "",
};

function EngagementDetails(props) {
  const [dealDetails, setDealDetails] = useState([]);
  const [engagementDetails, setEngagementDetails] = useState([]);
  const [assetVerification, setAssetVerification] = useState([]);
  const [avsReviews, setAvsReview] = useState([]);
  const [avsReviewsNew, setAvsReviewNew] = useState(null);
  const [selectedAvsItem, setSelectedAvsItem] = useState(null);
  const [isOpenAvsItem, setOpenAvsItem] = useState(false);
  const [selectedReviewItem, setSelectedReviewItem] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [values, setValues] = useState(null);
  const [attributeValues, setAttributeValues] = useState(null);
  const [avsDocuments, setAvsDocuments] = useState([]);
  const [summaryReports, setSummaryReports] = useState([]);

  const [covenantReviews, setCovenantReviews] = useState([]);
  const [covValues, setCovValues] = useState(covRevValues);
  const [isLoading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState("info");
  const [isAvsReviewError, setAvsReviewError] = useState(null);
  const [params, setParams] = useState(null);
  const breadcrumbs = [
    {
      op: "Home",
      link: "/",
    },
    {
      op: "Engagements",
      link: "/engage-list",
    },
    {
      op: "Engagement details",
      link: "",
    },
  ];

  const appContext = useContext(AppContext);
  const [editAttrbutesReview, setEditAttributesReview] = useState(false);
  const getCovenantReviewsWithAxios = async () => {
    try {
      const covresp = await axios.get(
        getCovenantReviewUrl + "?PeriodID=" + params.periodid
      );
      //console.log(resp.data.responseData);
      const covrev = covresp.data.responseData;
      setCovenantReviews(covrev);
      console.log(covrev);
    } catch (error) {
      // setAlertType("danger");
      // console.log("error>>", error);
      // setShowAlert(true);
      // setAlertMessage(error.response?.data?.Message);
    }
  };
  const initialAvsReview = {
    periodID: "",
    assetVerificationID: "",
    assetIdentifier: "",
    assetName: "",
  };
  const getSummaryReportWithAxios = async () => {
    try {
      const respsumrep = await axios.get(
        getSummaryReportUrl + "?PeriodID=" + params.periodid
      );
      console.log(
        "101 getSummaryReportWithAxios>>",
        respsumrep.data.responseData
      );
      const rep = respsumrep.data.responseData.sort(sortByDate);
      setSummaryReports(rep);
      //console.log(posts);
    } catch (err) {
      console.log("error in getSummary report");
    }
  };
  const saveNotes = (data) => {
    // setShowsStat(0);
    console.log("put>>", data);
    // return;
    const dataArr = data?.attributes?.map((a) => ({
      id: a.id,
      notes: a.notes,
      mitigationPlan: a.mitigationPlan,
    }));
    const headers = {
      "content-type": "application/json",
    };
    setLoading(true);
    axios
      .put(getSummaryReportUrl + "s", dataArr, { headers })
      .then((cresp) => {
        setLoading(false);
        getSummaryReportWithAxios();
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const generateReport = async () => {
    setLoading(true);
    await getSummaryReportWithAxios();
    setLoading(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    console.log(values);
  };
  const handleAttributeInputChange = (e) => {
    const { name, value } = e.target;
    setAttributeValues({
      ...attributeValues,
      [name]: value,
    });
    console.log(attributeValues);
  };
  const getEngagementDetailsWithAxios = async () => {
    setLoading(true);
    try {
      const response = await axios.get(getEngagementDetailsUrl + params?.id);
      console.log(response.data.responseData);
      const engagementDetails = response.data.responseData;
      setEngagementDetails(engagementDetails);
      const res = await axios.get(getDealDetailsUrl + engagementDetails.dealID);
      console.log("getEngagementDetailsWithAxios", res.data.responseData);
      const dealDetails = res.data.responseData;
      setDealDetails(dealDetails);
    } catch (err) {
      if (err.response.status == 401) {
        appContext.handleLogout();
      }
    }
    setLoading(false);
  };
  const getCovenantReviewWithAxios = async () => {
    try {
      const covresp = await axios.get(
        getCovenantReviewUrl + "?PeriodID=" + params.periodid
      );
      console.log(covresp.data.responseData);
      const covrev = covresp.data.responseData;
      const covrev_tem =
        covrev &&
        covrev.map((c) => {
          return { ...c, isEdit: false };
        });
      setCovenantReviews(covrev_tem);
    } catch (err) {
      if (err.response.status == 401) {
        // AppContext.handleLogout();
      }
    }
  };
  const [periodDetails, setPeriodDetails] = useState([]);
  const getPeriodDetailsWithAxios = async () => {
    try {
      const resp = await axios.get(getPeriodDetailsUrl + params.periodid);
      console.log("periods", resp.data.responseData);
      const periodDetails = resp.data.responseData;
      setPeriodDetails(periodDetails);
    } catch (error) {}
  };

  const [results, setResults] = useState([]);
  const [failResult, setFailResult] = useState(null);

  const getResultssWithAxios = async () => {
    try {
      const respres = await axios.get(getResultUrl);
      // console.log("getResultssWithAxios", respres);
      const ress = respres.data.responseData;
      const notNaResult = ress && ress.filter((r) => r.code !== "NA");
      setResults(notNaResult);
      // setResults(ress);
      // const fail = ress && ress.filter((r) => r.code == "NA");
      // if (fail.length > 0) {
      //   console.log("101 fail>>>", fail);
      //   setFailResult(fail[0]?.id);
      // }
      //getCovenantReviewsWithAxios();
    } catch (error) {}
  };

  const [formStat, setFormStat] = useState(0);
  const [isDisabled, setIsDisabled] = useState("");
  const openForm = () => {
    setFormStat(1);
  };

  const closeForm = () => {
    setFormStat(0);
  };
  const saveCovReview = (revid) => {
    setIsDisabled(revid);
    const dataArr = {
      id: revid,
      resultID: covValues.resultID,
      note: covValues.note,
      isComplete: false,
    };
    const headers = {
      "content-type": "application/json",
    };
    setLoading(true);
    axios
      .put(postCovenantReviewUrl, dataArr, { headers })
      .then((cresp) => {
        setLoading(false);
        // alert("Covenant Review has been saved successfully");
        getCovenantReviewWithAxios();
        setFormStat(0);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const completeCovReview = (revid) => {
    // console.log("data>>>",revid); return ;
    const dataArr = {
      id: revid.id,
      resultID: revid.resultID,
      note: revid.note,
      comparisonValue: revid.comparisonValue,
      isComplete: true,
    };
    const headers = {
      "content-type": "application/json",
    };
    setLoading(true);
    axios
      .put(postCovenantReviewUrl, dataArr, { headers })
      .then((cresp) => {
        setLoading(false);
        // alert("Covenant Review has been completed successfully");
        getCovenantReviewWithAxios();
        setFormStat(0);
      })
      .catch((error) => {
        // error response
        setLoading(false);
      });
  };

  const handleCovInputChange = (e) => {
    const { name, value } = e.target;
    setCovValues({
      ...covValues,
      [name]: value,
    });
    console.log(covValues);
  };

  const getAssetVerification = async () => {
    try {
      const resp = await axios.get(
        getAssetVerificationUrl + "?EngagementID=" + params?.id
      );
      console.log("getAssetVerificationUrl", resp);
      const av = resp.data.responseData;
      setAssetVerification((p) => [...av]);
    } catch (err) {
      if (err.response.status == 401) {
        appContext.handleLogout();
      }
    }
  };
  const getAssetReviewVerification = async () => {
    try {
      const response = await axios.get(
        assetVerificationReviewUrl + "?PeriodID=" + params.periodid
      );
      console.log("getAssetReviewVerification>>", response);
      setAvsReview(() => response.data.responseData);
    } catch (error) {}
  };
  const addAvsReview = () => {
    if (!avsReviewsNew) {
      initialAvsReview.periodID = params.periodid;
      // setAvsReview((p) => [...p, initialAvsReview]);
      setAvsReviewNew(() => initialAvsReview);
    } else {
      //alert("Submit the previous one please. ");
    }
  };
  const cancelAvsReview = () => {
    setAvsReviewNew(null);
    setValues(null);
  };
  const submitAvsReview = () => {
    if (
      !values?.assetVerificationID ||
      !values?.assetIdentifier ||
      !values?.assetName
    ) {
      //alert("All field is required");
      setAvsReviewError("All fields are required");
    } else {
      setAvsReviewError(null);
      setLoading(true);
      const dataArr = {
        ...values,
        periodID: params.periodid,
      };
      const headers = {
        "content-type": "application/json",
      };
      axios
        .post(assetVerificationReviewUrl, dataArr, { headers })
        .then((cresp) => {
          // alert("Asset verification has been completed successfully");
          // setFormStat(1);
          setLoading(false);
          getAssetReviewVerification();
          setAvsReviewNew(null);
          setValues(null);
        })
        .catch((error) => {
          // setLoading(false);
          // setAlertType("danger");
          // console.log("error>>", error);
          // setShowAlert(true);
          // setAlertMessage(error.response?.data?.Message);
          // error response
        });
    }
  };

  //delete
  const deleteAvsReview = (id) => {
    if (
      window.confirm(
        "Are you sure, you want to delete this asset verification review ?"
      )
    ) {
      const headers = {
        "content-type": "application/json",
      };
      setLoading(true);
      axios
        .delete(assetVerificationReviewUrl + "/" + id, { headers })
        .then((cresp) => {
          setLoading(false);
          // alert("Asset verification has been completed successfully");
          // setFormStat(1);
          getAssetReviewVerification();
          setAvsReviewNew(null);
        })
        .catch((error) => {
          // error response
          setLoading(false);
          // setAlertType("danger");
          // console.log("error>>", error);
          // setShowAlert(true);
          // setAlertMessage(error.response?.data?.Message);
        });
    }
  };
  // };
  const submitAvsReviewAttributes = async (values, reset) => {
    // if (
    //   !values?.assetVerificationID ||
    //   !values?.assetIdentifier ||
    //   !values?.assetName
    // ) {
    //   alert("All field is required");
    // } else {

    setLoading(true);
    try {
      const headers = {
        "content-type": "application/json",
      };
      const dataArr = values.attributes.map((v) => ({
        id: v.id,
        resultID: v.resultID,
        note: v.note,
        exceptionDescription: v.exceptionDescription,
      }));
      const res = await axios.put(
        assetVerificationAssetAttributeReviewsUrl,
        dataArr,
        {
          headers,
        }
      );
      console.log("resss>>", res);
      getAssetReviewVerification();
      getSummaryReportWithAxios();
      setTimeout(() => reset(), 1000);
    } catch (err) {
      getSummaryReportWithAxios();
    }
    setLoading(false);
    // const tprom = values.attributes.map((v) => {
    //   const dataArr = {
    //     id: v.id,
    //     // id: avsReviews[selectedAvsItem].id,
    //     resultID: v.resultID,
    //     note: v.note,
    //     exceptionDescription: v.exceptionDescription,
    //   };
    //   const headers = {
    //     "content-type": "application/json",
    //   };
    //   return axios.put(assetVerificationAssetAttributeReviewsUrl, dataArr, {
    //     headers,
    //   });

    //   // }
    // });
    // Promise.all(tprom).then(
    //   (data) => {
    //     setLoading(false);
    //     // alert("Asset verification has been completed successfully");
    //     // setFormStat(1);
    //     getAssetReviewVerification();
    //     getSummaryReportWithAxios();
    //     setTimeout(() => reset(), 1000);
    //   },
    //   (error) => {
    //     setLoading(false);
    //     console.log("error>>", error);
    //     getSummaryReportWithAxios();
    //     // setAlertType("danger");
    //     // console.log("error>>", error);
    //     // setShowAlert(true);
    //     // setAlertMessage(error.response?.data?.Message);
    //   }
    // );
  };

  const getDecuments = async (recodeId) => {
    try {
      const response = await axios.get(
        documentManageUrl + "?RecordID=" + recodeId
      );
      setAvsDocuments(() => response.data.responseData);
    } catch (error) {
      console.log("getDecuments error", error);
      // setAlertType("danger");
      // console.log("error>>", error);
      // setShowAlert(true);
      // setAlertMessage(error.response?.data?.Message);
    }
  };
  const downloadDocument = async (id, filename) => {
    try {
      setLoading(true);
      const response = await axios.get(decumentDownloadUrl + "/" + id, {
        responseType: "blob",
      });
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); //or any other extension
      document.body.appendChild(link);
      link.click();
      // setAvsDocuments(() => response.data.responseData);
    } catch (error) {
      setLoading(false);
      console.log("getDecuments error", error);
      // setAlertType("danger");
      // console.log("error>>", error);
      // setShowAlert(true);
      // setAlertMessage(error.response?.data?.Message);
    }
  };
  const documentUploadHandler = async (values) => {
    // const dataArr = {
    //   File: values.file,
    //   RecordID: selectedReviewItem,
    //   // BaseRecordID: covValues.note,
    // };
    setLoading(true);
    const formData = new FormData();
    formData.append("File", values.file);
    formData.append("RecordID", selectedReviewItem);
    formData.append("BaseRecordID", params?.id);
    const headers = {
      "content-type": "multipart/form-data",
    };
    axios
      .post(documentManageUrl, formData, { headers })
      .then((cresp) => {
        setLoading(false);
        // getCovenantReviewWithAxios();
        getDecuments(selectedReviewItem);
        // setFormStat(0);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const openModal = (v) => {
    setSelectedReviewItem(() => v);
    getDecuments(v);
    setModalOpen((p) => true);
  };
  const modalHideHandler = () => {
    setModalOpen((p) => false);
  };
  const parseParamsFromUrl = () => {
    let paramsTemp = queryString.parse(props.location.search);
    setParams(paramsTemp);
  };

  const reorderAssetVerification = async () => {
    let updateConvenantReviews = [];
    const engagementID = params.id;
    updateConvenantReviews = covenantReviews.map((g) => g?.covenantID);
    // .filter((gg) => gg);
    const headers = {
      "content-type": "application/json",
    };
    setLoading(true);
    axios
      .put(
        api.conventReviewReorder,
        {
          engagementID: engagementID,
          orderedCovenantIDs: updateConvenantReviews,
        },
        {
          headers,
        }
      )
      .then((resp) => {
        setLoading(false);
        // props.getEngagementsWithAxios();
        // setAssetVer(()=>avsresp.data.responseData?.id);
        // console.log("resp>>", avsresp, avsresp.data.responseData?.id, assetVer);
        // getCovenantReviewWithAxios();
        //alert('Successfully created AVS Setup');
      })
      .catch((error) => {
        setLoading(false);
        // error response
      });
  };
  useEffect(() => {
    axiosAuthHeaderSet();
    getEngagementDetailsWithAxios();
    getPeriodDetailsWithAxios();
    getCovenantReviewWithAxios();
    getResultssWithAxios();
    getAssetReviewVerification();
    getAssetVerification();
    getSummaryReportWithAxios();
  }, [params]);
  useEffect(() => {
    parseParamsFromUrl();
  }, []);

  return (
    <>
      <SideBar />
      <div id="main_wraper" className="">
        <AamsHeader />

        <div id="engagement_details_page">
          <MyBreadcrumb items={breadcrumbs} />
          <div className="clent-box-row new-two-row">
            <div className="row new-two-row-two">
              <div className="col-md-2 pr-0 ">
                <div className="client-name left-box-client">Deal name:</div>
              </div>
              <div className="col-md-10 pl-0 ">
                <div className="client-name-text right-box-client">
                  {dealDetails.name}
                  {/*<Button href="blank"  className="btn btn-link m-0 p-0">
								<i className="fas fa-edit"></i>
								</Button > */}
                </div>
              </div>
            </div>
            <div className="row new-two-row-two">
              <div className="col-md-2 pr-0 ">
                <div className="client-name left-box-client">Engagement :</div>
              </div>
              <div className="col-md-10 pl-0 ">
                <div className="client-name-text right-box-client">
                  {engagementDetails.name}
                </div>
              </div>
            </div>
            <div className="row new-two-row-two">
              <div className="col-md-2 pr-0 ">
                <div className="client-name left-box-client">Period :</div>
              </div>
              <div className="col-md-10 pl-0 ">
                <div className="client-name-text right-box-client">
                  {periodDetails.startDate &&
                    new Date(periodDetails.startDate).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        day: "numeric",
                        month: "numeric",
                      }
                    )}{" "}
                  -{" "}
                  {periodDetails.endDate &&
                    new Date(periodDetails.endDate).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        day: "numeric",
                        month: "numeric",
                      }
                    )}
                </div>
              </div>
            </div>
            <div className="row new-two-row-two">
              <div className="col-md-2 pr-0 ">
                <div className="client-name left-box-client">Status :</div>
              </div>
              <div className="col-md-10 pl-0 ">
                <div className="client-name-text right-box-client">
                  {periodDetails.statusName}
                </div>
              </div>
            </div>
          </div>
          {/* start */}

          <div
            className="accordion accordion-flush bg-transparent"
            id="accordionFlushExample"
          >
            {engagementDetails.isWaterfallGenerationEnabled ? (
              <div className="accordion-item bg-transparent">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Generate Waterfall
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body bg-transparent">
                    This is the third item's accordion body. It is hidden by
                    default, until the collapse plugin adds the appropriate
                    classes that we use to style each element. These classes
                    control the overall appearance, as well as the showing and
                    hiding via CSS transitions. You can modify any of this with
                    custom CSS or overriding our default variables. It's also
                    worth noting that just about any HTML can go within the
                    .accordion-body, though the transition does limit overflow.
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> className.
                    This is the first item's accordion body.
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {engagementDetails.isCovenantTrackingEnabled ? (
              <div className="accordion-item bg-transparent border-1">
                <h2 className="accordion-header" id="flush-headingTwo">
                  <button
                    className="accordion-button collapsed shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Covenant Review
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body p-1 bg-transparent">
                    <ConventReviewForm
                      completeCovReview={completeCovReview}
                      results={results}
                      covenantReviews={covenantReviews}
                      setCovenantReviews={setCovenantReviews}
                      submit={reorderAssetVerification}
                      periodDetails={periodDetails}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {engagementDetails.isVerificationServicesEnabled ? (
              <div className="accordion-item bg-transparent">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button collapsed shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    AVS Review
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse bg-transparent"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body bg-transparent">
                    <h1>
                      AVS Review Selection
                      {periodDetails?.statusCode !== "CLOSED" && (
                        <button title="Add New Item" onClick={addAvsReview}>
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </button>
                      )}
                      <div className="clearfix"></div>
                    </h1>
                    {isAvsReviewError && (
                      <div class="alert alert-danger">{isAvsReviewError}</div>
                    )}
                    <div className="table-responsive">
                      <table
                        className="table table-bordered text-light mb-0"
                        id="conventreviewTable"
                      >
                        <thead className="table-dark">
                          <tr>
                            <th width="10%">Selection #</th>
                            <th width="20%">Asset ID</th>
                            <th width="30%">Asset Name </th>
                            {/* <th width="">Asset Verification Id</th> */}
                            <th width="20%"></th>
                            <th width="20%">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {avsReviewsNew && (
                            <tr>
                              <td>{avsReviews.length + 1}</td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="assetIdentifier"
                                  onChange={handleInputChange}
                                />
                              </td>

                              <td>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="assetName"
                                  onChange={handleInputChange}
                                  // onBlur={submitAvsReview}
                                />
                              </td>
                              <td>
                                <select
                                  class="form-control"
                                  name="assetVerificationID"
                                  onChange={handleInputChange}
                                >
                                  <option>Select Asset</option>
                                  {assetVerification.length &&
                                    assetVerification.map((av) => {
                                      return (
                                        <option value={av.id}>
                                          {av.assetTypeName}
                                        </option>
                                      );
                                    })}
                                </select>
                              </td>
                              {/* <td></td> */}
                              <td>
                                <div class="row ">
                                  {periodDetails?.statusCode !== "CLOSED" && (
                                    <>
                                      <div class="col">
                                        <button
                                          type="button"
                                          class="form-control"
                                          onClick={cancelAvsReview}
                                          title="Cancel"
                                        >
                                          <i class="fa fa-window-close"></i>
                                        </button>
                                      </div>
                                      <div class="col">
                                        <button
                                          type="button"
                                          class="form-control "
                                          onClick={submitAvsReview}
                                          title="Save"
                                        >
                                          <i class="fas fa-save"></i>
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )}
                          {avsReviews.length > 0 &&
                            avsReviews.map((ar, i) => {
                              return (
                                <>
                                  <tr>
                                    <td>
                                      <label>{ar.selectionNumber}</label>
                                    </td>
                                    <td>
                                      <label>{ar.assetIdentifier}</label>
                                    </td>
                                    <td>{ar.assetName}</td>
                                    {/* <td>
                                      {assetVerification.length &&
                                        assetVerification.filter(
                                          (av) =>
                                            av.id === ar.assetVerificationID
                                        )[0]?.assetTypeName}
                                    </td> */}
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-main "
                                        onClick={() => openModal(ar.id)}
                                      >
                                        Documents ({ar.documentCount})
                                      </button>
                                    </td>
                                    <td>
                                      {!isOpenAvsItem && (
                                        <button
                                          className="btn btn-main"
                                          id=""
                                          onClick={() => {
                                            setSelectedAvsItem(i);
                                            setOpenAvsItem((p) => true);
                                          }}
                                        >
                                          <img
                                            alt="..."
                                            src={
                                              require("assets/images/outline_checklist_white_24dp.png")
                                                .default
                                            }
                                          ></img>
                                        </button>
                                      )}
                                      {isOpenAvsItem && (
                                        <button
                                          className="btn btn-main"
                                          id=""
                                          onClick={() => {
                                            setSelectedAvsItem(i);
                                            setOpenAvsItem((p) => false);
                                          }}
                                        >
                                          <img
                                            alt="..."
                                            src={
                                              require("assets/images/outline_checklist_white_24dp.png")
                                                .default
                                            }
                                          ></img>
                                        </button>
                                      )}
                                      {periodDetails?.statusCode !=
                                        "CLOSED" && (
                                        <button
                                          type="button"
                                          class="btn btn-main m-3"
                                          onClick={() => deleteAvsReview(ar.id)}
                                        >
                                          <i class="fa fa-trash"></i>
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                  {selectedAvsItem == i && isOpenAvsItem && (
                                    <tr>
                                      <td colSpan={6}>
                                        <div
                                          className="checklist"
                                          id={`checklistCheck_${i}`}
                                        >
                                          <div
                                            className="avs-setup justify-content-between align-items-center"
                                            id={`avsHead_${i}`}
                                          >
                                            <div className="d-flex">
                                              <div
                                                id="checkListText1"
                                                style={{ display: "flex" }}
                                              >
                                                <div id="checklistItem">
                                                  Verification Checklist
                                                </div>
                                              </div>
                                              <div
                                                id="checkListText2"
                                                className=""
                                                style={{ display: "none" }}
                                              >
                                                <div id="checklistItemshow">
                                                  <input
                                                    type="text"
                                                    value=""
                                                    id="checlistInput"
                                                  />{" "}
                                                </div>
                                                {periodDetails?.statusCode !=
                                                  "CLOSED" && (
                                                  <span
                                                    style={{
                                                      marginLeft: "10px",
                                                      fontSize: "10px",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <i
                                                      className="fa fa-save"
                                                      aria-hidden="true"
                                                      onclick="saveChecklist()"
                                                    ></i>
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <Formik
                                            initialValues={{
                                              attributes:
                                                ar.assetVerificationAssetAttributeReviews.map(
                                                  (arr) => {
                                                    return {
                                                      assetVerificationAttribute:
                                                        arr.assetVerificationAttribute,
                                                      id: arr.id,
                                                      note: arr.note,
                                                      resultID: arr.resultID,
                                                      isEdit: false,
                                                      exceptionDescription:
                                                        arr.exceptionDescription,
                                                    };
                                                  }
                                                ),
                                            }}
                                            onSubmit={(
                                              values,
                                              { resetForm }
                                            ) => {
                                              submitAvsReviewAttributes(
                                                values,
                                                resetForm
                                              );
                                            }}
                                            enableReinitialize={true}
                                          >
                                            {({
                                              values,
                                              errors,
                                              touched,
                                              handleChange,
                                              handleSubmit,
                                              setFieldValue,
                                            }) => (
                                              <form onSubmit={handleSubmit}>
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th>Group Name </th>
                                                      <th>
                                                        Attribute Test Name
                                                      </th>

                                                      {/* <th>Value to Compare </th> */}
                                                      <th>Source Name</th>
                                                      <th>Result</th>
                                                      <th>
                                                        <div
                                                          style={{
                                                            width: "250px",
                                                          }}
                                                        >
                                                          Notes
                                                        </div>
                                                      </th>
                                                      <th>
                                                        Exception Description
                                                      </th>
                                                      {/* <th></th> */}
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <FieldArray
                                                      name="attributes"
                                                      render={(
                                                        arrayHelpers
                                                      ) => (
                                                        <>
                                                          {values.attributes &&
                                                            values.attributes
                                                              .length > 0 &&
                                                            values.attributes.map(
                                                              (attr, i) => (
                                                                <tr>
                                                                  <td>
                                                                    {
                                                                      attr
                                                                        .assetVerificationAttribute
                                                                        ?.assetVerificationAttributeGroupName
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      attr
                                                                        .assetVerificationAttribute
                                                                        ?.name
                                                                    }
                                                                  </td>

                                                                  {/* <td>
                                                                    {
                                                                      attr
                                                                        .assetVerificationAttribute
                                                                        ?.value
                                                                    }
                                                                  </td> */}
                                                                  <td>
                                                                    {
                                                                      attr
                                                                        ?.assetVerificationAttribute
                                                                        ?.verificationSource
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    <Field
                                                                      as="select"
                                                                      id="resultSelect1"
                                                                      className="form-select"
                                                                      name={`attributes[${i}].resultID`}
                                                                      disabled={
                                                                        attr.isEdit
                                                                          ? false
                                                                          : false
                                                                      }
                                                                    >
                                                                      <option selected="">
                                                                        Select
                                                                      </option>
                                                                      {results.length >
                                                                        0 &&
                                                                        results?.map(
                                                                          (
                                                                            r
                                                                          ) => {
                                                                            return (
                                                                              <option
                                                                                value={
                                                                                  r.id
                                                                                }
                                                                              >
                                                                                {
                                                                                  r.name
                                                                                }
                                                                              </option>
                                                                            );
                                                                          }
                                                                        )}
                                                                    </Field>
                                                                  </td>
                                                                  <td>
                                                                    <Field
                                                                      as="textarea"
                                                                      className="form-control small"
                                                                      placeholder="Enter a Note…"
                                                                      name={`attributes[${i}].note`}
                                                                      rows={1}
                                                                      disabled={
                                                                        attr.isEdit
                                                                          ? false
                                                                          : false
                                                                      }
                                                                    />
                                                                  </td>
                                                                  <td>
                                                                    {values
                                                                      .attributes[0]
                                                                      ?.resultID !==
                                                                      failResult && (
                                                                      <Field
                                                                        as="textarea"
                                                                        rows={1}
                                                                        className="form-control small"
                                                                        placeholder="Enter a Note…"
                                                                        name={`attributes[${i}].exceptionDescription`}
                                                                        disabled={
                                                                          attr.isEdit
                                                                            ? false
                                                                            : false
                                                                        }
                                                                      />
                                                                    )}
                                                                  </td>
                                                                </tr>
                                                              )
                                                            )}
                                                        </>
                                                      )}
                                                      // )}
                                                    />
                                                  </tbody>
                                                </table>
                                                {periodDetails?.statusCode !=
                                                  "CLOSED" && (
                                                  <div class="text-right">
                                                    <button
                                                      class="btn btn-primary p-2"
                                                      title="Save"
                                                    >
                                                      <i class="fa fa-save"></i>
                                                    </button>
                                                  </div>
                                                )}
                                              </form>
                                            )}
                                          </Formik>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  <Modal
                                    show={isModalOpen}
                                    onHide={modalHideHandler}
                                    className="mt-5 eng_file_upload_modal"
                                  >
                                    <Modal.Body>
                                      <div
                                        className="modal-content"
                                        style={{ boxShadow: "none" }}
                                      >
                                        <div className="modal-body p-0">
                                          <Formik
                                            initialValues={{ file: null }}
                                            onSubmit={(values) =>
                                              documentUploadHandler(values)
                                            }
                                          >
                                            {({
                                              values,
                                              errors,
                                              touched,
                                              setFieldValue,
                                              handleChange,
                                              handleSubmit,
                                            }) => (
                                              <form onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                  <label
                                                    for="formFile"
                                                    className="form-label"
                                                  >
                                                    Upload file:
                                                  </label>
                                                  <input
                                                    className="form-control"
                                                    type="file"
                                                    id="formFile"
                                                    onChange={(e) =>
                                                      setFieldValue(
                                                        "file",
                                                        e.target.files[0]
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className="mb-3">
                                                  <div className="form-floating">
                                                    <textarea
                                                      className="form-control text-white"
                                                      placeholder="Leave a comment here"
                                                      id="floatingTextarea"
                                                    ></textarea>
                                                    <label for="floatingTextarea">
                                                      Description
                                                    </label>
                                                  </div>
                                                </div>

                                                <Loader isLoading={isLoading} />
                                                <Button
                                                  variant="secondary"
                                                  onClick={modalHideHandler}
                                                  className="btn btn-main"
                                                >
                                                  <i class="fas fa-arrow-left"></i>
                                                </Button>

                                                <button
                                                  type="submit"
                                                  className="btn btn-main"
                                                >
                                                  {" "}
                                                  <i class="fas fa-save"></i>
                                                </button>
                                              </form>
                                            )}
                                          </Formik>
                                        </div>
                                        <div class="row">
                                          <h5>
                                            Download document from list below
                                          </h5>
                                          <ul class="list-group">
                                            {avsDocuments.length > 0 &&
                                              avsDocuments.map((ad) => (
                                                <li class="list-group-item">
                                                  <h6>
                                                    {ad?.fileName}
                                                    <button
                                                      class="float-end pl-10"
                                                      onClick={() =>
                                                        downloadDocument(
                                                          ad.id,
                                                          ad.fileName
                                                        )
                                                      }
                                                    >
                                                      <i class="fas fa-download"></i>
                                                    </button>
                                                  </h6>
                                                </li>
                                              ))}
                                          </ul>
                                        </div>
                                      </div>
                                    </Modal.Body>
                                  </Modal>
                                </>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                    <PeriodSummaryReport
                      periodId={params.periodid}
                      saveNotes={saveNotes}
                      generateReport={generateReport}
                      summaryReports={summaryReports}
                      // deal={engagementDetails.dealID}
                      // start={periodDetails.startDate}
                      // end={periodDetails.endDate}
                      periodDetails={periodDetails}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* end */}
        </div>
        <Loader isLoading={isLoading} />
        <AamsFooter />
        {showAlert && (
          <Toaster show={showAlert} message={alertMessage} type={alertType} />
        )}
      </div>
    </>
  );
}

export default EngagementDetails;
