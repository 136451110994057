import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { config } from "../constant";
// reactstrap components
import { Button, Card, CardBody, CardTitle, Input } from "reactstrap";

// core components
import SideBar from "components/SideBar.js";
import AamsHeader from "components/AamsHeader.js";
import AamsFooter from "components/AamsFooter.js";
import moment from "moment";
import { axiosAuthHeaderSet } from "helpers/utils";
import { useSelector } from "react-redux";
import Toaster from "components/Toaster";
import Loader from "components/Loader";
import { AppContext } from "App";
import { useHistory } from "react-router";
import { sortedByProperty } from "helpers/data";
import MyBreadcrumb from "components/MyBreadcrumb/MyBreadcrumb";

const getEngagementUrl = config.url.API_URL + "period";

function Engagelist() {
  const [engagements, setEngagements] = useState([]);
  const [engagementsCopy, setEngagementsCopy] = useState([]);
  const homeData = useSelector((state) => state.home);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState("info");
  const [isLoading, setLoading] = useState(false);
  const [sortType, setSortType] = useState(null);
  const appContext = useContext(AppContext);
  const history = useHistory();
  const breadcrumbs = [
    {
      op: "Home",
      link: "/",
    },
    {
      op: "Engagements",
      link: "/engage-list",
    },
  ];
  const getEngagementsWithAxios = async () => {
    try {
      setLoading(true);
      const resp = await axios.get(getEngagementUrl);
      console.log(resp.data.responseData);
      const engagements = resp.data.responseData;
      setEngagements(engagements);
      setEngagementsCopy(engagements);
      setLoading(false);
    } catch (error) {
      setAlertType("danger");
      console.log("error>>", error);
      setShowAlert(true);
      setAlertMessage(error.response?.data?.Message);
      if (error.response.status == 401) {
        appContext.handleLogout();
      }
    }
    //console.log(posts);
  };
  const [statusCode, setStatusCode] = useState("OPEN");
  const handleInputChange = (e) => {
    setStatusCode(e.target.value);
    getEngagementsWithAxios();
  };
  const searchHandler = (e) => {
    var search = new RegExp(e.target.value, "gi");
    const tempDeal =
      engagementsCopy.length &&
      engagementsCopy.filter((d) => {
        if (d.engagementName.match(search)) return d;
      });
    setEngagements(tempDeal);
  };
  const tableSort = (property) => {
    console.log(property);
    setSortType((t) => {
      if (t == "asc") return "desc";
      else return "asc";
    });
    const tempData = sortedByProperty(engagements, property, sortType);
    setEngagements(tempData);
  };
  useEffect(() => {
    axiosAuthHeaderSet();
    getEngagementsWithAxios();
  }, [homeData]);

  return (
    <>
      <SideBar />
      <div id="main_wraper">
        <AamsHeader />

        <div id="engage_list_page">
          <MyBreadcrumb items={breadcrumbs} />
          <div className="row filter_form">
            <div className="col-sm-5">
              <div className="input-group search mb-1">
                <Input
                  type="text"
                  className="form-control bg-transparent text-light"
                  placeholder="Search by engagement name..."
                  onChange={searchHandler}
                ></Input>
                <Button color="primary">
                  <i className="fa fa-search" />
                </Button>
              </div>
            </div>
            {/* <div className="col-auto ml-auto">
						<div className="row">
							<label htmlFor="staticEmail" className="col-sm-4 col-form-label">Show</label>
							<div className="col-sm-8">    
								<Input id="" name="select" type="select" >
									<option>10</option>
									<option>20</option>
									<option>30</option>
								</Input>
							</div>
						</div>
					</div> */}
          </div>

          <div className="clearfix"></div>

          <Card className="card rounded-0 border-0 mb-3 bg-transparent">
            <CardBody className="p-0 rp-0 rounded-0 border-0 bg-transparent">
              <CardTitle tag="h4" style={{ padding: 0, margin: 0 }}>
                <span className=" mb-2 mt-3 float-start">
                  Engagement Listing
                </span>

                <span
                  style={{ display: "inline-block" }}
                  className=" mb-2 mt-2 float-end"
                >
                  <select
                    className="form-select bg-transparent text-light"
                    aria-label="Default select example"
                    name="covenantCategoryID"
                    value={statusCode}
                    onChange={handleInputChange}
                  >
                    <option value="OPEN">OPEN</option>
                    <option value="CLOSED">CLOSED</option>
                    <option value="NOT_STARTED">NOT STARTED</option>
                  </select>
                </span>
              </CardTitle>

              <div className="table-responsive">
                <table className="table table-bordered text-light mb-0">
                  <thead className="table-dark">
                    <tr>
                      <th>
                        <a type="button" onClick={() => tableSort("dealName")}>
                          Deal
                        </a>
                      </th>
                      <th>
                        <a
                          type="button"
                          onClick={() => tableSort("engagementName")}
                        >
                          Engagement
                        </a>
                      </th>
                      <th>
                        {/* <a
                          type="button"
                          onClick={() => tableSort("servicesRequired")}
                        > */}
                        Services
                        {/* </a> */}
                      </th>
                      <th>
                        <a type="button" onClick={() => tableSort("startDate")}>
                          Period Start
                        </a>
                      </th>
                      <th>
                        <a type="button" onClick={() => tableSort("endDate")}>
                          Period End
                        </a>
                      </th>
                      <th>
                        <a
                          type="button"
                          onClick={() => tableSort("deliveryDate")}
                        >
                          Est. Delivery Date
                        </a>
                      </th>
                      {/* <th>Services</th> */}
                      <th>
                        <a
                          type="button"
                          onClick={() => tableSort("statusCode")}
                        >
                          Status
                        </a>
                      </th>
                      <th>
                        <a
                          type="button"
                          onClick={() => tableSort("closedDate")}
                        >
                          Closed Date
                        </a>
                      </th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {engagements.map((period, j) =>
                      period.statusCode === statusCode ? (
                        <tr key={j}>
                          <td>
                            <a
                              href="javascript:void(0);"
                              onClick={() =>
                                history.replace(
                                  "engagement-details?id=" +
                                    period.engagementID +
                                    "&periodid=" +
                                    period.id
                                )
                              }
                            >
                              {period.dealName}
                            </a>
                          </td>
                          <td>{period.engagementName}</td>
                          <td>
                            {period.servicesRequired &&
                              period.servicesRequired.join(", ")}
                          </td>
                          <td>
                            {new moment(period.startDate).format("MM/DD/YYYY")}
                          </td>
                          <td>
                            {new moment(period.endDate).format("MM/DD/YYYY")}
                          </td>
                          <td>
                            {new moment(period.deliveryDate).format(
                              "MM/DD/YYYY"
                            )}
                          </td>
                          <td>{period.statusCode}</td>
                          <td>
                            {period.statusCode !== "OPEN" && period.closedDate
                              ? new moment(period.closedDate).format(
                                  "MM/DD/YYYY"
                                )
                              : ""}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </div>
        <AamsFooter />
        {showAlert && (
          <Toaster show={showAlert} message={alertMessage} type={alertType} />
        )}
        <Loader isLoading={isLoading} />
      </div>
    </>
  );
}

export default Engagelist;
