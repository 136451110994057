import React, { useState, useEffect, useContext } from "react";
import useForm from "../useForm";
import validate from "../FormValidationRules";
import axios from "axios";
import { config } from "../constant";
import { Link } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";
// reactstrap components
import {
  Button,
  Input,
  Label,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  Spinner,
} from "reactstrap";
import moment from "moment";

// core components
import SideBar from "components/SideBar.js";
import AamsHeader from "components/AamsHeader.js";
import AamsFooter from "components/AamsFooter.js";
import Loader from "components/Loader";
import { Field, Formik } from "formik";
import * as yup from "yup";
import { axiosAuthHeaderSet } from "helpers/utils";
import Toaster from "components/Toaster";
import { AppContext } from "App";

const postDealUrl = config.url.API_URL + "deal";
const getDealTypeUrl = config.url.API_URL + "deal-type";
const getPaymentPeriodUrl = config.url.API_URL + "payment-period";

function AddDeals(props) {
  //const [active, setActive] = useState(1);
  // const { values, errors, handleChange, setValue, handleSubmit } = useForm(
  // 	add_deal,
  // 	validate,
  // 	"add_deal"
  // );
  console.log("props>>", props);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isActive, setActive] = useState(true);
  const [includeInactive, setIncludeInactive] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState("info");

  const [dealTypes, setDealTypes] = useState();
  const appContext = useContext(AppContext);
  const addDealFormValidationSchema = yup.object().shape({
    deal_name: yup.string().required("Deal Name is a required field"),

    deal_description: yup.string().required("Description is a required field"),
    deal_type: yup.string().required("Deal Type is a required field"),
    originator: yup.string().required("Originator is a required field"),
    initial_deal_size: yup
      .number()
      .required("Initial Deal Size is a required field")
      .min(1, "Initial Deal Size must be greater than or equal to 1"),
    deal_closing_date: yup

      .date("Invalid date")
      .required("Deal Closing Date is a required field"),
    end_date: yup.date("Invalid date").required("End Date is a required field"),
    estimated_closing_date: yup
      .date("Invalid date")
      .required("Estimated Closing Date is a required field"),
    payment_date: yup.string(),
    payment_date_period: yup
      .string()
      .required("Payment Period is a required field"),
    initial_payment_date: yup
      .date("Invalid date")
      .required("Initial Payment Date  is a required field"),
    determination_date: yup.string(),
  });

  const getDealTypes = async () => {
    try {
      setLoading(true);
      const res = await axios.get(getDealTypeUrl);
      //console.log(res.data.responseData);
      const dealTypes = res.data.responseData;
      setDealTypes(dealTypes);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlertType("danger");
      console.log("error>>", error);
      setShowAlert(true);
      setAlertMessage(error.response?.data?.Message);
      if (error.response.status == 401) {
        appContext.handleLogout();
      }
    }
  };

  const [paymentPeriods, setPaymentPeriods] = useState();
  const getPaymentPeriods = async () => {
    const res = await axios.get(getPaymentPeriodUrl);
    //console.log(res.data.responseData);
    const paymentPeriods = res.data.responseData;
    setPaymentPeriods(paymentPeriods);
  };

  function add_deal(values) {
    const dataArr = {
      name: values.deal_name,
      description: values.deal_description,
      dealTypeID: values.deal_type,
      originator: values.originator,
      initialSize: values.initial_deal_size,
      closingDate: values.deal_closing_date,
      endDate: values.end_date,
      paymentDateDescription: values.payment_date,
      paymentPeriodID: values.payment_date_period,
      initialPaymentDate: values.initial_payment_date,
      determinationDateDescription: values.determination_date,
      isActive: isActive,
      estimatedClosingDate: values.estimated_closing_date,
      IncludeInactive: includeInactive,
    };
    setLoading(true);
    const headers = {
      "content-type": "application/json",
    };
    axios
      .post(postDealUrl, dataArr, { headers })
      .then((response) => {
        setLoading(false);
        console.log("res=>", response);
        let dealId = response.data.responseData.id;
        const timer = setTimeout(() => {
          props.history.push("/deal-engagement/" + dealId);
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.status === 400) {
          let errorVar = error.response.data;
          if (errorVar.Status === 2) {
            setErrorMessage(errorVar.Message);
          }
        }
      });
  }

  useEffect(() => {
    axiosAuthHeaderSet();
    getDealTypes();
    getPaymentPeriods();
  }, []);

  return (
    <>
      <SideBar />
      <div id="main_wraper">
        <AamsHeader />
        <div id="add_deal_page">
          {/* <div className="col-md-12 "><h3 className="m-0 mb-3">Add Deal</h3></div> */}
          {/* card */}
          <Formik
            initialValues={{
              deal_name: "",
              deal_description: "",
              deal_type: "",
              originator: "",
              initial_deal_size: "",
              deal_closing_date: "",
              end_date: "",
              payment_date: "",
              payment_date_period: "",
              initial_payment_date: "",
              estimated_closing_date: "",
              determination_date: "",
            }}
            onSubmit={(values, { resetForm }) => {
              console.log(values);
              add_deal(values);
              // resetForm();
            }}
            validationSchema={addDealFormValidationSchema}
          >
            {({
              handleChange,
              touched,
              values,
              handleSubmit,
              errors,
              setFieldValue,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <Card className="card rounded-0  mb-3 bg-transparent">
                  {errorMessage && (
                    <div class="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                    //   <span className="text-danger mb-3">  </span>
                  )}
                  {successMessage && (
                    <span className="success"> {successMessage} </span>
                  )}
                  <CardBody className="p-0 rounded-0 bg-transparent">
                    <CardTitle tag="h4">Deals</CardTitle>
                    <div className="p-3 text-white">
                      <Row>
                        {/* <Col sm={6}>
									<FormGroup row>
										<Label for="" sm={4}>Client Name :<span className="text-danger">*</span></Label>
										<Col sm={8}>
											<Input type="text" name="text" id="" placeholder="Enter client name" className="form-control-plaintext" required/>
										</Col>
									</FormGroup>
								</Col> */}
                        <Col sm={6}>
                          <FormGroup row>
                            <Label for="" sm={4}>
                              Deal Name :<span className="text-danger">*</span>
                            </Label>
                            <Col sm={8}>
                              {/* <Input
																type="text"
																name="deal_name"
																id="deal_name"
																placeholder="Enter Deal Name"
																onChange={handleChange}
																value={values.deal_name || ""}
																required
															/> */}
                              <Field
                                type="text"
                                name="deal_name"
                                id="deal_name"
                                placeholder="Enter Deal Name"
                                class="form-control bg-transparent text-light"
                              />
                              {errors.deal_name && touched.deal_name ? (
                                <div class="text-danger">
                                  {errors.deal_name}
                                </div>
                              ) : null}
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup row>
                        <Label for="exampleText" sm={2}>
                          Deal Description :
                          <span className="text-danger">*</span>
                        </Label>
                        <Col sm={10}>
                          {/* <Input
														type="textarea"
														name="deal_description"
														id="deal_description"
														onChange={handleChange}
														value={values.deal_description || ""}
														required
													/> */}
                          <Field
                            as="textarea"
                            name="deal_description"
                            id="deal_description"
                            placeholder="Enter Deal Description"
                            class="form-control bg-transparent text-light"
                          />
                          {errors.deal_description &&
                          touched.deal_description ? (
                            <div class="text-danger">
                              {errors.deal_description}
                            </div>
                          ) : null}
                        </Col>
                      </FormGroup>
                      <Row>
                        <Col sm={4}>
                          <FormGroup row>
                            <Label for="" sm={6}>
                              Deal Type :<span className="text-danger">*</span>
                            </Label>
                            <Col sm={6}>
                              <Field
                                as="select"
                                name="deal_type"
                                id="deal_type"
                                class="form-control bg-transparent text-light"
                                // onChange={handleChange}
                                // value={values.deal_type || ""}
                              >
                                <option>Select Deal Type</option>
                                {dealTypes &&
                                  dealTypes.map((dealType, i) => (
                                    <option key={i} value={dealType.id}>
                                      {dealType.name}
                                    </option>
                                  ))}
                              </Field>
                              {errors.deal_type && touched.deal_type ? (
                                <div class="text-danger">
                                  {errors.deal_type}
                                </div>
                              ) : null}
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup row>
                            <Label for="" sm={6}>
                              Originator :{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm={6}>
                              {/* <Input
																type="text"
																name="originator"
																id="originator "
																placeholder="Enter Originator"
																onChange={handleChange}
																value={values.originator || ""}
																required
															/> */}
                              <Field
                                type="text"
                                name="originator"
                                id="originator "
                                placeholder="Enter Originator"
                                class="form-control bg-transparent text-light"
                              />
                              {errors.originator && touched.originator ? (
                                <div class="text-danger">
                                  {errors.originator}
                                </div>
                              ) : null}
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup row>
                            <Label for="" sm={6}>
                              Intial Deal Size :
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm={6}>
                              <CurrencyInput
                                id="input-example"
                                name="initial_deal_size"
                                className="form-control text-end bg-transparent text-light"
                                placeholder="Please enter a number"
                                defaultValue={values.initial_deal_size || ""}
                                decimalsLimit={2}
                                prefix={"$"}
                                onValueChange={(value, name) =>
                                  setFieldValue("initial_deal_size", value)
                                }
                              />
                              {errors.initial_deal_size &&
                              touched.initial_deal_size ? (
                                <div class="text-danger">
                                  {errors.initial_deal_size}
                                </div>
                              ) : null}
                              {/* <Input
																type="number"
																name="initial_deal_size"
																id="initial_deal_size"
																placeholder="$$,$$$,$$$$.$$"
																onChange={handleChange}
																value={values.initial_deal_size || ""}
																pattern="[0-9]+"
																min={1}
																required
															/> */}
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4}>
                          <FormGroup row>
                            <Label for="" sm={6}>
                              Estimated Closing Date :
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm={6}>
                              {/* <Input
																type="date"
																name="estimated_closing_date"
																id="estimated_closing_date"
																placeholder="Select Date"
																onChange={handleChange}
																value={values.estimated_closing_date || ""}
																min={new Date().toISOString().split("T")[0]}
															/> */}
                              <Field
                                type="date"
                                name="estimated_closing_date"
                                id="estimated_closing_date"
                                placeholder="Select Date"
                                min={new Date().toISOString().split("T")[0]}
                                class="form-control bg-transparent text-light"
                              />
                              {errors.estimated_closing_date &&
                              touched.estimated_closing_date ? (
                                <div class="text-danger">
                                  {errors.estimated_closing_date}
                                </div>
                              ) : null}
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup row>
                            <Label for="" sm={6}>
                              Deal Closed Date :{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm={6}>
                              {/* <Input
																type="date"
																name="deal_closing_date"
																id="deal_closing_date"
																placeholder="Select Date"
																onChange={handleChange}
																value={values.deal_closing_date || ""}
																min={moment().format("YYYY-MM-DD")}
																required
															/> */}
                              <Field
                                type="date"
                                name="deal_closing_date"
                                id="deal_closing_date"
                                placeholder="Select Date"
                                min={moment().format("YYYY-MM-DD")}
                                class="form-control bg-transparent text-light"
                              />
                              {errors.deal_closing_date &&
                              touched.deal_closing_date ? (
                                <div class="text-danger">
                                  {errors.deal_closing_date}
                                </div>
                              ) : null}
                            </Col>
                          </FormGroup>
                        </Col>

                        <Col sm={4}>
                          <FormGroup row>
                            <Label for="" sm={6}>
                              End Date : <span className="text-danger">*</span>
                            </Label>
                            <Col sm={6}>
                              {/* <Input
																type="date"
																name="end_date"
																id="end_date"
																placeholder="Select Date"
																onChange={handleChange}
																value={values.end_date || ""}
																min={new Date().toISOString().split("T")[0]}
															/> */}
                              <Field
                                type="date"
                                name="end_date"
                                id="end_date"
                                placeholder="Select Date"
                                min={new Date().toISOString().split("T")[0]}
                                class="form-control bg-transparent text-light"
                              />
                              {errors.end_date && touched.end_date ? (
                                <div class="text-danger">{errors.end_date}</div>
                              ) : null}
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                {/* card */}
                <Card className="card rounded-0 mb-3 bg-transparent">
                  <CardBody className="p-0 rounded-0 bg-transparent ">
                    <CardTitle tag="h4">Deal Parameters</CardTitle>
                    <div className="p-3 text-white">
                      <Row>
                        <Col sm={6}>
                          <FormGroup row>
                            <Label for="" sm={4}>
                              Payment Date:
                            </Label>
                            <Col sm={8}>
                              {/* <Input
																type="text"
																name="payment_date"
																id="payment_date"
																placeholder="payment date"
																onChange={handleChange}
																value={values.payment_date || ""}
															/> */}
                              <Field
                                type="text"
                                name="payment_date"
                                id="payment_date"
                                placeholder="Payment date"
                                class="form-control bg-transparent text-light"
                              />
                              {errors.payment_date && touched.payment_date ? (
                                <div class="text-danger">
                                  {errors.payment_date}
                                </div>
                              ) : null}
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup row>
                            <Label for="" sm={4}>
                              Payment Date Period:
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm={8}>
                              {/* <Input
																type="select"
																name="payment_date_period"
																id="payment_date_period"
																placeholder="Payment date period"
																onChange={handleChange}
																value={values.payment_date_period || ""}
															>
																<option>Select Payment Period</option>
																{paymentPeriods &&
																	paymentPeriods.map((paymentPeriod, i2) => (
																		<option key={i2} value={paymentPeriod.id}>
																			{paymentPeriod.name}
																		</option>
																	))}
															</Input> */}
                              <Field
                                as="select"
                                name="payment_date_period"
                                id="payment_date_period"
                                placeholder="Payment date period"
                                class="form-control bg-transparent text-light"
                              >
                                <option>Select Payment Period</option>
                                {paymentPeriods &&
                                  paymentPeriods.map((paymentPeriod, i2) => (
                                    <option key={i2} value={paymentPeriod.id}>
                                      {paymentPeriod.name}
                                    </option>
                                  ))}
                              </Field>
                              {errors.payment_date_period &&
                              touched.payment_date_period ? (
                                <div class="text-danger">
                                  {errors.payment_date_period}
                                </div>
                              ) : null}
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup row>
                            <Label for="" sm={4}>
                              Initial Payment Date:
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm={8}>
                              {/* <Input
																type="date"
																name="initial_payment_date"
																id="initial_payment_date"
																placeholder="Initial Payment Date"
																onChange={handleChange}
																value={values.initial_payment_date || ""}
																// min={values.deal_closing_date > values.estimated_closing_date? values.deal_closing_date: values.estimated_closing_date}
																min={
																	values.deal_closing_date &&
																	moment(values.deal_closing_date)
																		.add(1, "d")
																		.format("YYYY-MM-DD")
																}
															/> */}
                              <Field
                                type="date"
                                name="initial_payment_date"
                                id="initial_payment_date"
                                placeholder="Initial Payment Date"
                                min={
                                  values.deal_closing_date &&
                                  moment(values.deal_closing_date)
                                    .add(1, "d")
                                    .format("YYYY-MM-DD")
                                }
                                class="form-control bg-transparent text-light"
                              />
                              {errors.initial_payment_date &&
                              touched.initial_payment_date ? (
                                <div class="text-danger">
                                  {errors.initial_payment_date}
                                </div>
                              ) : null}
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup row>
                            <Label for="" sm={4}>
                              Determination Date:
                            </Label>
                            <Col sm={8}>
                              {/* <Input
																type="text"
																name="determination_date"
																id="determination_date"
																placeholder="Determination Date"
																onChange={handleChange}
																value={values.determination_date || ""}
															/> */}
                              <Field
                                type="text"
                                name="determination_date"
                                id="determination_date"
                                placeholder="Determination Date"
                                class="form-control bg-transparent text-light"
                              />
                              {errors.determination_date &&
                              touched.determination_date ? (
                                <div class="text-danger">
                                  {errors.determination_date}
                                </div>
                              ) : null}
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Input type="hidden" name="isActive" values={values.isActive} />
                <Row>
                  <Col>
                    <Button
                      color="danger"
                      title="Cancel"
                      type="button"
                      onClick={() => {
                        // handleSubmit();
                        if (window.confirm("Are you sure?")) {
                          props.history.goBack();
                        }
                      }}
                    >
                      Cancel
                    </Button>{" "}
                    <Button
                      color="primary"
                      title="Submit"
                      type="submit"
                      onClick={() => {
                        setActive(true);
                        setIncludeInactive(true);
                        // handleSubmit();
                      }}
                    >
                      Submit
                    </Button>{" "}
                    <Button
                      color="default"
                      type="submit"
                      title="Save"
                      onClick={() => {
                        setActive(false);
                        setIncludeInactive(true);
                        // handleSubmit();
                      }}
                    >
                      Save
                    </Button>{" "}
                    {/* <Link to="/deal-Listing" className="btn btn-danger">Cancel</Link> */}
                    {/* <Spinner animation="border" role="status">
  <span className="visually-hidden">Loading...</span>
</Spinner> */}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
        <Loader isLoading={isLoading} />
        <AamsFooter />
        {showAlert && (
          <Toaster show={showAlert} message={alertMessage} type={alertType} />
        )}
      </div>
    </>
  );
}

export default AddDeals;
