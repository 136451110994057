import { Field, Formik } from "formik";
import * as yup from "yup";
import { dateFormat } from "helpers/utils";
import React from "react";
import { Button, Modal, Form, Table } from "react-bootstrap";
import { CSVParser } from "services/CSVParser";
import { camelToTitle } from "helpers/data";

import asset_img from "../../assets/img/asset_icn.png";
import collection_img from "../../assets/img/currency_icn.png";
import uploadplaceholder_img from "../../assets/img/upload-file.png";
import { FileUploader } from "react-drag-drop-files";

const stepOneValidation = yup.object().shape({
  selectedDate: yup.string().required(),
  selectedDeal: yup.string().required(),
});
const stepThreeValidation = yup.object().shape({
  file: yup.string().required(),
});

export default function FileUploadModal({
  handleClose,
  show,
  isUploadStepOneDone,
  isUploadStepTwoDone,
  isUploadStepThreeDone,
  deals,
  getMothlyPeriod,
  monthEndDateList,
  getTapeAsset,
  onOverwriteFileSelect,
  parseFile,
  uploadFile,
  success,
  error,
  downloadSample,
  back,
  uploadType,
  setUploadType,
}) {
  const fileTypes = ["csv"];
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="eng_file_upload_modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {error && (
            <div
              class="alert alert-danger"
              style={{
                padding: "5px",
                fontSize: 12,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
              }}
            >
              {error}
            </div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isUploadStepOneDone && (
          <div>
            <Formik
              initialValues={{ selectedDate: "", selectedDeal: "" }}
              onSubmit={(values) => {
                getTapeAsset(values.selectedDeal, values.selectedDate);
              }}
              validationSchema={stepOneValidation}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({
                handleChange,
                touched,
                values,
                handleSubmit,
                errors,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="radio_btn_group">
                    {" "}
                    <button
                      type="button"
                      onClick={() => setUploadType("asset")}
                      className={
                        uploadType === "asset"
                          ? "radio_button active"
                          : "radio_button"
                      }
                    >
                      <img src={asset_img} style={{ color: "#fff" }} />
                      Asset tape
                    </button>
                    <button
                      onClick={() => setUploadType("collection")}
                      type="button"
                      className={
                        uploadType === "collection"
                          ? "radio_button active"
                          : "radio_button"
                      }
                    >
                      <img src={collection_img} />
                      Collection tape
                    </button>
                  </div>

                  {/* <div className="form_dropdown_holder">

                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Upload File</Form.Label>

                      <div className="file_holder">
                        <img src={uploadplaceholder_img} />
                        <span>Upload or drop a file right here</span>
                      </div>
                      <div className="file_holder_below_text">
                        <div>No files uploaded yet</div>

                        <div>JPEG,PNG,GIF</div>
                      </div>
                    </Form.Group>
                  </div> */}

                  <Form.Group className="mb-1" controlId="formBasicEmail">
                    <Form.Label>Deal name </Form.Label>
                    <Field
                      as="select"
                      class="form-control"
                      name="selectedDeal"
                      // value={values.selectedDeal}
                      // onChange={(val) => {
                      // 	console.log("val>>>", val.target.value);
                      // 	console.log("val>>>", val.target.value);
                      // 	setFieldValue("selectedDeal", val.target.val);
                      // 	// getMothlyPeriod(val.target.val);
                      // }}
                      onChange={(e) => {
                        handleChange(e);
                        getMothlyPeriod(e.target.value);
                      }}
                    >
                      <option value="">Select Deal</option>
                      {deals &&
                        deals.length > 0 &&
                        deals.map((d) => (
                          <option key={`op_${d.id}`} value={d.id}>
                            {d.name}
                          </option>
                        ))}
                    </Field>
                    {errors.selectedDeal && touched.selectedDeal ? (
                      <div class="text-danger">
                        {camelToTitle(errors.selectedDeal)}
                      </div>
                    ) : null}
                    {/* <Form.Control type="text" placeholder="Deal name " /> */}
                  </Form.Group>

                  <Form.Group className="mb-1" controlId="formBasicPassword">
                    <Form.Label>Date</Form.Label>
                    <Field
                      as="select"
                      name="selectedDate"
                      value={values.selectedDate}
                      // type="text"
                      placeholder="18/07/2022"
                      class="form-control"
                    >
                      <option value="">Select Date</option>
                      {monthEndDateList &&
                        monthEndDateList.map((m) => (
                          <option
                            key={`option_date_${Math.random()}`}
                            value={dateFormat(
                              m?.tapeMonthEndDate,
                              "MM/DD/YYYY"
                            )}
                          >
                            {dateFormat(m?.tapeMonthEndDate, "MM/DD/YYYY")}
                          </option>
                        ))}
                    </Field>
                    {errors.selectedDate ? (
                      <div class="text-danger">
                        {camelToTitle(errors.selectedDate)}
                      </div>
                    ) : null}
                  </Form.Group>

                  <Button
                    className="btn btn-defult"
                    variant="defult"
                    type="button"
                    onClick={handleClose}
                  >
                    <i class="fas fa-arrow-left"></i> Cancel
                  </Button>
                  <Button
                    className="btn btn-defult"
                    variant="defult"
                    type="submit"
                  >
                    Next <i class="fas fa-arrow-right"></i>
                  </Button>
                  <Button
                    className="btn btn-defult"
                    variant="defult"
                    type="button"
                    onClick={downloadSample}
                  >
                    Download Sample<i class="fas fa-arrow-down"></i>
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        )}
        {/* part 2 */}
        {isUploadStepOneDone && !isUploadStepTwoDone && (
          <div>
            <h5>Upload and replace the previously uploaded information</h5>

            <Button
              style={{
                fontSize: "16px",
                marginRight: "8px",
              }}
              className="btn btn-success"
              variant="success"
              type="button"
              onClick={() => onOverwriteFileSelect(true)}
            >
              <i class="fas fa-check"></i>
            </Button>
            <Button
              style={{
                fontSize: "16px",
              }}
              className="btn btn-danger"
              variant="danger"
              type="button"
              onClick={() => onOverwriteFileSelect(false)}
            >
              <i class="fas fa-times"></i>
            </Button>
          </div>
        )}
        {/* part 3 */}
        {isUploadStepOneDone &&
          isUploadStepTwoDone &&
          !isUploadStepThreeDone && (
            <div>
              <Formik
                initialValues={{ file: "" }}
                onSubmit={(values) => uploadFile()}
                validationSchema={stepThreeValidation}
              >
                {({
                  handleChange,
                  touched,
                  values,
                  handleSubmit,
                  errors,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Upload File</Form.Label>
                      {/* <Form.Control
                        type="file"
                        name="file"
                        onChange={(e) => {
                          console.log("101 onChange>>", e);
                          handleChange(e);
                          parseFile(e);
                        }}
                        accept=".csv"
                      /> */}
                      <FileUploader
                        multiple={false}
                        handleChange={(e) => {
                          console.log(e);
                          // handleChange(e);
                          setFieldValue("file", e);
                          parseFile(e);
                        }}
                        name="file"
                        types={fileTypes}
                      />
                      <div class="text-primary fs-7">
                        <span class="text-danger">*</span>
                        Supported file format (.csv)
                      </div>
                      {errors.file && touched.file ? (
                        <div class="text-danger">
                          {camelToTitle(errors.file)}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Button
                      className="btn btn-defult"
                      variant="defult"
                      type="button"
                      onClick={back}
                    >
                      <i class="fas fa-arrow-left"></i> Cancel
                    </Button>
                    <Button
                      className="btn btn-defult"
                      variant="defult"
                      type="submit"
                    >
                      Upload <i class="fas fa-arrow-right"></i>
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          )}
      </Modal.Body>
      {/* <Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>
								Close
							</Button>
							<Button variant="primary" onClick={handleClose}>
								Save Changes
							</Button>
						</Modal.Footer> */}
    </Modal>
  );
}
