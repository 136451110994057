import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function SideBar() {
	const homeState = useSelector((state) => state.home);
	return (
		<>
			{/* new code */}

			<aside className="d-sm-flex d-none ">
				<div className="mt-2 mb-auto w-100">
					<div className="sidebar-header">
						<Link to="/dashboard">
							<img
								alt="..."
								src={require("assets/images/logo-dashboard.png").default}
							></img>
						</Link>
					</div>
					<div className="sidebar-menu">
						<h5>{homeState?.clients?.name}</h5>
						<div className="sidebar-normal-menu">
							<ul className="list-unstyled">
								<li>
									{/* <a href={`${process.env.PUBLIC_URL}/dashboard`}>
										<i className="fa fa-home" aria-hidden="true"></i>
										<span>Home</span>
									</a> */}
									<Link to="/dashboard">
										<i className="fa fa-home" aria-hidden="true"></i>
										<span>Home</span>
									</Link>
								</li>
								<li>
									{/* <a href={`${process.env.PUBLIC_URL}/deal-Listing`}>
										<i className="fa fa-newspaper" aria-hidden="true"></i>
										<span>Deals</span>
									</a> */}
									<Link to="/deal-Listing">
										<i className="fa fa-newspaper" aria-hidden="true"></i>
										<span>Deals</span>
									</Link>
								</li>
								<li>
									{/* <a href={`${process.env.PUBLIC_URL}/engage-list`}>
										<i className="fa fa-newspaper" aria-hidden="true"></i>
										<span>Engagements</span>
									</a> */}
									<Link to="/engage-list">
										<i className="fa fa-newspaper" aria-hidden="true"></i>
										<span>Engagements</span>
									</Link>
								</li>
								<li>
									<Link to="/portfolio-history">
										<i className="fa fa-newspaper" aria-hidden="true"></i>
										<span>Portfolio History</span>
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</aside>

			{/* new code end */}
		</>
	);
}

export default SideBar;
