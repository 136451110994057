export const camelToTitle = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};
export const titleToCamel = (text) => {
  const result = text.replaceAll(" ", "");
  return result;
};
export const sortByDate = (a, b) => {
  return new Date(a.createdDate) > new Date(b.createdDate);
};
export const strlimit = (text, count) => {
  return text.slice(0, count) + (text.length > count ? "..." : "");
};
export const lowercaseKeys = (obj) => {
  return Object.keys(obj).reduce((accumulator, key) => {
    accumulator[key.toLowerCase()] = obj[key];
    return accumulator;
  }, {});
};
export const isValidDate = (s) => {
  if (!/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})$/.test(s)) {
    return false;
  }
  const parts = s.split("-").map((p) => parseInt(p, 10));
  parts[0] -= 1;
  const d = new Date(parts[0], parts[1], parts[2]);
  return (
    d.getMonth() === parts[1] &&
    d.getDate() === parts[2] &&
    d.getFullYear() === parts[0]
  );
};
export const sortedByProperty = (a, property, order = "asc") => {
  return a.sort((aa, b) => {
    console.log(typeof aa[property]);

    if (typeof aa[property] == "number") {
      if (order == "asc") {
        return aa[property] - b[property];
      } else {
        return b[property] - aa[property];
      }
    } else if (typeof aa[property] === "boolean") {
      if (order == "asc") {
        return aa[property] - b[property];
      } else {
        return b[property] - aa[property];
      }
    } else {
      if (aa[property] === null) {
        return 1;
      }
      if (b[property] === null) {
        return -1;
      }
      if (aa[property] && b[property])
        if (order == "asc") {
          return aa[property].localeCompare(b[property]);
        } else {
          return b[property].localeCompare(aa[property]);
        }
    }
  });
};

export const searchKeyword = (data, search) => {
  var results = [];

  for (var i = 0; i < data.length; i++) {
    console.log(data[i], typeof data[i], Object.keys(data[i]).length);
    Object.keys(data[i]).map((key) => {
      if (data[i].hasOwnProperty(key) && data[i][key]) {
        if (
          data[i][key].toString().toLowerCase().indexOf(search.toLowerCase()) !=
          -1
        ) {
          results.push(data[i]);
        }
      }
      return true;
    });
  }
  console.log(results);
  return results;
};

export const toFindDuplicates = (arr, property) => {
  const dup = [];

  for (const item of arr) {
    const isDuplicate = dup.find((obj) => {
      // if (Object.prototype.hasOwnProperty.call(property, item)) {
      //   console.log("data>>", arr, obj[property], item[property]);

      //   return obj[property] === item[property];
      // }
      return item[property] && obj[property] === item[property];
    });
    if (!isDuplicate) {
      dup.push(item);
    }
  }
  let difference = arr.filter((x) => !dup.includes(x));
  return difference;
};
