import { Field, Formik } from "formik";
import { camelToTitle } from "helpers/data";
import moment from "moment";
import React from "react";
import { Button } from "reactstrap";
import CurrencyInput from "react-currency-input-field";
import * as yup from "yup";

export default function DealInfoEditForm({
  dealDetails,
  setDealDetailsWithAxios,
  dealTypes,
}) {
  return (
    <>
      <Formik
        initialValues={{
          ...dealDetails,
          id: dealDetails.id,
          name: dealDetails.name,
          description: dealDetails.description,
          dealTypeID: dealDetails.dealTypeID,
          originator: dealDetails.originator ? dealDetails.originator : "",
          initialSize: dealDetails.initialSize || 1,
          closingDate: dealDetails.closingDate
            ? moment(dealDetails.closingDate).format("YYYY-MM-DD")
            : "",
          isActive: dealDetails.isActive,
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required(),
          id: yup.string().required(),
          description: yup.string().required("Description is a required field"),
          dealTypeID: yup.string().required("Deal Type is a required field"),
          originator: yup.string().required("Originator is a required field"),
          initialSize: yup
            .number()
            .required("initial Size is a required field")
            .min(1),
          closingDate: yup
            .date()
            .required("Closing Date is a required field")
            .min(dealDetails.closingDate, "Date cannot be in the past"),
        })}
        onSubmit={setDealDetailsWithAxios}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div class="deal-update ">
              <Button
                className="btn btn-primary"
                type="button"
                title="Submit deal"
                onClick={() => {
                  setFieldValue("isActive", true);
                  handleSubmit();
                }}
              >
                <i class="fas fa-paper-plane"></i>
              </Button>
              <Button
                className="btn btn-primary"
                type="button"
                title="Save deal"
                onClick={() => {
                  setFieldValue("isActive", false);
                  handleSubmit();
                }}
              >
                <i class="fas fa-save"></i>
              </Button>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="deal-left-title">
                  Deal Name
                  <span class="text-danger">*</span>:
                </div>
              </div>
              <div className="col-md-4">
                <div className="deal-right-text">
                  <Field
                    type="text"
                    class="form-control bg-transparent text-light"
                    name="name"
                  />
                  {errors.name && touched.name ? (
                    <div class="text-danger">{errors.name}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="deal-left-title">
                  Deal Type
                  <span class="text-danger">*</span>:
                </div>
              </div>
              <div className="col-md-8">
                <div className="deal-right-text">
                  <Field
                    as="select"
                    class="form-control bg-transparent text-light"
                    name="dealTypeID"
                  >
                    {dealTypes?.map((d) => {
                      return <option value={d.id}>{d.name}</option>;
                    })}
                  </Field>
                  {errors.dealTypeID && touched.dealTypeID ? (
                    <div class="text-danger">{errors.dealTypeID}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="deal-left-title">
                  Deal Description
                  <span class="text-danger">*</span>:
                </div>
              </div>
              <div className="col-md-8">
                <div className="deal-right-text">
                  <Field
                    as="textarea"
                    class="form-control bg-transparent text-light"
                    name="description"
                  />

                  {errors.description && touched.description ? (
                    <div class="text-danger">{errors.description}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="deal-left-title">
                  Originator
                  <span class="text-danger">*</span>:
                </div>
              </div>
              <div className="col-md-8">
                <div className="deal-right-text">
                  <Field
                    type="text"
                    name="originator"
                    id="originator "
                    placeholder="Enter Originator"
                    class="form-control bg-transparent text-light"
                  />
                  {errors.originator && touched.originator ? (
                    <div class="text-danger">{errors.originator}</div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-4">
                <div className="deal-left-title">
                  Deal Closing Date
                  <span class="text-danger">*</span>: <br />{" "}
                  <small>If already Closed</small>
                </div>
              </div>
              <div className="col-md-8">
                <div className="deal-right-text">
                  {console.log(
                    "close>>>",
                    // values.closingDate,
                    moment(values.closingDate).format("YYYY-MM-DD")
                  )}
                  <Field
                    type="date"
                    name="closingDate"
                    id="closingDate"
                    // placeholder="Select Date"
                    class="form-control bg-transparent text-light"
                    // min={
                    // 	new Date().toISOString().split("T")[0]
                    // }
                    format="dd/mm/yyyy"
                  />
                  {errors.closingDate && touched.closingDate ? (
                    <div class="text-danger">{errors.closingDate}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="deal-left-title">
                  Initial Deal Size
                  <span class="text-danger">*</span>:
                </div>
              </div>
              <div className="col-md-8">
                <div className="deal-right-text">
                  {/* <Field
                                                                                type="number"
                                                                                name="initialSize"
                                                                                id="initialSize "
                                                                                placeholder="Enter initialSize"
                                                                                class="form-control"
                                                                                min={1}
                                                                            /> */}
                  <CurrencyInput
                    // type="number"
                    name="initialSize"
                    id="initialSize "
                    placeholder="Enter initialSize"
                    defaultValue={values.initialSize || ""}
                    decimalsLimit={2}
                    class="form-control text-end bg-transparent text-light"
                    prefix={"$"}
                    onValueChange={(value, name) =>
                      setFieldValue("initialSize", value)
                    }
                  />
                  {errors.initialSize && touched.initialSize ? (
                    <div class="text-danger">
                      {camelToTitle(errors.initialSize)}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
