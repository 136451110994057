// Constants.js
const {configData} =window;
const prod = {
	url: {
		API_URL: configData.REACT_APP_API_URL,
		//   API_URL: 'https://app-mtag-devtest-amssapi.azurewebsites.net/api/'
	},
};


export const config =  prod;
