import { Field, Formik } from "formik";
import moment from "moment";
import React from "react";

export default function DealParameters({
	dealDetails,
	setDealDetailsWithAxios,
	dealParameterFormValidationSchema,
	isDealParameterEdit,
	setDealParameterEdit,
	paymentPeriods,
}) {
	return (
		<>
			<Formik
				initialValues={{
					initialPaymentDate: dealDetails?.initialPaymentDate
						? moment(dealDetails?.initialPaymentDate).format("YYYY-MM-DD")
						: "",
					determinationDateDescription:
						dealDetails?.determinationDateDescription,
					paymentDateDescription: dealDetails?.paymentDateDescription,
					paymentPeriodID: dealDetails?.paymentPeriodID
						? dealDetails?.paymentPeriodID
						: "",
					id: dealDetails?.id,
					name: dealDetails?.name,
					DealTypeID: dealDetails?.dealTypeID,
					Description: dealDetails?.description,
					originator: dealDetails?.originator,
					closingDate: dealDetails?.closingDate,
				}}
				onSubmit={(values) => {
					console.log("vules>>", values);
					setDealDetailsWithAxios(values);
				}}
				enableReinitialize={true}
				validationSchema={dealParameterFormValidationSchema}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					/* and other goodies */
				}) => (
					<form onSubmit={handleSubmit}>
						<div className="content-box-main p-3">
							<h2 className="color-red mb-3">
								Deal Payment Terms
								{!isDealParameterEdit ? (
									<button
										class="float-end small_button_custom"
										type="button"
										onClick={(e) => {
											e.preventDefault();
											setDealParameterEdit(() => true);
										}}
									>
										<i class="fa fa-edit "></i>
									</button>
								) : (
									<button class="float-end small_button_custom" type="submit">
										<i class="fas fa-save "></i>
									</button>
								)}
							</h2>
							{isDealParameterEdit ? (
								<>
									<div className="parameter-row">
										<p>
											Payment Start Date:
											<span class="text-danger">*</span>
										</p>
										<Field
											type="date"
											name="initialPaymentDate"
											class="form-control bg-transparent text-light"
										/>
										{errors.initialPaymentDate && touched.initialPaymentDate ? (
											<div class="text-danger">{errors.initialPaymentDate}</div>
										) : null}
									</div>
									<div className="parameter-row mt-2">
										<p>Determination Date:</p>
										<Field
											type="text"
											name="determinationDateDescription"
											class="form-control bg-transparent text-light"
										/>
										{/* {errors.determinationDateDescription &&
                                touched.determinationDateDescription ? (
                                  <div class='error'>
                                   Determination date is required

                                  </div>
                                ) : null} */}
									</div>
									<div className="parameter-row mt-2">
										<p>Payment Date:</p>
										<Field
											type="text"
											name="paymentDateDescription"
											class="form-control bg-transparent text-light"
										/>
										{/* {errors.paymentDateDescription &&
                                touched.paymentDateDescription ? (
                                  <div class='error'>
                                    Payment date is required
                                  </div>
                                ) : null} */}
									</div>

									<div className="parameter-row mt-2">
										<p>
											Payment Frequency:
											<span class="text-danger">*</span>
										</p>
										<Field
											as="select"
											name="paymentPeriodID"
											class="form-control bg-transparent text-light"
										>
											<option>Select please</option>
											{paymentPeriods.length > 0 &&
												paymentPeriods.map((pp) => (
													<option
														key={"pay_" + pp.id}
														selected={pp.id === dealDetails?.paymentPeriodID}
														value={pp.id}
													>
														{pp.name}
													</option>
												))}
										</Field>
										{errors.paymentPeriodID && touched.paymentPeriodID ? (
											<div class="text-danger">{errors.paymentPeriodID}</div>
										) : null}
									</div>
								</>
							) : (
								<>
									<div className="parameter-row">
										<p>Payment Start Date:</p>
										<span className="parameter-date deal-right-text">
											{dealDetails.initialPaymentDate &&
												new Date(
													dealDetails.initialPaymentDate
												).toLocaleDateString("en-US", {
													year: "numeric",
													day: "numeric",
													month: "numeric",
												})}{" "}
										</span>
									</div>
									<div className="parameter-row mt-2">
										<p>Determination Date:</p>
										<span className="parameter-date deal-right-text">
											{dealDetails.determinationDateDescription}{" "}
										</span>
									</div>
									<div className="parameter-row mt-2">
										<p>Payment Date:</p>
										<span className="parameter-date deal-right-text">
											{dealDetails.paymentDateDescription}{" "}
										</span>
									</div>

									<div className="parameter-row mt-2">
										<p>Payment Frequency: </p>
										<span className="parameter-date deal-right-text">
											{dealDetails.paymentPeriodName}
										</span>
									</div>
								</>
							)}
						</div>
					</form>
				)}
			</Formik>
		</>
	);
}
