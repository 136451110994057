import { memo, useEffect, useState } from "react";
import { Dustbin } from "./dustbin";
import { Box } from "./box";
export const Container = memo(function Container(props) {
  const [boards, setBoard] = useState([...props.board]);
  function handleChange(event) {
    // Here, we invoke the callback with the new value
    props.onChange(boards)
    // props.onChange(event.target.value);
  }
  // useEffect(()=>{
  //   props.onChange(boards)
  // },[boards])
  useEffect(() => {
    setBoard([...props.board])
  }, [props.board])
  return (
    <>
      <div className='componant-box' style={{ margin: "20px 15px" }}>
        <div className='componant-box-title'>
          <span>
            AMSS Services
          </span>
        </div>
        <div
          className='componant-box-content'
        //style={{ backgroundColor: "#1d97bc", padding: "7px 18px" }}
        >
          <h6 className='mt-2' style={{ fontSize: "12px", color: "#fff" }}>
            Select or Drag AMSS Services to use on Engagement Windows
          </h6>
          <div
            style={{
              fontSize: "12px",
              display: "flex",
              gap: "15px",
              flexWrap: "wrap",
            }}
          >
            <Box name='Waterfall Generation' id='isWaterfallGenerationEnabled' />
            <Box name='Covenant Tracking' id='isCovenantTrackingEnabled' />
            <Box name='Report Generation' id='isReportGenerationEnabled' />
            <Box name='Verification Services' id='isVerificationServicesEnabled' />
            <Box name='Cash Administration' id='isCashAdministrationEnabled' />

          </div>
        </div>
      </div>
      <div style={{ overflow: "hidden", clear: "both" }}>
        <Dustbin
          value={props.value}
          onChange={handleChange}
          boards={boards}
          setBoard={props.setBoard}
        />
      </div>
    </>
  );
});
