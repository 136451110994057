import React, { useRef } from "react";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Button } from "react-bootstrap";
import { strlimit } from "helpers/data";

export default function MyTooltrip({ msg, target, size = 65 }) {
  //   const target = useRef(null);

  const popover = (
    <Popover id="popover-basic" className={"bg-secondary"}>
      <Popover.Header as="h3" className="text-white bg-dark mt-0">
        Description
      </Popover.Header>
      <Popover.Body className="text-white">{msg}</Popover.Body>
    </Popover>
  );
  return (
    <>
      {msg.length > size ? (
        <OverlayTrigger placement="right" overlay={popover}>
          <div variant="success">{strlimit(msg, size)}</div>
        </OverlayTrigger>
      ) : (
        <div variant="success">{msg}</div>
      )}
    </>
  );
}
