export const API = {
  assetTapeMonth: "asset-tape-months",
  assetTape: "asset-tape/v2",
  assetTapeDocDownload: "document-download",
  getDeals: "deal",
  assetTapeMapping: "asset-tape-mapping",
  sampleTapFileUrl: "asset-tape-template",
  collectionTape: "collection-tape",
  collectionTapeMonth: "collection-tape-months",
  uploadTapeMonth: "upload-tape-months",
  sampleCollectionFileUrl: "collection-tape-template",
  collectionTapeMapping: "collection-tape-mapping",
};
