import { useDrag } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
const style = {
	backgroundColor: "white",
	padding: "15px",
	cursor: "move",
	textAlign: "center",
	borderRadius: "4px",
};
export const Box = function Box({ name, id }) {
	const [{ isDragging }, drag] = useDrag(() => ({
		type: ItemTypes.BOX,
		item: { name, id },
		end: (item, monitor) => {
			const dropResult = monitor.getDropResult();
			if (item && dropResult) {
				//alert(`You dropped ${item.name} into ${dropResult.name}!`);
			}
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
			// handlerId: monitor.getHandlerId(),
		}),
	}));
	const opacity = isDragging ? 0.4 : 1;
	return (
		<div
			ref={drag}
			role="Box"
			style={{ ...style, opacity }}
			data-testid={`box-${name}`}
		>
			{name}
		</div>
	);
};
