import React, { useCallback } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AssetVerificationGroupItem from "./AssetVerificationGroupItem";
import "./AssetVerification.scss";

export default function AssetVerificationView({
  groups,
  setSelectedGroup,
  setGroupEdit,
  setGroupActive,
  setGroupEditModalShow,
  editAVSAttribute,
  delAVSAttribute,
  showstat,
  setGroups,
  reorderAssetVerificationAttributes,
  isGroupOrderFlag,
  setAttributeOrderFlag,
  isAttributeOrderFlag,
  onAttributesFlagOpen,
}) {
  const onDragEnd = (e) => {
    console.log(e);
    const sourceIndex = e.source.index;
    const destIndex = e.destination.index;
    const tempGroups = [...groups];
    tempGroups.splice(destIndex, 0, tempGroups.splice(sourceIndex, 1)[0]);
    setGroups(tempGroups);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-1">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {groups &&
              groups.map((g, index) => (
                <Draggable
                  draggableId={"draggable-" + index}
                  index={index}
                  key={index}
                >
                  {(provided, snapshot) => (
                    <fieldset
                      // className="position-relative"
                      index={index}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        ...provided.draggableProps.style,
                        boxShadow: snapshot.isDragging
                          ? "0 0 .4rem #666"
                          : "none",
                      }}
                    >
                      {isGroupOrderFlag && (
                        <span className="flote-start drag_drop position-absolute rounded-circle">
                          <span
                            className="btn btn-link"
                            color="primary"
                            {...provided.dragHandleProps}
                            // onClick={() => groupOrder(g)}
                          >
                            <i class="fas fas fa-sort"></i>
                          </span>
                        </span>
                      )}
                      <legend className="mt-0 mb-0">
                        {g?.name}{" "}
                        <span className="flote-end">
                          <button
                            className="btn btn-link"
                            color="primary"
                            onClick={() => {
                              setSelectedGroup(g);
                              setGroupEdit(true);
                              setGroupActive(true);
                              setGroupEditModalShow(true);
                            }}
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                        </span>
                      </legend>
                      <AssetVerificationGroupItem
                        g={{ ...g }}
                        index={index}
                        delAVSAttribute={delAVSAttribute}
                        editAVSAttribute={editAVSAttribute}
                        reorderAssetVerificationAttributes={
                          reorderAssetVerificationAttributes
                        }
                        setAttributeOrderFlag={setAttributeOrderFlag}
                        isAttributeOrderFlag={isAttributeOrderFlag}
                        groups={groups}
                        setGroups={setGroups}
                        onAttributesFlagOpen={onAttributesFlagOpen}
                      />

                      {provided.placeholder}
                    </fieldset>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
