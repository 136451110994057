/*eslint-disable*/
import React, { useContext, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState } from "react";

// reactstrap components
import {
  Input,
  FormGroup,
  Button,
  Form,
  //  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { handleAzureLogout, SignInButton } from "./AzureButton";
import { logout } from "helpers/utils";
import { getName } from "helpers/utils";
import { useMsal } from "@azure/msal-react";
import { b2cPolicies } from "authConfig";
import axios from "axios";
import { config } from "../constant";
import { axiosAuthHeaderSet } from "helpers/utils";
import { setClientStorage } from "helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import { updateClient } from "actions/homeAction";
import { getClientStorage } from "helpers/utils";
import { AppContext } from "App";
const clientUrl = config.url.API_URL + "client";

// core components

function AamsHeader() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState("");
  const [clients, setClints] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const { instance } = useMsal();
  const homeState = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const handleLogout = () => {
    appContext.handleLogout("logout");
  };
  useEffect(() => {
    axiosAuthHeaderSet();
    loadName();
    loadClients();
    // e.preventDefault();
    // setSelectedClient(e.target.value);
    // dispatch(updateClient(e.target.value));
    // setClientStorage(e.target.value).then(() => {
  }, []);
  useEffect(() => {
    axiosAuthHeaderSet();
    // setSelectedClient();
  }, [homeState]);
  const loadName = async () => {
    const name = await getName();
    setName(name);
  };

  const handleEditProfile = () => {
    instance.loginPopup(b2cPolicies.authorities.editProfile);
  };
  const loadClients = async () => {
    console.log("homeState", homeState);
    const clients = homeState.clients;
    // const resp = await axios.get(clientUrl);
    setClints(() => clients);
    // if (!resp?.data?.isError && resp?.data?.responseData.length > 0) {
    // 	// setSelectedClient(resp?.data?.responseData[0]?.id);
    const client = homeState.client_id;
    // if (client) {
    // setClientStorage(client);
    setSelectedClient(client);
    // } else setClientStorage(resp?.data?.responseData[0]?.id);
    // }

    // console.log("resp>>", resp);
  };
  const clientHandler = async (e) => {
    e.preventDefault();
    // setSelectedClient(e.target.value);
    // dispatch(updateClient(e.target.value));
    // setClientStorage(e.target.value).then(() => {
    // 	// window.location.reload();
    // });
    setClientStorage(selectedClient);
    await dispatch(updateClient(selectedClient));
    setTimeout(() => {
      window.location.href = "/dashboard";
    }, 2000);
  };
  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <aside className="d-flex ">
            <div className="mt-5 mb-auto w-100">
              <div className="sidebar-header">
                <a href="blank">
                  <img
                    alt="..."
                    src={require("assets/images/logo-dashboard.png").default}
                  ></img>
                </a>
              </div>
              <div className="sidebar-menu">
                {/* <h5>Metropoliton</h5> */}
                <div className="sidebar-normal-menu">
                  <ul className="list-unstyled">
                    <li>
                      <a href={`${process.env.PUBLIC_URL}/dashboard`}>
                        <i className="fa fa-home" aria-hidden="true"></i>
                        <span>Home</span>
                      </a>
                    </li>
                    <li>
                      <a href={`${process.env.PUBLIC_URL}/deal-Listing`}>
                        <i className="fa fa-newspaper" aria-hidden="true"></i>
                        <span>Deals</span>
                      </a>
                    </li>
                    <li>
                      <a href={`${process.env.PUBLIC_URL}/engage-list`}>
                        <i className="fa fa-newspaper" aria-hidden="true"></i>
                        <span>Engagements</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </aside>
        </Offcanvas.Body>
      </Offcanvas>

      <div className="top-header">
        <div className="d-flex  justify-content-between flex-sm-row flex-column">
          <div className="">
            {/* 
    <InputGroup>
    <Input id="" name="select" type="select" >
    <option>Metropolitan</option>
          <option>Blade Runner</option>
          <option>Thor Ragnarok</option>
    </Input>
    <Button>
      I'm a button
    </Button>
   
  </InputGroup> */}
            {/* 
						<Form className="form-inline">
							<FormGroup>
						
								<Input
									id=""
									name="select"
									type="text"
									readOnly
									className=" bg-secondary text-light"
									defaultValue={clients.name}
									onChange={(e) => setSelectedClient(e.target.value)}
								>
									{clients && (
										<option
											value={clients.id}
											selected={selectedClient == clients.id}
										>
											{clients.name}
										</option>
									)}
									
								</Input>
							</FormGroup>
							<Button
								color="primary"
								type="button"
								onClick={() => console.log("")}
								// onClick={(e) => clientHandler(e)}
							>
								View Client
							</Button>
						</Form> */}
          </div>
          <div className="">
            <div className="top-right-icon d-flex align-items-center ">
              <div className="right-icon-box  d-block d-sm-none mr-auto">
                <Button variant="primary" onClick={handleShow} color="neutral">
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </Button>
              </div>
              {/* <div className="right-icon-box ">
								<Button color="neutral">
									<i className="fa fa-cog" aria-hidden="true"></i>
								</Button>
							</div>
							<div className="right-icon-box ">
								<Button color="neutral">
									{" "}
									<i className="fa fa-bell" aria-hidden="true"></i>
								</Button>
							</div> */}
              <div className="av_holder">
                <div className="avatar">
                  <Button color="neutral">
                    <img
                      alt="..."
                      width={36}
                      className="rounded-circle"
                      src={require("assets/images/1.jpg").default}
                    ></img>
                  </Button>
                </div>
                <div className="avatar ">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      aria-expanded={false}
                      aria-haspopup={true}
                      caret
                      className="btn-link"
                      data-toggle="dropdown"
                      id="dropdownMenuButton"
                      type="button"
                    >
                      {name}
                    </DropdownToggle>
                    <DropdownMenu aria-labelledby="dropdownMenuButton">
                      <DropdownItem
                        onClick={(e) => {
                          e.preventDefault();
                          handleEditProfile();
                        }}
                      >
                        Edit Profile
                      </DropdownItem>
                      <DropdownItem
                        onClick={(e) => {
                          e.preventDefault();
                          handleLogout();
                        }}
                      >
                        Logout
                      </DropdownItem>
                      {/* <DropdownItem
												href="#pablo"
												onClick={(e) => e.preventDefault()}
											>
												Another action
											</DropdownItem>
											<DropdownItem
												href="#pablo"
												onClick={(e) => e.preventDefault()}
											>
												Something else here
											</DropdownItem> */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AamsHeader;
